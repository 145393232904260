<template lang="pug">
section
    template(v-if="loading")
        .flex.items-center.justify-center.pt-40
            a-spin(size="large" :spinning="loading" :tip="$t('v2.action.loading') + '...'")
    template(v-else-if="!result?.records?.length || !total")
        .relative(class="top-1/2 -translate-y-1/2 -mt-9")
            div.text-center
                template(v-if="fromSearch")
                    svg-icon(name="empty" :size="100").pri-color
                    div(class="text-base text-[#4E5969] font-medium")  {{ $t('v2.projectList.searchDesc') }}
                template(v-else)
                    img(class="w-45 m-auto" src="@/assets/images/home/create-project.png")
                    div(class="text-base txt-second-color font-medium")  {{ $t('v2.gameList.emptyText') }}
    template(v-else)
        .flex.justify.mb-4(v-if="showTitle")
            span(v-if="result.total" class="text-[#86909C] text-xs") {{ total }} {{ $t('v2.gameList.include') }}
        .list-wrap
            game-item(v-for="i in result.records" :key="i.id" :item="i" :from-project="fromProject" @onDelete="total--")
        .flex.justify-end
            a-pagination(
                v-if="!hidePagination && result.total > result.size"
                v-model:current="result.current"
                size="small"
                :total="result.total"
                :pageSize="result.size"
                @change="currentChange"
            )
</template>

<script lang="ts" setup>
import API from '@/common/api/console/v2/home'
import GameItem from '../components/gameItem.vue'

const props = withDefaults(defineProps<{
    fromProject?: boolean,
    showTitle?: boolean,
    hidePagination?: boolean,
    params?: Record<string, any>
    item?: any
}>(), {
    params: () => ({ size: 20, current: 1 })
})

const total = ref(0)
const route = useRoute()
const router = useRouter()
const { current, size } = props.params || {}
const result = ref<any>({
    current,
    total: 0,
    size,
    records: []
})
const loading = ref(true)
const fromSearch = ref('')
const fetchList = (params: any = {}) => {
    fromSearch.value = params.packageName
    const currentParams = { ...props.params,  ...params}
    if (props.fromProject) {
        currentParams.applicationId = route.params.projectId
    }
    loading.value = true
    API[props.fromProject ? 'getProjectGames' : 'getGames'](currentParams).then((data: any) => {
        result.value = data
        total.value = data.total
        loading.value = false
    }).catch(() => {
        loading.value = false
    })
}

const currentChange = (page: string) => {
    fetchList({ current: page, packageName: fromSearch.value })
}
const createGame = () => {
    router.push({ name: 'gameCreate', params: { projectId: route.params.projectId } })
}

defineExpose({
    fetchList
})

onBeforeMount(fetchList)
</script>