<template lang="pug">
.success.w-full.p-5
    .anth_status_success(v-if="isReady&&data&&data.approveStatus===1")
        span.success_title 已完成企业认证
            span.successStatus 已完成
        .success_content
            span.content-title
            a-descriptions(title="")
                a-descriptions-item(label="认证类型")
                    span {{ data?.authType }}
                a-descriptions-item(label="认证完成时间")
                    span {{ data?.authFinishTime }}
                a-descriptions-item(label="认证企业社会统一信用代码")
                    span {{ data?.enterpriseBusinessNo }}
                a-descriptions-item(label="认证企业名称")
                    span {{ data?.enterpriseName }}
            
            a-button(type="primary" size="small" @click="detailBox").mt-1 查看认证信息


.anth-padding(v-if="(isReady&&data&&data.approveStatus===0)")
    .padding-title
        span 企业认证中请耐心等待
    .padding-img
        //- img(style="width: 100%;height: 100%;clip:rect(100%,100%)" src="../../../assets/svg/暂无认证.svg", alt="" )
        //- .padding-box
    w-button.padding-btn(type="primary" style="border-radius: 8px" @click="toDetail") 查看认证申请信息
    span.padding-btn.block.mt-5.text-blue-500.cursor-pointer(to="authDetail" @click="withdraw") 撤销认证申请
.anth_status(v-if="isReady&&data?.approveStatus===2||!data&&isReady||isReady&&data?.approveStatus===3")
    span.auth-title 选择实名认证类型
    span.auth-desc 企业主体请尽量避免使用个人认证方式，以免企业人员变动或交接引起不必要的纠纷
    div.auth-type
        .auth-item
            .anth-img
                img(src="@/assets/svg/new-enterprise.svg", alt="")
            .anth-name 企业认证
            //- .anth-box
            w-button.anth-start(v-if="(data&&data?.approveStatus!==2&&data.approveStatus!==3)||!data" type="primary" @click="enterpriseCer") 开始企业认证
            w-button.anth-start(v-if="data&&data.approveStatus===2" type="error"  @click="enterpriseCer") 认证失败重新认证
            w-button.anth-start(v-if="data&&data.approveStatus===3" type="error"  @click="enterpriseCer") 已撤销，重新认证
            .anth-error(v-if="data&&data.approveStatus===2") 失败原因: {{ data.approveDesc }}
        .auth-item
            .anth-img
                img(src="@/assets/svg/new-personal.svg", alt="" )
            .anth-name 个人认证
            //- .anth-box
            w-button.anth-start-disabled(disabled ) 开始个人认证
</template>
<script setup lang="ts">
import { authDetail } from "@/service/userCenter/authentication"
import { Modal, message } from '@welink/design-v2';
import API from "@/common/api"
const { data, run } = authDetail({})
const router = useRouter()
const isReady = ref(false)
const enterpriseCer = () => {
    console.log(data)
    if (!data.value) {
        router.push({ name: "certification" })
    } else {
        router.push({ name: "certification_edit", params: { id: data ? data.value.id : 0 } })
    }

}
const detailBox = () => {
    router.push({name:"authenticationSuccess"})
}
const withdraw = () => {
    Modal.confirm({
        title: '确认撤销',
        content: '实名认证正在审核中，是否确认撤销认证申请',
        onOk() {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve: any, reject:any) => {
                const res: any = await API.userCenter.enterpriseAuthCanCel({ id: data ? data.value.id : 0 })
                console.log(res)
                if (res.data.code === 0) {
                    message.success("操作成功")
                    resolve()
                    await run({})
                }else{
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject()
                    message.error(res.data.msg)
                }
            }).catch(() => console.log('Oops errors!'));
        },
        cancelText: '取消',
        okText: '确认',
        onCancel() {
            Modal.destroyAll();
        },
    })
}
const toDetail = () => {
    router.push({ name: "certification_detail", params: { id: data ? data.value.id : 0 } })
}
onBeforeMount(async () => {
    await run({})
    isReady.value = true
})
</script>
<style lang="sass">
.success_content
    .ant-descriptions-item-container
        display: flex
        align-items: center
    .ant-descriptions-item-content
        display: flex !important
        align-items: center !important
        // margin-left: 200px !important
    .ant-descriptions-item-label
        // margin-right: 80px !important
        color: #6B7280 !important
        font-size: 14px !important
    .ant-descriptions-item-content
        color: #111928 !important
        font-size: 14px !important
</style>
<style lang="sass" scoped>
.anth_status
    width: 100%
    height: 100%
    background: #ffffff
    display: flex
    flex-direction: column
    .auth-title
        text-align: center
        margin-top: 60px
        margin-left: 50%
        transform: translateX(-50%)
        height: 30px
        font-size: 22px
        font-family: PingFangSC-Regular, PingFang SC
        font-weight: 400
        color: #333333
        line-height: 30px
    .auth-desc
        text-align: center
        margin-top: 10px
        margin-left: 50%
        transform: translateX(-50%)
        height: 18px
        font-size: 13px
        font-family: PingFangSC-Regular, PingFang SC
        font-weight: 400
        color: #333333
        line-height: 18px
    .auth-type
        width: 100%
        margin-top: 36px
        display: flex
        margin-left: 50%
        transform: translateX(-50%)
        text-align: center
        justify-content: center

    .anth-img
        width: 100%
        margin-top: 48px
        display: flex
        justify-content: center
    .auth-item
        display: flex
        flex-direction: column
        width: 384px
        height: 272px
        background: #F9FAFB
        border-radius: 8px
        border: 1px solid #EDEDED
        margin-right: 20px
        border-radius: 8px
    .anth-name
        font-size: 20px
        font-weight: 500
        color: #333333
        line-height: 28px
        margin-top: 28px
        // margin-left: 151px
    .anth-box
        width: 344px
        height: 141px
        background: #EEEEEE
        margin-top: 20px !important
        margin: 0 auto
    .anth-start
        width: 150px
        height: 34px
        // background: #4779F6
        color: #FFFFFF
        line-height: 10px
        border-radius: 8px
        margin-top: 30px !important
        margin: 0 auto
        font-size: 12px
    .anth-start-disabled
        width: 123px
        height: 34px
        background: #4779F6
        color: #FFFFFF
        line-height: 10px

        border-radius: 8px
        margin-top: 30px !important
        margin: 0 auto
.anth_status_success
    box-sizing: border-box !important
    // margin: 20px
    padding: 20px
    width: 100%
    background: #FFFFFF
    border: 1px solid #E5E7EB
    .success_title
        display: flex
        align-items: center
        font-size: 20px
        font-weight: 500
        color: #111928
        line-height: 28px
        .successStatus
            // width: 64px

            display: flex
            align-items: center
            height: 22px
            background: #EFF8EC
            border-radius: 4px
            font-size: 12px
            font-weight: 400
            color: #58AD43
            padding: 5px
            padding-left: 3px
            padding-right: 8px
            line-height: 22px
            margin-left: 8px
    .content-title
        width:100%
        margin-top: 24px
        display: block
        height: 1px
        border-top: 1px dashed #E5E7EB
        position: relative
        margin-bottom: 20px
        &::before
            content:"基本信息"
            background: #fff
            position: absolute
            padding-right: 4px
            top:-10px
            font-size: 12px
            font-family: PingFangSC-Regular, PingFang SC
            font-weight: 400
            color: #D1D5DB
            line-height: 17px
.anth-padding
    width: 100%
    height: 100%
    background: #ffffff
    box-sizing: border-box
    .padding-title
        display: block
        height: 30px
        font-size: 22px
        font-family: PingFangSC-Regular, PingFang SC
        font-weight: 400
        color: #333333
        line-height: 30px
        padding-top: 120px
        margin:0 auto
        text-align: center
    .padding-img
        width: 356px
        height: 277px
        background: linear-gradient(180deg, #F4F2FB 0%, #E1EEF5 100%)
        margin:0 auto
        text-align: center
        margin-top: 88px
        background: url("@/assets/svg/暂无认证.svg")
        background-size: 100% 100%
    .padding-box
        width: 799px
        height: 76px
        background: #F0F0F0
        margin:0 auto
        margin-top: 40px
    .padding-btn
        margin:0 auto
        // width: 150px
        height: 42px
        text-align: center
        // background: #C6C6C6
        margin-left: 50%
        transform: translateX(-50%)
        outline: none
        border-radius: 0px
        margin-top: 50px
        border:0px
        font-size: 14px
        padding-left: 16px
        padding-right:16px
.anth-error
    width: 100%
    // text-align: left
    height: 36px
    font-size: 12px
    font-family: PingFangSC-Semibold, PingFang SC
    font-weight: 600
    color: #AFAFAF
    line-height: 18px
    margin-top:14px
    padding-left: 33px
    padding-right:33px
img
    cursor: pointer
button
    font-size: 12px
</style>