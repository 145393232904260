import { i18n } from '@/i18n'
export const regPassword = {
    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,32}$/,
    message: i18n.global.t('validate.passwordMsg')
}
export const regUserName = {
    // pattern:/^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z!@#$%^&*\-_+=]{3,29}[!@#$%^&*\-_+=]$/,
    // pattern:/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*\-_+=])[0-9a-zA-Z!@#$%^&*\-_+=]{4,30}$/,
    pattern: /^[a-zA-Z][a-zA-Z0-9._@-]{3,29}$/,
    message: i18n.global.t('validate.userNameMsg')
}


export const regEmail = {
    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: i18n.global.t('validate.emailMsg')
}

export const regPhone = {
    pattern: /^1[3-9]\d{9}$/,
    message: i18n.global.t('validate.phoneMsg')
}