import Request from "@/common/request"
// import { useRequest } from "@/hooks/useRequest"
const common = {
    topFirstList: '/user/support/manager/feedback/simple',
    contact: '/user/support/manager/feedback/contactWay',
    setpList: '/user/support/feedback/add',
    file: '/common/file/upload'
}

export default {
    topFirstList: (params: any) => Request.post(common.topFirstList, { ...params }),
    getContact: (params: any) => Request.get(common.contact, {}),
    setpList: (params: any) => Request.post(common.setpList, { ...params }),
    getFile: (params: any) => Request.post(common.file, { ...params }),
}