<template lang="pug">
div.home(class="-mt-0")
    w-biz-table(
        :data="IncomeRecord"
        :loading="loading"
        :columns="columns"
        :pagination="pageinationParams"
        @change="sizeChange"
    )
        template(#emptyText)
            .py-10.txt-third-color {{ $t('v2.action.emptyData') }}
        template(#customColumn="{ record, column, text}")
            template(v-if="column.prop === 'createTime'")
                span {{ dayjs(text).format('YYYY-MM-DD') }}
            template(v-if="column.prop === 'billAmount'")
                | {{ text }} {{ record.unit }}
            template(v-if="column.prop === 'download'")
                a-button(v-if="record.uploadPath" type="link" class="!px-0" @click="downloadPDF(record)") {{ $t('v2.action.download') }}
</template>
<script setup lang="ts">
import API from "@/common/api/console/v2/home"
import dayjs from "dayjs";
import { cloneDeep } from "lodash-es";
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const IncomeRecord = ref([])
const loading = ref(false)

const pageinationParams = reactive({
    current: 1,
    size: 'small',
    total: 0,
    pageNo: 1,
    pageSize: 10
})
const fetchTableList = async () => {
    const params: any = cloneDeep(pageinationParams)
    delete params.size
    delete params.pageNo
    delete params.pageSize
    delete params.total
    loading.value = true
    try {
        const data: any = await API.getBillPage(params)
        console.log('data: ', data);
        IncomeRecord.value = data.records
        pageinationParams.total = data.total
        loading.value = false
    } catch(e) {
        loading.value = false
    }
}

const sizeChange = ({ current, pageSize}: any) => {
    pageinationParams.current = current
    pageinationParams.pageNo = current
    pageinationParams.size = pageSize
    pageinationParams.pageSize = pageSize
    fetchTableList()
}

const downloadPDF = (record: any) => {
    API.billDownload(record.id).then((data: any) => {
        if (data?.blobUrl) {
            window.open(data.blobUrl)
        }
    })
}

const columns = ref<any>([
    {
        title: t("v2.bills.list.table.t1"),
        prop: 'userName',
        width: 200
    },
    {
        title: t("v2.bills.list.table.t2"),
        prop: 'billPeriod',
        width: 250
    },
    {
        title: t("v2.bills.list.table.t3"),
        prop: 'billAmount',
        width: 150
    },
    {
        title: t("v2.bills.list.table.t4"),
        prop: 'download',
        width: 200
    },
    {
        title: t("v2.bills.list.table.t5") + t('v2.common.gmt'),
        prop: 'createTime',
        width: 250
    },
])
onMounted(fetchTableList)

</script>