import Request from '@/common/request'

// interface Config extends RequestConfig {
//     /** 是否手动执行 */
//     manual?: boolean;
// }

type Method = 'get' | 'post';

// eslint-disable-next-line max-params
export function useRequest<R, S>(api: string, method: Method, params: R, config?: any) {
    const { manual = false } = config || {}
    const data = ref<any>({})
    // const data = ref<Partial<S>>({})
    const error = ref<any>()
    const loading = ref<boolean>(false)
    const run = async(customParams?: R) => {
        error.value = ''
        data.value = ''
        loading.value = true
        try {
            const res = await Request[method]<R, S>(api, { ...params, ...customParams }, config)
            data.value = res
            loading.value = false
        } catch (e: any) {
            error.value = e
            loading.value = false
        } finally {
            loading.value = false
        }
    }
    if (!manual) {
        run()
    }
    return {
        data, run, error, loading
    }
}


