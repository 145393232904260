<template lang="pug">
.console-main-process
    .inner.flex.justify-center
        .pt-39
            .label.mb-3 {{ $t('v2.projectList.create') }}
            a-input.block(
                size="large"
                class="!w-160"
                v-model:value="projectName"
                :status="error ? 'error' : null"
                    :placeholder="$t('v2.projectList.createPlaceholder')"
                )
            .error.text-xs.pt-1(v-if="error" class="text-[#ff4d4f]") {{ $t('v2.projectList.createTip') }}
            .flex.justify-right.mt-7
                a-button(type="primary" :disabled="!projectName.trim() || error" :loading="loading" @click="saveProject") {{ $t('v2.action.save') }}
</template>

<script lang="ts" setup>
import API from '@/common/api/console/v2/home'
import { PROJECT_NAME_LENGTH } from '@/views/console/v2/constants/index'
import { Toast } from '@welink/design';
import { useI18n } from 'vue-i18n';

const { t } = useI18n()

const projectName = ref('')
const loading = ref(false)
const error = ref(false)
const router = useRouter()

watch(projectName,(v) => {
    error.value = v.trim().length > PROJECT_NAME_LENGTH
})
const saveProject = async () => {
    loading.value = true
    try {
        await API.createProject(projectName.value.trim())
        loading.value = false
        Toast.success(t('v2.action.createSuccess'))
        router.push({ name: 'projectList' })
    } catch(e) {
        loading.value = false
    }
}
</script>