<template lang="pug">
.register-panel(class="h-5/6 w-3/4 relative left-1/2 -translate-x-1/2 max-sm:w-auto max-sm:p-6 max-sm:left-0 max-sm:translate-x-0 max-sm:h-auto")
    h1.title.m-auto.mb-6.text-center.register-title.text-xl.font-medium.pt-12(class="max-sm:text-lg max-sm:font-semibold") 欢迎注册蔚领时代账号
    router-link.text-xs.block.m-auto.mb-5.text-center.register-title.text-gray-600(:to="{ name: 'userCenterLogin'}")
        | 已有账号？
        span.text-main-color 去登录
    .form(class="max-sm:!w-auto")
        w-biz-form(
            ref="form" :formState="formState" hide-labels
            :footer="{ okTxt:'注册', hideCancelTxt:true, cls: 'w-90 flex justify-center'}"
            submitText="注册"
            hideReset
            @on-submit="onSubmit"
        )
            template(#appendField="{ filedName }")
                template(v-if="filedName === 'imgVerifyCode'")
                    img.imgCode.absolute.right-0.p-1.top-0.cursor-pointer(:src="imgVerifyCode.base64Image" class="rounded-lg" @click="getImgCode")
                template(v-if="filedName === 'smsCode'")
                    label(for="form_item_smsCode")
                        span.getCode.absolute.right-0.top-0.rounded-lg(:class="{'disabled !cursor-not-allowed': disabledSms }" @click="getCode") {{ verifyCodeTxt }}
            p.text-xs.text-gray-500.flex.items-center.mb-8
                input#checkbox(v-model="checked" type="checkbox", class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600")
                label(for="checkbox", class="ml-2 text-xs font-medium text-gray-400 dark:text-gray-500")
                    span.text-xs 注册视为您已阅读并同意
                    router-link.text-xs.text-main-color.ml-1(target="_blank" :to="{ name: 'legal_notice' }") 隐私政策、
                    router-link.text-xs.text-main-color(target="_blank" :to="{ name: 'user_agreement' }") 服务条款
                    span.text-xs 和
                    router-link.text-xs.text-main-color(target="_blank" :to="{ name: 'legal_statement' }") 法律声明
.footer.text-info-color.pb-3.w-full.text-center
    | © 2023
    a(href="https://www.wl-times.com", target="_blank").mx-1 wl-times.com
    | All Rights Reserved
</template>

<script lang="ts" setup>
import API from '@/common/api'
import { router } from '@/routers';
import { Toast } from '@welink/design';
import { FormConst } from '../form'
import { cloneDeep } from 'lodash-es'
import { regPhone } from "@/utils/validate"

const { username, password, confirmPassword, imgVerifyCode: imgVerifyCodeForm , userTel, smsCode } = cloneDeep(FormConst)

const form = ref()
const verifyCodeTxt = ref<string>("获取验证码")
const showTime = ref<boolean>(false)
const checked = ref<boolean>(false)
const imgVerifyCode = ref<any>({
    base64Image: '',
    code: null,
    expiresAt: '',
    width: '',
    height: '',
    verifyCodeKey: ''
})


const formState = reactive<any>({
    username,
    password,
    confirmPassword,
    imgVerifyCode: { ...imgVerifyCodeForm },
    userTel,
    smsCode,
})
const getImgCode = async () => {
    const res: any = await API.userCenter.getImgVerifyCodeInRegister()
    imgVerifyCode.value = res
}
const onSubmit = async (values: any) => {
    if (!checked.value) {
        Toast.warning('请同意条款')
        return false
    }
    API.userCenter.register(values).then((res: any) => {
        console.log(res.data)
        if (res.data.code === 0) {
            Toast.success("注册成功")
            router.push('/user_center/login')
        } else {
            getImgCode()
            Toast.error(res.data.msg)
        }
    }).catch((error) => {
        console.log(error)
    })
}
const isFetchSms = ref(false)
const disabledSms = computed(() => !(formState?.imgVerifyCode?.value.length === 4 && regPhone.pattern.test(formState?.userTel.value)) || isFetchSms.value || showTime.value)
const getCode = async () => {
    if (disabledSms.value) { return }
    isFetchSms.value = true
    verifyCodeTxt.value = '获取中...'
    const res: any = await API.userCenter.getPhoneCode({
        imageVerifyCode: form.value.formModel.imgVerifyCode,
        imageVerifyCodeKey: imgVerifyCode.value.verifyCodeKey,
        userTel: form.value.formModel.userTel,
        verifyCodeType: 1
    })
    if (res.data.code === 0) {
        let time = 60
        showTime.value = true
        const times = setInterval(() => {
            time--
            verifyCodeTxt.value = time + '后重新获取'
            if (time === 1) {
                clearInterval(times)
                verifyCodeTxt.value = "获取验证码"
                showTime.value = false
            }
        }, 1000)
    } else {
        Toast.error(res.data.msg)
        getImgCode()
        verifyCodeTxt.value = "获取验证码"
    }
    isFetchSms.value = false
}
onMounted(() => {
    getImgCode()
})
</script>
<style scoped lang="sass">
.register-panel
    .form
        width: 360px
        margin: 0 auto
        font-size: 12px
    .register-title
        width: 360px
        // margin: 0 auto
        // margin-bottom: 18px
    .phone-input
        padding-left: 60px !important
    .phone-prefix
        width:58px
        height:38px
        line-height: 38px
        top:1px
        left: 1px
        border: transparent
        border-right: 1px solid #d9d9d9
        position: absolute
    :deep .ant-input
        border-radius: 8px !important
    :deep .ant-input-password
        border-radius: 8px !important
    :deep #form_item_userTel
        border-top-left-radius: 0px !important
        border-bottom-left-radius: 0px !important
    :deep #form_item_smsCode
        width: 244px
        &:hover
            border-color: #d9d9d9
        .ant-btn
            width: 100%
            border-radius: 8px
    :deep(.btns)
        .ant-btn-primary
            width: 100%
</style>
