import { useRequest } from '@/hooks/useRequest'
import Api from "@/common/api"
export const getLogList = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.workOrder.getLogList, 'post', params || {}, { manual: true })
}
export const getFastList = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.workOrder.fastList, 'get', params || {}, { manual: false })
}
export const getEdit = (id: any,manual: any = {}) => {
    return useRequest<Partial<any>, any>(Api.workOrder.edit + id, 'get',{},{ ...manual})
}
export const getBackout = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.workOrder.backout, 'post', params || {}, { manual: true })
}
export const getProblemType = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.workOrder.problemType, 'post', params || {}, { manual: true })
}
