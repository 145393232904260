import { useRequest } from '@/hooks/useRequest'
import Api from "@/common/api"

export const activation = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.userCenter.activation, 'post', params || {}, { manual: true })
}
export const checkActivation = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.userCenter.checkActivation, 'get', params || {}, { manual: false })
}

