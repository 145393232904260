<template lang="pug">
div.h-full.page-sidebar-wrap.relative.transition-all(:class="{'w-62': !menuStatus, 'w-16': menuStatus}")
    aside(
        :class="{'w-62': !menuStatus, 'w-16': menuStatus, 'transition-all h-full fixed bg-white rounded-tr-lg pt-8 pb-16 box-border z-1': true}"
    )
        ul.space-y-2( :class="{'mx-4 max-w-[182px]': !menuStatus, 'w-16 mx-2': menuStatus,}")
            li(v-for="item in menuList" :key="item.key" )
                template(v-if="!item.hidden")
                    a(v-if=" !item.children && !item.type"
                        :class="{'w-full h-[52px]' : !menuStatus, 'text-center h-[46px] w-[46px] !p-[14px]' : menuStatus,'bg-b-color !pri-color': selectedKeys === item.name,'inline-block cursor-pointer rounded-lg txt-color text-[16px] px-5 py-[14px] hover:bg-[#F7F8FA] active:bg-[#F2F3F5]': true}"
                        @click="handleClick(item.path)"
                    )
                        template(v-if="item.icon")
                            a-tooltip(placement="right" :mouseEnterDelay="1" overlayClassName="header-tooltip")
                                template(v-if="menuStatus" #title)
                                    span {{ item.label }}
                                svg-icon(:name="item.icon", :size="18" class="-mt-1")
                        span(class="ml-3") {{ !menuStatus? item.label: null }}
                    div(v-if="item.children && item.type")
                        div(v-if="!menuStatus" class="text-[#C9CDD4] mb-[6px] text-[14px]") {{ item.label }}
                        div(v-else class="w-[30px] h-px bg-b-color ml-[10px] my-[18px]")
                        div(v-for="subItem in item.children" :key="subItem.key" class="flex")
                            a(
                                :class="{'bg-b-color !pri-color' : selectedKeys === subItem.name,'w-full h-[52px]' : !menuStatus, 'text-center h-[46px] w-[46px] !p-[14px] mb-2' : menuStatus, 'cursor-pointer rounded-lg text-[16px] px-5 py-[14px] hover:bg-[#F7F8FA] active:bg-[#F2F3F5]': true}"
                                @click="handleClick(subItem.path)"
                            ).flex.items-center.txt-color
                                template(v-if="subItem.icon")
                                    a-tooltip(placement="right" :mouseEnterDelay= "1" overlayClassName="header-tooltip")
                                        template(v-if="menuStatus" #title)
                                            span.shrink-0 {{ subItem.label }}
                                        svg-icon(:name="subItem.icon", :size="18").shrink-0
                                span(class="ml-3")  {{ !menuStatus? subItem.label:null }}
        .tutorial.px-3.absolute.bottom-26.cursor-pointer.w-full(:class="{'close': menuStatus }")
            //- Tutorial
            div(v-if="isTutorialGuideShow" @click="showTutorialGuide" v-show="isHomePage")
                a-popover(
                    placement="right" overlayClassName="sidebar-tutorial" :open="popoverTutorial"
                    :getPopupContainer="getPopupContainer"
                )
                    template.relative(#content)
                        div(class="text-[#FFF] text-base") {{ $t('v2.console.sidebar.tutorial.tTitle') }}
                        a-button.tutorial-btn(ghost class="!absolute right-4 bottom-4" @click="hidePopoverTutorial") {{ $t('v2.console.sidebar.tutorial.tBtn') }}
                    div(
                        class="s-guide p-[6px] mb-[22px] flex border border-[#E5E6EB] rounded-[10px] shadow-[0px 4px 10px 0px rgba(0, 0, 0, 0.15)]"
                    )
                        svg-icon(name="tutorial",:size="50")
                        div(class="flex-1 ml-[10px] mt-1" v-show="!menuStatus")
                            div(class="text-sm font-medium txt-color") {{ $t('v2.console.sidebar.tutorial.title') }}
                            span(class="text-xs text-[#4E5969] text-[11px]") {{ $t('v2.console.sidebar.tutorial.desc') }}
            div(class="h-[124px] px-[18px] py-4 bg-[#F6F9FF] rounded-lg s-download" v-show="showDownload")
                svg-icon(name="develop-center",:size="20" @click="router.push({ name: 'landingSDKpage' })")
                template(v-if="!menuStatus")
                    span(class="ml-1 text-[#4E5969] text-base mb-1 font-medium") {{ $t('v2.console.sidebar.download.title') }}
                    div(class="ml-6 text-[#86909C] text-xs font-normal") {{ $t('v2.console.sidebar.download.desc') }}
                    a-button(type="primary" class="font-medium w-full py-[10px] px-5 mt-3" @click="router.push({ name: 'landingSDKpage' })") {{ $t('v2.console.sidebar.download.btn') }}

    div(@click="toggleMenu")
        div.icon.cursor-pointer(v-if="!menuStatus" :class="{'w-[34px] h-[34px] bg-b-color absolute z-10 top-0 right-0 rounded-tr-lg rounded-bl-lg flex':true}")
            svg-icon.m-auto(name="open-left", :size="16")
        div.icon.cursor-pointer(v-else :class="{'flex w-[14px] h-[14px] mt-[10px] relative z-10 mx-auto':true}")
            svg-icon.m-auto(name="open-right", :size="16")
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useHomeStore } from '@/store/home'

defineProps<{
    menuList: { label?: string, name?: string, path?: string, key?: string, icon?: string, hidden?: boolean, type?:string, children?: any[]}[]
}>()
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const store = useHomeStore()
const isTutorialGuideShow = computed(() => store.guideInfo.tutorialGuideStyle)
const isHomePage = computed(() =>  route.name === 'home')
const menuStatus = ref(localStorage.getItem('WL_SIDEBAR_STATIS') === 'close')
const popOpen = ref(false)
const menuListRef = ref()
const selectedKeys: any = computed(() => route.name || menuListRef.value[0]?.name)
const popoverTutorial = computed(() => isTutorialGuideShow.value && isHomePage && popOpen.value && !store.guideInfo.guidePopup)
const showDownload = computed(() => route.path.includes('console'))


const toggleMenu = () => {
    window.localStorage.setItem('WL_SIDEBAR_STATIS', !menuStatus.value ? 'close' : 'open')
    menuStatus.value = !menuStatus.value
}
const handleClick = async (name?: string) => {
    router.push({ name })
};

const getPopupContainer = () => document.querySelector('.page-sidebar-wrap aside')

watch(isTutorialGuideShow, (v) => {
    if (v && !window.localStorage.getItem('WL_SIDEBAR_TUTORIAL_POPOVER')) {
        popOpen.value = true
    }
})

const showTutorialGuide = () => {
    store.hideTutorialGuide(0)
}

const hidePopoverTutorial = () => {
    popOpen.value = false
    window.localStorage.setItem('WL_SIDEBAR_TUTORIAL_POPOVER', 'hide')
}


</script>
<style lang="sass" >
.sidebar-tutorial
    .ant-popover-inner
        width: 364px
        height: 132px
        padding: 16px
        background: linear-gradient(90deg, #124EF4 -22.45%, #9F83E5 248.21%) !important
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15) !important
    .ant-popover-arrow::after
        background: #124EF4 !important
        border-radius: 0 !important
.tutorial-btn
    color: #fff !important
    &:hover
        border-color: #fff !important
        background: #4D7AF7 !important
    &:active
        background-color: #124EF4 !important
        border-color: #4D7AF7 !important
</style>
<style lang="sass" scoped>
.page-sidebar-wrap
    height: calc(100vh - 64px)
    .icon
        visibility: hidden
    &:hover
        .icon
            visibility: visible
</style>
<style lang="sass" scoped>
.tutorial
    &.close
        .s-guide
            border: none
            margin-bottom: 4px
        .s-download
            width: 28px
            height: 28px
            display: flex
            align-items: center
            justify-content: center
            margin: 0 auto
            padding: 0
</style>
