import Request from "@/common/request"
import topList from "./commodity"
import bill from "./bill"
// import { useRequest } from "@/hooks/useRequest"
const common = {
    /** 现金余额变更记录列表 */
    cashRecordList: '/user/account/cash/recordList',
    /** 账户详情 */
    accountDetail: '/user/account/detail',
    /** 信用额度变更记录列表 */
    creditRecordList: '/user/account/credit/recordList',
    /** 收支明细导出 */
    accountRecordDownload: '/user/account/account/record/download',
    /** 收支明细 */
    accountIncomeRecord: '/user/account/account/recordList',
    appAndArea: '/user/product/sku/appAndArea',
    priceByArea: '/user/product/sku/priceByArea',
    orderSave: '/user/product/order/save',

}

export default {
    ...bill,
    ...topList,
    cashRecordList: (params: any) => Request.post(common.cashRecordList, { ...params }),
    accountDetail: (params: any) => Request.post(common.accountDetail, { ...params }),
    creditRecordList: (params: any) => Request.post(common.creditRecordList, { ...params }),
    accountRecordDownload: (params: any) => Request.post(common.accountRecordDownload, { ...params, }, {  responseType: 'blob',canCustomHandle: true }),
    accountIncomeRecord: (params: any) => Request.post(common.accountIncomeRecord, { ...params }),
    appAndArea: (params: any) => Request.post(common.appAndArea, { ...params }),
    priceByArea: (params: any) => Request.post(common.priceByArea, { ...params }),
    orderSave: (params: any) => Request.post(common.orderSave, { ...params }, { canCustomHandle: true }),
}