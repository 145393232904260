<template>
    <div class="pagination flex py-4 text-xs text-gray-500 justify-end items-center">
        <span>
            {{ $t('common.pagenation.inTotal') }} {{ total }} {{ $t('common.pagenation.article') }}，{{ $t('common.pagenation.howPerPage') }}
            <select
                v-model="pageSizeSelect"
                class="mx-1 text-gray-900 leading-normal border-gray-300 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 rounded placeholder:text-xs border p-0 py-1 text-xs appearance-none text-center"
                @change="sizeChange"
            >
                <option v-for="option in sizeOptions" :key="option" :value="option" class="text-gray-900">{{ option }}</option>
            </select>
            {{ $t('common.pagenation.articles') }}
        </span>
        <!-- 上一页按钮 -->
        <button class="mx-4 hover:font-semibold cursor-pointer hover:text-blue-600" :disabled="currentPage === 1 " @click="prev">&lt;</button>
        <!-- 页码列表 -->
        <ul class="flex items-center justify-center">
            <!-- <li
                v-for="pageNum in pages" :key="pageNum"
                :class="{ 'text-blue-600 font-semibold': pageNum === currentPage, 'mx-2 p-2 cursor-pointer hover:font-semibold hover:text-blue-600 flex justify-center': true }"
                @click="goToPage(pageNum)"
            >
                {{ pageNum }}
            </li> -->
            <li class="mt-3">{{ currentPage }}/{{ totalPages }}</li>
        </ul>

        <!-- 下一页按钮 -->
        <button class="mx-4 hover:font-semibold cursor-pointer hover:text-blue-600" :disabled="currentPage === totalPages" @click="next">&gt;</button>

        <!-- 跳转输入框和按钮 -->
        <!-- <div class="jump flex justify-center items-center">
            <w-input v-model.number="state.gotoPage" size="small" type="text" class="!text-sm !w-10 !h-6 mr-4 text-center" />
            <button class="hover:font-semibold cursor-pointer hover:text-blue-600" @click="jump">跳转</button>
        </div> -->
    </div>
</template>

<script lang="ts" setup scoped>
import { useI18n } from 'vue-i18n'
const props = withDefaults(defineProps<{
    total: number,
    size: number,
    currentPage: number
    sizeOptions?: any,
}>(), { total: 0, size: 10, currentPage: 1, sizeOptions: [5, 10, 20, 50, 100] })

const emit = defineEmits(['currentChange', 'sizeChange'])
console.log(props)
const { t } = useI18n()
const totalPages = computed(() => Math.ceil(props.total / props.size));
const pageSizeSelect = ref(props.size || props.sizeOptions[0])

watch(() => props.size, (v) => {
    pageSizeSelect.value = v
})

// 计算页码列表
const pages = computed(() => {
    let start = Math.max(props.currentPage - 2, 1);
    const end = Math.min(start + 4, totalPages.value);

    if (end - start < 4 && start > 1) {
        start -= 4 - (end - start);
        start = Math.max(1, start);
    }

    return new Array(end - start + 1).fill(0).map((_, index) => start + index);
});

// 前往指定页码
const goToPage = (page: number) => {
    if (props.currentPage === page) return;
    state.gotoPage = '';
    emit('currentChange', page);
};
const sizeChange = (current: number) => {
    // emit('currentChange', 1);
    emit('sizeChange', pageSizeSelect)
};

// 上一页
const prev = () => {
    if (props.currentPage > 1) {
        emit('currentChange', props.currentPage - 1);
    }
};

// 下一页
const next = () => {
    if (props.currentPage < totalPages.value) {
        emit('currentChange', props.currentPage + 1);
    }
};

// 跳转到指定页码
const state = reactive({ gotoPage: '' });
const jump = () => {
    const page = parseInt(state.gotoPage, 10);
    if (!isNaN(page) && page > 0 && page <= totalPages.value) {
        emit('currentChange', page);
        console.log('page: ', page);
        state.gotoPage = '';
    }
};
</script>