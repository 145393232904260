<template lang="pug">
.relative#game-wrapper(v-if="height" :style="`height: ${gameHeight}px;width:${gameWidth}px;`" class="m-auto")
    .info(v-if="gameMsg" class="absolute top-2 bg-slate-600 px-10 text-xs rounded-md z-10 text-white text-center left-1/2 -translate-x-1/2 py-1") {{ gameMsg }}
    .absolute.right-5.top-6(class="w-10 h-full z-2" v-if="gameSetting.running && !route.query.isH5")
        svg-icon(name="fullscreen" size="30" hover="opacity-100" @click="toggleFullscreen").cursor-pointer.opacity-50.transition-all
    .absolute(class="left-1/2 -translate-x-1/2 bottom-4 z-10 text-white bg-slate-500 rounded-xl py-1 flex justify-center w-max flex-wrap backdrop-blur-sm")
        template(v-for="(key,index) in Object.keys(statisticsData)" :key="index")
            .inline-block.mx-4
                span.text-xs {{ `${gameStatisticsKeyMapping[key]?.name || key}: ` }}
                span.text-xs(:class="gameStatisticsKeyMapping[key]?.cls") {{ (statisticsData)[key] }} {{ gameStatisticsKeyMapping[key]?.unit || "" }}
    a-button(v-show="!gameSetting.running" type="primary" class="left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 !absolute z-10" :disabled="!startGameBtnStatus" @click="startGame") {{ $t('console.adapter.stepDemo.start') }}
    #gameContainer.game-container.overflow-hidden.rounded-lg(v-if="gameHeight" :style="`height: ${gameHeight}px;width:${gameWidth}px;`" class="m-auto")
</template>

<script setup lang="ts">
import API from '@/common/api/console/v2/home'
import { warningCode, errorCode } from '../../constants/game'
import { dispatchApapterWebParams, gameStatisticsKeyMapping } from '../../constants'
import { useGlobalStore } from '@/store/global'
import { fullScreen } from '@/utils/fullscreen'
import { VITE_GAME_ISP_HOST, VITE_GAME_REPORT_HOST } from '@/common/constants/env';
import { useI18n } from 'vue-i18n'

const props = defineProps<{
    height: number,
    width: number
}>()
const { t } = useI18n()
const store = useGlobalStore()
const gameMsg = ref('')
const gameSetting = reactive({
    visible: false,
    running: false,
    bps: dispatchApapterWebParams.bitRate,
    mode: 'window',
})
const startGameBtnStatus = ref(false)
const statisticsData = ref<any>({})
const envId = computed(() => store.envId)

const WLCG = ref()
const route = useRoute()
const gameHeight = ref(props.height)
// const gameWidth = ref(Math.floor(gameHeight.value * 16 / 9))
const gameWidth = ref(props.width)
const handleSDKMessage = (code: number, message: any) => {
    console.log('code: number, message:', code, message)
    switch (code) {
    case 6038: {
        gameMsg.value = t('console.adapter.stepDemo.runnning')
        gameSetting.running = true
        setTimeout(() => {
            gameMsg.value = ''
        }, 1000)
        break;
    }
    
    case 6112: {

        break;
    }
    case 6075: {
        break;
    }
    default: {
        if (Object.keys({ ...warningCode, ...errorCode }).includes(String(code))) {
            gameMsg.value = (warningCode[String(code)] || errorCode[code]);
            // gameSetting.running = false
            return;
        }
    }
    }
};

const initGame = async () => {
    // @ts-expect-error: Unreachable code error1
    await import('@/assets/libs/WelinkCloudGame.4.4.18_2311091206.min.js')
    // @ts-expect-error: Unreachable code error
    await import('@/assets/libs/WeLinkQueue.1.0.0.min.653db3.js')

    // @ts-expect-error: Unreachable code error
    WLCG.value = new WelinkCloudGame({
        id: "gameContainer",
        IspUrl: VITE_GAME_ISP_HOST,
        videoWidth: gameWidth.value,
        videoHeight: gameHeight.value,
        tenantKey: envId.value,
        reportHost: VITE_GAME_REPORT_HOST,
        rotate: 0,
        envType: "pc",
    })
    // 监听sdk消息
    WLCG.value.onMessage = handleSDKMessage;
    WLCG.value.onGameData = (data: any) => {
        console.log('data: ', data);
    };
    WLCG.value.onGameDataWithKey = (key: string, data: any) => {
        console.log('ke1y: ', key, data);
    };
    WLCG.value.showGameStatisticsData = (data: any) => {
        data.bandWidth = (Number(data.bandWidth) / 1000000 * 8)?.toFixed(2) || 0;
        const params = { ...data }
        delete params.netWorkDelayUdp
        delete params.packetLossCont
        statisticsData.value = params
    };
    // setInterval(() => {
    //     const data = {
    //         bandWidth: Math.random(),
    //         bitrate: Math.random(),
    //         fps: Math.random(),
    //     }
    //     statisticsData.value = data
    // }, 1000)
    // 执行初始化
    WLCG.value.init();
    startGameBtnStatus.value = true
}

const startGame = async () => {
    if (!startGameBtnStatus.value) { return }
    const { projectId, gameId, nodeId } = route.params
    const { loginToken, lang, isH5 } = route.query
    console.log('isH5:', isH5)
    gameSetting.running = true
    gameMsg.value = t('console.adapter.stepDemo.starting')
    setTimeout(async () => {
        const bizData = WLCG.value?.getBizData()
        try {
            const params: any = {
                bizData, ...dispatchApapterWebParams, 
                projectId,
                packageId: gameId,
                node: nodeId,
            }
            const res: any = await API.adapterDispatch(params, loginToken as string, lang as string )
            WLCG.value?.startGame(res?.sdkMsg);
        } catch (e) {
            console.log(e)
            gameSetting.running = false
            gameMsg.value = ''
        }
    }, 100)
}

const toggleFullscreen = () => {
    const mode = gameSetting.mode
    const newMode = mode === 'window' ? 'fullscreen' : 'window'
    gameSetting.mode = newMode
    if (newMode === 'fullscreen') {
        fullScreen(document.querySelector('#game-wrapper'))
    } else {
        document.exitFullscreen()
    }
}

const saveGameSetting = () => {
    try {
        if (gameSetting.mode === "fullscreen") {
            fullScreen(document.querySelector('#game-wrapper'))
        } else {
            document.exitFullscreen()
        }
        if (gameSetting.running) {
            WLCG.value.setBitrate(gameSetting.bps);
        }
        gameSetting.visible = false
    } catch (e) {
        console.log('e: ', e);
    }
}

const onFullScreen = () => {
    const isFullScreen = (document as any).fullScreen || (document as any).mozFullScreen || (document as any).webkitIsFullScreen;
    if (isFullScreen) {
        document.body.classList.add('page-fullscreen')
    } else {
        gameSetting.mode = 'window'
        document.body.classList.remove('page-fullscreen')
    }
    gameWidth.value = window.innerWidth
    gameHeight.value = window.innerHeight
    WLCG.value.setVideoSize(window.innerWidth, window.innerHeight);
}

onMounted(() => {
    initGame()
    window.addEventListener('resize', onFullScreen)
})

const onBeforeUnload = () => {
    if (WLCG.value) {
        WLCG.value?.exitGame();
        WLCG.value = null;
    }
}
window.addEventListener('beforeunload', onBeforeUnload);


onBeforeUnmount(() => {
    if (WLCG.value) {
        WLCG.value?.exitGame();
        WLCG.value = null;
    }
    window.removeEventListener('resize', onFullScreen)
    window.removeEventListener('beforeunload', onBeforeUnload)
})
</script>

<style lang="sass" scoped>
:deep video
    background-color: rgba(0,0,0,.2) !important
</style>


<style lang="sass">
.page-fullscreen
    .game-container
        width: auto !important
        height: auto !important
</style>