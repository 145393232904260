<template lang="pug">
page-sidebar(:menuList="menuList")
page-body
    h1.txt-color.text-xl.mb-0.pl-5.mb-4.pt-3.font-medium {{ route.meta.title }}
    .bg-white.p-5.rounded-lg.min-h-full
        router-view
</template>

<script lang="ts" setup>
import pageSidebar from '@/views/components/page-sidebar.vue';
import pageBody from '@/views/components/page-body.vue';
import { useI18n } from 'vue-i18n'

const router = useRouter();
const route = useRoute()
const { t } = useI18n()
const collapsed = ref(true);
const menuList: { label: string, name: string, path: string, key?: string, icon?: string, children?: any, showChildren?: boolean }[] = [
    {
        label: t('cost.meauList.cost'), name: 'Income', path: 'Income', key: 'Income', icon: '',
        children: [
            { label: t('cost.meauList.Income'), name: 'Income', path: 'Income', key: 'Income', icon: '' },
            // { label: '账单概览', name: '', path: 'bill', key: '', icon: '' },
            { label: t('cost.meauList.CreditLimit'), name: 'CreditLimit', path: 'CreditLimit', key: '', icon: '' },
            { label: t('cost.meauList.Recharge'), name: 'Recharge', path: 'Recharge', key: '', icon: '' },
            { label: t('cost.meauList.preFund'), name: 'preFund', path: 'preFund', key: '', icon: '' },
        ]
    },

    { label: t('cost.meauList.billList'), name: 'billList', path: 'bill_list', key: '', icon: 'bill' },
]
const handleMenu = (v: any, key: any, keyPath: any) => {
    console.log(v)
    router.push({ name: v.key });
};
</script>

<style lang="sass" scoped>
// :deep #app
//     width: 100%
//     height: 100%
//     display: flex
//     flex-direction: column
//     background: red !important
//     section
//         flex:1
//         height: 100%
// .page-content
//     width: 100%
//     height: 100%

</style>
