<template lang="pug">
.h5-landing.w-full
    template(v-if="loading")
        w-loading(:loading="loading")
    template(v-else)
        img(src="@/assets/images/appConsole/h5-landing.png")
        .txt(class="text-2xl font-medium text-center pt-8")
            | {{ title }}
            br
            | {{ desc }}
        .info.text-gray-500.text-center.pt-4
        .px-10.pt-5
            w-button(size="lg", @click="download").w-full.rounded-full {{ url }}
</template>

<script setup lang="ts">
import { Toast } from '@welink/design';
import API from '@/common/api'
import { dispatchApapterAndriodParams } from './constants'
import ApkFileEn from '@/assets/file/cloud_debugging_tool-1.0.1_International.apk?url'
import ApkFileCn from '@/assets/file/cloud_debugging_tool-1.0.1.apk?url'
import { useI18n } from 'vue-i18n'
import { VITE_GAME_ISP_HOST } from '@/common/constants/env';
import { isForeign } from '@/common/constants/env';

document.body.classList.add('page-h5-landing')

const route = useRoute()
const { messages } = useI18n()
const title = ref('')
const desc = ref('')
const url = ref('')
const error = ref('')
const tip = ref('')


const loading = ref(true)

const download = () => {
    const isAndroid = navigator.userAgent.indexOf('Android') > -1 ||  navigator.userAgent.indexOf('Adr') > -1;
    if (isAndroid) {
        window.open(isForeign ? ApkFileEn : ApkFileCn)
    } else {
        Toast.warning(tip.value)
    }
}

onMounted(() => {
    const query = route.query
    const msgs: any = messages.value[route.query.lang as string || 'en']

    title.value = msgs?.console?.adapter?.h5?.title
    desc.value = msgs?.console?.adapter?.h5?.desc
    url.value = msgs?.console?.adapter?.h5?.url
    error.value = msgs?.console?.adapter?.h5?.error
    tip.value = msgs?.console?.adapter?.h5?.tip

    document.title = msgs?.pageTitle

    if (!query.token || !query.loginToken) {
        Toast.warning(error.value)
        return
    }
})

window.onload = () => {
    const query = route.query
    try {
        const isWelinkWebView: boolean = window.navigator.userAgent.includes("__WELINK_CLOUD_GAMING__")
        if (isWelinkWebView) {
            // @ts-expect-error: Unreachable code error
            API.console.adapterDispatch({ ...dispatchApapterAndriodParams, token: query.token, bizData: window.AndroidBridge?.getBizData()  }, query.loginToken, route.query.lang).then((res : any) => {
                // @ts-expect-error: Unreachable code error
                window.AndroidBridge?.sendSdkMsgToAndroid(JSON.stringify({
                    hostUrl: VITE_GAME_ISP_HOST,
                    bitRate: dispatchApapterAndriodParams.bitRate,
                    tenantId: query.tenantId,
                    tenantSecret: decodeURIComponent(query.tenantSecret as string),
                    sdkMsg: res?.sdkMsg,
                }));
            }).catch(() => {
                setTimeout(() => {
                    // @ts-expect-error: Unreachable code error
                    window.AndroidBridge?.back()
                }, 2000);
            })
        } else {
            loading.value = false
        }
    } catch (e) {
        loading.value = false
    }
}

onBeforeUnmount(() => {
    document.body.classList.remove('page-h5-landing')
})

</script>

<style lang="sass">
.page-h5-landing
    background-color: #fff
    #app
        min-width: 100%
    .page-header
        display: none
    .page-wrapper
        padding-top: 0
    img
        object-fit: cover
        object-position: center
</style>