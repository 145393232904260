<template lang="pug">
div.home
    div.searchSty.items-center.flex.gap-4.mb-5
        a-cascader.cont(
            ref="cascader"
            v-model:value="searchParams.problemTypeId"
            :options="options"
            :field-names="{ label: 'name', value: 'id'}"
            :load-data="loadOptionsData"
            change-on-select
            :placeholder="$t('workOrder.serviceLog.problemType')"
        ).w-60
        a-select.w-60(v-model:value="searchParams.status" :placeholder="$t('workOrder.serviceLog.type')" :options="statusOptions" )
        a-select.w-60(v-model:value="searchParams.urgencyLevel" :placeholder="$t('workOrder.serviceLog.urgency')" :options="fastOptions")
        a-button(type="primary" @click="searchBox") {{ $t('workOrder.serviceLog.search') }}
        a-button(type="default" @click="resetBox") {{ $t('workOrder.serviceLog.reset') }}
    w-biz-table(
        :data="data?.list"
        :columns="columns"
        :pagination="paginationParams"
        :loading="loading"
        @change="sizeChange"
    )
        template(#emptyText)
            .py-10.txt-third-color {{ $t('v2.action.emptyData') }}
        template(#customColumn="{ record, column }")
            template(v-if="column.prop === 'first'")
                span {{ record.firstProblemTypeSimpleInfo.name }}
            template(v-if="column.prop === 'second'")
                span {{ record.secondProblemTypeSimpleInfo.name }}
            template(v-if="column.prop === 'status'")
                span {{ record.statusInfo.desc }}
            template(v-if="column.prop === 'action'")
                span.operateButton.pri-color.mr-4.cursor-pointer(hover="underline" type="link" @click="onDetail(record)") {{ $t('workOrder.serviceLog.look') }}
                span.operateButton.pri-color.cursor-pointer(v-if="!record.statusInfo.code" hover="underline" type="link" @click="onBackout(record)") {{ $t('workOrder.serviceLog.back') }}
    w-drawer(:title="$t('workOrder.serviceLog.detail')" :visible="drawer" @close="onCanCel")
        form(:key="timmer" ref="formRef" :model="formState").detailHeader.text-xs
            .reTitle.font-semibold.mb-4.text-sm {{ $t('workOrder.serviceLog.info') }}
            .contItem
                .formItem.mb-4.flex
                    .text-gray-500.mr-2 {{ $t('workOrder.serviceLog.affiliation') }}:
                    span.content {{ formState.firstProblemTypeSimpleInfo.name }}
            .contItem
                .formItem.mb-4.flex
                    .text-gray-500.mr-2 {{ $t('workOrder.serviceLog.state') }}:
                    span.content {{ formState.statusInfo.desc }}
            .contItem
                .formItem.mb-4.flex
                    .text-gray-500.mr-2 {{ $t('workOrder.serviceLog.question') }}:
                    span.content {{ formState.secondProblemTypeSimpleInfo.name }}
            .contItem
                .formItem.mb-4.flex
                    .text-gray-500.mr-2 {{ $t('workOrder.serviceLog.person') }}:
                    span.content {{ formState.createOperatorInfo.account}}
            .contItem
                .formItem.mb-4.flex
                    .text-gray-500.mr-2 {{ $t('workOrder.serviceLog.time') }}:
                    span.content {{ formState.createOperatorInfo.time }}
            .contItem
                .formItem.mb-4.flex
                    .text-gray-500.mr-2 {{ $t('workOrder.serviceLog.handleTime') }}:
                    span.content {{ formState.claimOperatorInfo }}
            .contItem
                .formItem.mb-4.flex
                    .text-gray-500.mr-2 {{ $t('workOrder.serviceLog.overTime') }}:
                    span.content {{ formState.finalityTime}}
            .reTitle.font-semibold.mb-4.text-sm.mt-6 {{ $t('workOrder.serviceLog.desc') }}
            .formItem.mb-4.flex
                .text-gray-500.mr-2 {{ $t('workOrder.serviceLog.desc') }}:
                span.content {{ formState.depict }}
            .formItem.mb-4.flex
                .text-gray-500.mr-2 {{ $t('workOrder.serviceLog.updateFile') }}:
                span.content
                    div(v-for="(item,index) in formState.accessoryInfoList" :key="item.typeCode")
                        div.contFile(v-if="item.typeCode === 1")
                            div(@click="handleImg(item,index)") {{ item.val }}
                            w-image(
                                v-if="imgIndex === index ? true : false"
                                :width="160"

                                :src="item.val"
                            )
                        div.contFile(v-if="item.typeCode === 2")
                            a(
                                :href="item.val"
                                :download="item.val"
                                class="ground_pic"
                            ) {{ item.val }}
                        div.contFile(v-if="item.typeCode === 3")
                            a(
                                :href="item.val"
                                :download="item.val"
                                class="ground_pic"
                            ) {{ item.val }}
                        div.contFile(v-if="item.typeCode === 4")
                            div(@click="handleAudio(item,index)") {{ item.val }}
                            w-modal(v-if="audioIndex === index ? true : false" v-model="audioModal" :title="$t('workOrder.serviceLog.file')" :footer="null")
                                audio(style="width: 400px; height: 50px;" :src="item.val" controls)
                        div.contFile(v-if="item.typeCode === 5")
                            div(@click="handleVideo(item,index)") {{ item.val }}
                            w-modal(v-if="videoIndex === index ? true : false" v-model="videoModal" :title="$t('workOrder.serviceLog.file')" :footer="null")
                                video(style="width: 620px; height: 200px;" :src="item.val" utoplay controls)
            .formItem.mb-4.flex
                .text-gray-500.mr-2 {{ $t('workOrder.serviceLog.phone') }}:
                span.content {{ formState.contactWayInfo.value }}
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useGlobalStore } from '@/store/global'
import { getLogList, getFastList, getEdit, getBackout, getProblemType } from "@/service/serviceLog"
import { onMounted, ref } from 'vue'
import { Toast } from '@welink/design';
const { t } = useI18n()
const route = useRoute()
const store = useGlobalStore()
const searchParams = ref({
    problemTypeId: [],
    status: null,
    urgencyLevel: null
})
const rowId = ref<any>()
const options = ref([]);
const fastOptions = ref<any>([])
const statusOptions = ref([
    { label: t('workOrder.serviceLog.pending'), value: 0 },
    { label: t('workOrder.serviceLog.inHand'), value: 1 },
    { label: t('workOrder.serviceLog.finish'), value: 2 },
    { label: t('workOrder.serviceLog.cancel'), value: 3 }
])
const formState = ref<any>({
    problemTypeName: null,
    "firstProblemTypeSimpleInfo": {
        "name": null
    },
    "secondProblemTypeSimpleInfo": {
        "name": null
    },
    "statusInfo": {
        "desc": null
    },
    "createOperatorInfo": {
        "time": null,
        "name": null
    },
    "contactWayInfo": {
        "value": null
    },
    "accessoryInfoList": [],
    content: null
})
const paginationParams = ref({
    current: 1,
    pageSize: 10,
    pageNo: 1,
    size: 'small',
    total: 0
})
const form = ref({
    applicationName: null,
    environmentId: store.envId,
    type: 2
})
const imgIndex = ref<any>(false)
const audioIndex = ref<any>(false)
const videoIndex = ref<any>(false)
// eslint-disable-next-line vue/no-ref-object-destructure
const { data, loading, run } = getLogList({ ...paginationParams.value })
const { data: dataFast, run: runFast } = getFastList({})
const { data: dataProblemType, run: runProblemType } = getProblemType({})
const drawer = ref(false)
const audioModal = ref(false)
const videoModal = ref(false)
const timmer = ref()
const handleImg = (url: any, index: any) => {
    imgIndex.value = index
}
const handleAudio = (url: any, index: any) => {
    audioIndex.value = index
    audioModal.value = true
}
const handleVideo = (data: any, index: any) => {
    videoIndex.value = index
    videoModal.value = true
}
const onCanCel = () => {
    form.value.applicationName = null
    form.value.type = 2
    drawer.value = false
}
const searchBox = () => {
    paginationParams.value.current = 1
    paginationParams.value.pageNo = 1
    getList()
}
const resetBox = () => {
    searchParams.value.problemTypeId = []
    searchParams.value.status = null
    searchParams.value.urgencyLevel = null
    getList()
}
const changeParams = (params: any) => {
    console.log(params)
    paginationParams.value.current = params
    paginationParams.value.pageNo = params
    getList()
}
const sizeChange =  ({ current, pageSize}: any) => {
    paginationParams.value.current = current
    paginationParams.value.pageNo = current
    paginationParams.value.size = pageSize
    paginationParams.value.pageSize = pageSize
    getList()
}
const getList = async () => {
    const params = {
        status: searchParams.value?.status,
        urgencyLevel: searchParams.value?.urgencyLevel,
        problemTypeId: ''
    }
    if (searchParams.value?.problemTypeId && searchParams.value?.problemTypeId?.length) {
        params.problemTypeId = searchParams.value?.problemTypeId[1]
    }
    await run({ ...paginationParams.value, ...params })
    paginationParams.value.total = data.value.total
}
const columns = ref<any>([
    {
        title: t('workOrder.serviceLog.feedbackCode'),
        prop: 'feedbackCode',
        width: 200
    },
    {
        title: t('workOrder.serviceLog.depict'),
        prop: 'depict',
        width: 200
    },
    {
        title: t('workOrder.serviceLog.first'),
        prop: 'first',
        width: 350
    },
    {
        title: t('workOrder.serviceLog.second'),
        prop: 'second',
        width: 350
    },
    {
        title: t('workOrder.serviceLog.status'),
        prop: 'status',
        width: 180
    },
    {
        title: t('workOrder.serviceLog.createTime'),
        prop: 'createTime',
        width: 240
    },
    {
        title: t('workOrder.serviceLog.createUserAccount'),
        prop: 'createUserAccount',
        width: 180
    },
    {
        title: t('workOrder.serviceLog.action'),
        prop: 'action',
        slot: 'action',
        width: 180
    },

])
const loadOptionsData = function (selectedOptions: any) {
    formState.value.problemTypeId = selectedOptions[0].id
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    setTimeout(() => {
        targetOption.loading = false;
        runProblemType({ parentId: selectedOptions[0].id }).then(res => {
            targetOption.children = dataProblemType.value.map((item: any) => {
                item.isLeaf = true
                return item
            })
        })
        options.value = [...options.value];
    }, 200)
}
const onDetail = async function (row: any) {
    drawer.value = true
    const { data: dataEdit, run: runEdit } = getEdit(row.id, { manual: true })
    runEdit().then((res) => {
        formState.value.firstProblemTypeSimpleInfo.name = dataEdit.value.firstProblemTypeSimpleInfo.name
        formState.value.statusInfo.desc = dataEdit.value.statusInfo.desc
        formState.value.secondProblemTypeSimpleInfo.name = dataEdit.value.secondProblemTypeSimpleInfo.name
        formState.value.createOperatorInfo.name = dataEdit.value.createOperatorInfo.name
        formState.value.createOperatorInfo.time = dataEdit.value.createOperatorInfo.time
        formState.value.createOperatorInfo.account = dataEdit.value.createOperatorInfo.account
        formState.value.claimOperatorInfo = dataEdit.value.claimTime
        formState.value.finalityTime = dataEdit.value.finalityTime
        formState.value.depict = dataEdit.value.depict
        formState.value.accessoryInfoList = dataEdit.value.accessoryInfoList
        formState.value.contactWayInfo.value = dataEdit.value.contactWayInfo.value
    })
}
const onBackout = async function (row: any) {
    const { data: dataBackout, run: runBackout } = getBackout({})
    runBackout({ id: row.id, action: 4 }).then(res => {
        Toast.success(t('workOrder.serviceLog.backoutSuccess'))
        run()
    })
}
onMounted(() => {
    if (route.query.workCode) {
        drawer.value = true
        const { data: dataEdit, run: runEdit } = getEdit(route.query.workCode, { manual: true })
        runEdit().then(res => {
            formState.value.firstProblemTypeSimpleInfo.name = dataEdit.value.firstProblemTypeSimpleInfo.name
            formState.value.statusInfo.desc = dataEdit.value.statusInfo.desc
            formState.value.secondProblemTypeSimpleInfo.name = dataEdit.value.secondProblemTypeSimpleInfo.name
            formState.value.createOperatorInfo.name = dataEdit.value.createOperatorInfo.name
            formState.value.createOperatorInfo.time = dataEdit.value.createOperatorInfo.time
            formState.value.createOperatorInfo.account = dataEdit.value.createOperatorInfo.account
            formState.value.claimOperatorInfo = dataEdit.value.claimTime
            formState.value.finalityTime = dataEdit.value.finalityTime
            formState.value.depict = dataEdit.value.depict
            formState.value.accessoryInfoList = dataEdit.value.accessoryInfoList
            formState.value.contactWayInfo.value = dataEdit.value.contactWayInfo.value
        })
    } else {
        getList()
    }
    runFast().then(res => {
        dataFast.value.forEach((item: any) => {
            fastOptions.value.push({
                value: item.code,
                label: item.desc,
            })
        })
    })
    runProblemType({ parentId: 0 }).then(res => {
        options.value = dataProblemType.value.map((item: any) => {
            item.isLeaf = false
            return item
        })
    })
})
</script>
<style lang="sass" scoped>
.home
    .contItem
        display: flex
        .formItem
            width: 300px
    .contFile
        color: #1890ff
</style>

<style lang="sass">
.ant-select-selection-placeholder
    color: rgba(0,0,0,.88)
</style>
