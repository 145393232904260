<template lang="pug">
a-steps.steps(
    class="!pt-10"
    :current="current"
    :items="items"
    label-placement="vertical"
)
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n()
defineProps<{
    current: number,
}>()

const items = [
    { title: t('v2.mainProcess.step1') },
    { title: t('v2.mainProcess.step2') },
    { title: t('v2.mainProcess.step3') },
    { title: t('v2.mainProcess.step4') },
]
</script>

<style lang="sass" scoped>
.steps
    padding: 0 0px
    :deep(.ant-steps-item)
        &.ant-steps-item-wait
            .ant-steps-item-icon
                background-color: transparent !important
                border: 1px solid #C9CDD4 !important
        .ant-steps-item-title
            line-height: 22px
</style>