<template lang="pug">
.flex.mb-4.justify-between.items-center
    h1.page-title.mb-0.pt-0 {{ $t('v2.bills.title') }}
.bg-white.pb-5.pt-0.px-5.rounded-lg.min-h-full.common-console-list
    .left-top
    .right-top
    .left-bottom
    .right-bottom
    .relative.z-2.py-4 222
        w-biz-table(
            :data="dataResult.records"
            :columns="columns"
            :loading="tableLoading"
            :pagination="{ current: dataResult.current, total: dataResult.total, pageSize: dataResult.size, size: 'small' }"
            @change="changeSize"
        )
            template(#emptyText)
                .py-10.txt-third-color {{ $t('v2.action.emptyData') }}
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import API from '@/common/api/console/v2/home'
const { t } = useI18n()

const tableLoading = ref(false)
const dataResult = ref({
    current: 1,
    total: 0,
    size: 10,
    records: []
})
const columns = ref<any>([
    {
        title: t('console.resource.table.id'),
        prop: 'id',
        width: 220
    }
])

const fetchList = (params = {}) => {
    const current = dataResult.value.current
    const size = dataResult.value.size
    tableLoading.value = true
    API.getResourceList({ current, size, ...params}).then((data: any) => {
        dataResult.value = data
        tableLoading.value = false
    }).catch(() => {
        tableLoading.value = false
    })
}


const changeSize = ({ current, pageSize}: any) => {
    fetchList({ current, size: pageSize })
}
onMounted(fetchList)
</script>