<template lang="pug">
page-sidebar(:menuList="menuList()")
page-body(v-if="store.isReady")
    router-view
div(v-else).w-full
    .flex.justify-center.pt-40
        a-spin(size="large" :spinning="true" :tip="$t('v2.action.loading') + '...'")
</template>
<script setup lang="ts">
import pageSidebar from '@/views/components/page-sidebar.vue';
import pageBody from '@/views/components/page-body.vue';
import { useGlobalStore } from '@/store/global'
import { menuList } from './menu'
import { LOCALE } from '@/common/constants/env'
const store = useGlobalStore()

onBeforeMount(async() => {
    document.body.classList.remove('page-full-content', LOCALE)
    await store.getEnvIds()
})
</script>

<style lang="sass">
.console-title
    @apply txt-color text-sm
.popup-edit-project,.popup-delete-project
    .ant-modal-content
        padding: 20px 0 !important
        .ant-modal-header
            padding-left: 28px
            margin-bottom: 0
            padding-bottom: 16px
        .ant-modal-body
            padding: 16px 28px
        .ant-modal-footer
            .ant-btn
                margin-left: 16px !important
.popup-edit-project
    .ant-modal-body
        border-top: 1px solid #F7F8FA
        border-bottom: 1px solid #F7F8FA
    .ant-modal-footer
        display: flex
        padding-right: 28px
        justify-content: flex-end

        .ant-btn[disabled]
            color: #C9CDD4
            border-color: transparent
            background-color: #F2F3F5 !important

// 主流程样式
.console-main-process
    @apply px-18 pt-10 pb-15 min-h-full flex flex-col
    background: linear-gradient(180deg, #FFF 0%, #E3E8F7 218.48%)
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25)
    .inner
        @apply min-h-full flex-1
        border-radius: 10px
        margin-top: 36px
        border: 1px solid rgba(18, 78, 244, 0.20)
        background: #fff url('@/assets/images/console/main-process-bg.png') center bottom no-repeat
        background-size: cover
        position: relative
        .close
            position: absolute
            right: 20px
            top: 20px
            opacity: 0.3
            transtion: .3s
            cursor: pointer
            &:hover
                opacity: 1
        .ant-btn[disabled]
            color: #C9CDD4
            border-color: transparent
            background-color: #F2F3F5 !important
            .inner
        .label
            @apply txt-color pt-2
            font-size: 20px
            font-weight: 500
            line-height: 28px
        .order
            color: #6AA1FF
            font-size: 36px
            font-weight: 700
            line-height: 32px
            padding-right: 14px
// 分页样式
.ant-pagination-mini 
    display: flex !important
    gap: 10px
    margin-top: 40px !important
    .ant-pagination-item
        font-size: 12px
// 上传游戏展开收起样式
.collapse-base-info
    color: #000
    .ant-collapse-header,.ant-collapse-content-box
        padding: 0 !important
.ant-select-selection-search-input
    box-shadow: none !important
// 数字 加减样式
.ant-input-number-wrapper 
    .ant-input-number-input
        text-align: center !important
    .ant-input-number-group-addon
        cursor: pointer
        user-select: none
        padding: 0 !important
        overflow: hidden
        .action
            padding: 8px 11px
        &:hover
            background-color: rgba(0,0,0,.1) !important
        .disabled
            color: rgba(0,0,0,.1)
            background-color: rgba(0,0,0,.01) !important
// 时间选择样式
.ant-picker
    .ant-picker-input input
        border-radius: 0 !important
// 列表页面四个角图案
.common-console-list
    position: relative
    .left-top
        position: absolute
        left: 0
        top: 0
        width: 250px
        height: 250px
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjExIiBoZWlnaHQ9IjIyMiIgdmlld0JveD0iMCAwIDIxMSAyMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yMC44MDgyIDc4LjE1ODlMNjcuNjczMyAzMS45NDU2TDg0LjI2MjYgOTUuNjM4NkwyMC44MDgyIDc4LjE1ODlaIiBzdHJva2U9IiNGNkY5RkYiLz4KPHBhdGggZD0iTS0xNS42MTEgODQuOTQ4TDgwLjAwMzQgLTIuOTg4ODFMMTA4LjM1MiAxMjMuNzg0TC0xNS42MTEgODQuOTQ4WiIgc3Ryb2tlPSIjRjZGOUZGIi8+CjxwYXRoIGQ9Ik0tMzMuNTU4MSA4Ny4wODk3TDg3LjEyMjIgLTE5LjYwMjZMMTE5LjE4IDEzOC4yNTZMLTMzLjU1ODEgODcuMDg5N1oiIHN0cm9rZT0iI0Y2RjlGRiIvPgo8cGF0aCBkPSJNLTQzLjczNzcgODcuMTE0MUw5Mi4xOTA4IC0yOC40M0wxMjQuMjkxIDE0Ny4wNkwtNDMuNzM3NyA4Ny4xMTQxWiIgc3Ryb2tlPSIjRjZGOUZGIi8+CjxwYXRoIGQ9Ik0tNDkuOTc2MyA4Ni4yNTQ0TDk2LjA1NDkgLTMzLjQwMjlMMTI2LjY2NiAxNTIuODkzTC00OS45NzYzIDg2LjI1NDRaIiBzdHJva2U9IiNGNkY5RkYiLz4KPHBhdGggZD0iTS01My4yMjM3IDg0Ljg5NzVMOTguODUzNSAtMzUuNTM3MkwxMjcuMTE0IDE1Ni4zODNMLTUzLjIyMzcgODQuODk3NVoiIHN0cm9rZT0iI0Y2RjlGRiIvPgo8L3N2Zz4K") no-repeat left top
    .right-top
        position: absolute
        right: 0
        top: 0
        width: 204px
        height: 204px
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTY5IiBoZWlnaHQ9IjE1OSIgdmlld0JveD0iMCAwIDE2OSAxNTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9IjczLjQyMDEiIHk9IjM4Ljg0MzgiIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgdHJhbnNmb3JtPSJyb3RhdGUoLTE1IDczLjQyMDEgMzguODQzOCkiIHN0cm9rZT0iI0Y2RjlGRiIvPgo8cmVjdCB4PSI0Ny43OTM1IiB5PSIyOS4zNzg1IiB3aWR0aD0iODYuMzI1OCIgaGVpZ2h0PSI4Ni4zMjU4IiB0cmFuc2Zvcm09InJvdGF0ZSgtMTkuMzM3NCA0Ny43OTM1IDI5LjM3ODUpIiBzdHJva2U9IiNGNkY5RkYiLz4KPHJlY3QgeD0iMjkuMDA3MiIgeT0iMjcuMzk5NiIgd2lkdGg9IjExMS44NDYiIGhlaWdodD0iMTExLjg0NiIgdHJhbnNmb3JtPSJyb3RhdGUoLTIzLjk0NDEgMjkuMDA3MiAyNy4zOTk2KSIgc3Ryb2tlPSIjRjZGOUZGIi8+CjxyZWN0IHg9IjE0LjY1NzciIHk9IjI4LjQ3OTkiIHdpZHRoPSIxMzAuNTI3IiBoZWlnaHQ9IjEzMC41MjciIHRyYW5zZm9ybT0icm90YXRlKC0yNy43NzMgMTQuNjU3NyAyOC40Nzk5KSIgc3Ryb2tlPSIjRjZGOUZGIi8+CjxyZWN0IHg9IjYuMjg4NDMiIHk9IjMxLjcyODgiIHdpZHRoPSIxNDAuNjkzIiBoZWlnaHQ9IjE0MC42OTMiIHRyYW5zZm9ybT0icm90YXRlKC0zMC45ODk1IDYuMjg4NDMgMzEuNzI4OCkiIHN0cm9rZT0iI0Y2RjlGRiIvPgo8cmVjdCB4PSIwLjY5MDk3OSIgeT0iMzMuNjIwOSIgd2lkdGg9IjE0Ny43OTUiIGhlaWdodD0iMTQ3Ljc5NSIgdHJhbnNmb3JtPSJyb3RhdGUoLTMyLjczOTMgMC42OTA5NzkgMzMuNjIwOSkiIHN0cm9rZT0iI0Y2RjlGRiIvPgo8L3N2Zz4K") no-repeat right top
    .left-bottom
        position: absolute
        left: 0
        bottom: 0
        width: 176px
        height: 176px
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYzIiBoZWlnaHQ9IjE0MCIgdmlld0JveD0iMCAwIDE2MyAxNDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHgxPSI0MS43MTk4IiB5MT0iNjIuODc2MyIgeDI9IjEwNy4xNzgiIHkyPSIxMTQuNjE4IiBzdHJva2U9IiNGNkY5RkYiLz4KPGxpbmUgeTE9Ii0wLjUiIHgyPSI4My40Mzg2IiB5Mj0iLTAuNSIgdHJhbnNmb3JtPSJtYXRyaXgoLTAuNjIwMTIgMC43ODQ1MDcgMC43ODQ1MDcgMC42MjAxMiAxMDAuMDEgNTYuNDEwMikiIHN0cm9rZT0iI0Y2RjlGRiIvPgo8bGluZSB4MT0iMzMuMDU1NCIgeTE9IjQxLjQ2NDUiIHgyPSIxMTUuOTc1IiB5Mj0iMTM2LjE1NSIgc3Ryb2tlPSIjRjZGOUZGIi8+CjxsaW5lIHkxPSItMC41IiB4Mj0iMTI1Ljg2NSIgeTI9Ii0wLjUiIHRyYW5zZm9ybT0ibWF0cml4KC0wLjc1MjMyMSAwLjY1ODc5NyAwLjY1ODc5NyAwLjc1MjMyMSAxMjEuNDg0IDQ3LjY3OTcpIiBzdHJva2U9IiNGNkY5RkYiLz4KPGxpbmUgeDE9IjI3LjI5NzgiIHkxPSIyMy4yMDY5IiB4Mj0iMTIxLjc5MiIgeTI9IjE1NC40ODgiIHN0cm9rZT0iI0Y2RjlGRiIvPgo8bGluZSB5MT0iLTAuNSIgeDI9IjE2MS43NTIiIHkyPSItMC41IiB0cmFuc2Zvcm09Im1hdHJpeCgtMC44MTE2MTcgMC41ODQxODkgMC41ODQxODkgMC44MTE2MTcgMTM5Ljc4IDQxLjg5MjYpIiBzdHJva2U9IiNGNkY5RkYiLz4KPGxpbmUgeDE9IjIxLjcwNjMiIHkxPSI5LjI5MjciIHgyPSIxMjcuNDA1IiB5Mj0iMTY4LjQzMiIgc3Ryb2tlPSIjRjZGOUZGIi8+CjxsaW5lIHkxPSItMC41IiB4Mj0iMTkxLjA0MyIgeTI9Ii0wLjUiIHRyYW5zZm9ybT0ibWF0cml4KC0wLjgzMzAwMiAwLjU1MzI2OSAwLjU1MzI2OSAwLjgzMzAwMiAxNTMuNzA5IDM2LjI5KSIgc3Ryb2tlPSIjRjZGOUZGIi8+CjxsaW5lIHgxPSIxOS4xNTU2IiB5MT0iMC43MzM4OTciIHgyPSIxMjkuOTY5IiB5Mj0iMTc3LjAxMiIgc3Ryb2tlPSIjRjZGOUZGIi8+CjxsaW5lIHkxPSItMC41IiB4Mj0iMjA4LjIxNSIgeTI9Ii0wLjUiIHRyYW5zZm9ybT0ibWF0cml4KC0wLjg0NjYxNSAwLjUzMjIwNiAwLjUzMjIwNiAwLjg0NjYxNSAxNjIuMjc4IDMzLjczMjQpIiBzdHJva2U9IiNGNkY5RkYiLz4KPC9zdmc+Cg==") no-repeat left bottom
    .right-bottom
        position: absolute
        bottom: 0
        right: 0
        width: 410px
        height: 410px
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjA1IiBoZWlnaHQ9IjIwNSIgdmlld0JveD0iMCAwIDIwNSAyMDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxjaXJjbGUgY3g9IjIwNSIgY3k9IjIwNSIgcj0iMTI3LjYyNSIgc3Ryb2tlPSIjRjZGOUZGIi8+CjxjaXJjbGUgY3g9IjIwNSIgY3k9IjIwNSIgcj0iODIuNjA4MSIgc3Ryb2tlPSIjRjZGOUZGIi8+CjxjaXJjbGUgY3g9IjIwNSIgY3k9IjIwNSIgcj0iMjMuNSIgc3Ryb2tlPSIjRjZGOUZGIi8+CjxjaXJjbGUgY3g9IjIwNSIgY3k9IjIwNSIgcj0iMTU1LjU4IiBzdHJva2U9IiNGNkY5RkYiLz4KPGNpcmNsZSBjeD0iMjA1IiBjeT0iMjA1IiByPSIxNzYuNTQ1IiBzdHJva2U9IiNGNkY5RkYiLz4KPGNpcmNsZSBjeD0iMjA1IiBjeT0iMjA1IiByPSIxOTAuNzczIiBzdHJva2U9IiNGNkY5RkYiIHN0cm9rZS13aWR0aD0iMC41Ii8+CjxjaXJjbGUgY3g9IjIwNSIgY3k9IjIwNSIgcj0iMjAwLjA5MSIgc3Ryb2tlPSIjRjZGOUZGIiBzdHJva2Utd2lkdGg9IjAuNSIvPgo8Y2lyY2xlIGN4PSIyMDUiIGN5PSIyMDUiIHI9IjIwNC43NSIgc3Ryb2tlPSIjRjZGOUZGIiBzdHJva2Utd2lkdGg9IjAuNSIvPgo8L3N2Zz4K") no-repeat right bottom
</style>