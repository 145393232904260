import queryString from 'query-string';

export function getQueryString(name?: string) {

    const parseSearch = queryString.parse(window.location.search)
    if (name) {
        return parseSearch?.[name]
    }
    return parseSearch;
}


export const objToQueryString = (obj: any) => queryString.stringify(obj);