<template lang="pug">
.page-login
    .login-panel.border.w-96.fixed(class="top-1/2 shadow -translate-y-1/2  p-6")
        h2.title.mb-4 {{ $t('userCenter.login.title') }}
        .inner.gap-6.grid
            .item(:class="{ 'error': formState.username }")
                w-input.input#email.username(v-model="form.username" type="email", :placeholder="$t('userCenter.login.foreign.username.text')")
                .msg {{ $t('userCenter.login.foreign.username.error') }}
            .item(:class="{ 'error': formState.password }")
                w-input.input#password.email(v-model="form.password" type="password", :placeholder="$t('userCenter.login.foreign.password.text')")
                .msg {{ $t('userCenter.login.foreign.password.error') }}
            .item(:class="{ 'error': formState.verifyCode }")
                .relative
                    w-input(
                        v-model="form.verifyCode"
                        :placeholder="$t('userCenter.login.foreign.verifyCode.text')"
                        autocomplete="new-password"
                        @keyup.enter="login"
                    )
                    img(class="absolute top-0 right-0 p-1 h-full" :src="verifyCode.base64Image", @click="getImgVerifyCodeInEmail")
                .msg {{ $t('userCenter.login.foreign.verifyCode.error') }}
            p.text-xs.text-gray-500(class="-mt-2 pl-1 flex items-start")
                input#checkbox(v-model="formState.checked" type="checkbox", class="w-3 h-3 align-middle text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mr-1")
                label(for="checkbox", class="ml-1 text-xs font-medium text-gray-400 dark:text-gray-500")
                    | {{ $t('userCenter.service.t1') }}
                    //- router-link.text-blue-400.mx-1(target="_blank" :to="{ name: 'legal_notice' }") {{ $t('userCenter.service.t3') }}
                    //- | {{ $t('userCenter.service.and') }}
                    //- router-link.text-blue-400.mx-1(target="_blank" :to="{ name: 'user_agreement' }") {{ $t('userCenter.service.t2') }}
                    //- | {{ $t('userCenter.service.and') }}
                    //- router-link.text-blue-400.mx-1(target="_blank" :to="{ name: 'legal_statement' }") {{ $t('userCenter.service.t4') }}
            w-button(:loading="loading" @click="login") {{ $t('userCenter.login.title') }}
            span(class="-mt-3 cursor-pointer text-xs text-gray-400 w-full block text-center" @click="modifyPwd") {{ $t('userCenter.login.forgot') }}
</template>
<script setup lang="ts">
import API from '@/common/api'
import { Toast } from '@welink/design';
import { saveLoginInfo } from '@/utils/sso'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const form = reactive({
    username: '',
    password: '',
    verifyCodeKey: '',
    verifyCode: ''
})

const loading = ref(false)
const formState = reactive({
    username: false,
    password: false,
    verifyCode: false,
    checked: false,
})

const verifyCode = ref<any>({
    base64Image: '',
    code: null,
    expiresAt: '',
    width: '',
    height:'',
    verifyCodeKey: ''
})

const getImgVerifyCodeInEmail = async() => {
    const res: any = await API.userCenter.getImgVerifyCodeInEmail()
    form.verifyCodeKey = res.verifyCodeKey
    verifyCode.value = res
}

const modifyPwd = () => router.push({ name: 'userCenteModifyPwd'})
const login = async() => {
    formState.username = Boolean(!form.username.trim())
    formState.password = Boolean(!form.password.trim())
    formState.verifyCode = Boolean(!form.verifyCode.trim())
    if (!form.username || !form.password || !form.verifyCode) {
        return false
    }
    if(!formState.checked) {
        Toast.warning(t('userCenter.login.agree'))
        return false
    }
    try {
        loading.value = true
        const res = await API.userCenter.foreignLogin(form)
        loading.value = false
        if (res) {
            saveLoginInfo(res)
            let toUrl: any = '/'
            if (route.query.redirect) {
                toUrl = decodeURIComponent(route.query.redirect as string)
            }
            router.push(toUrl)
        } else {
            getImgVerifyCodeInEmail()
        }
    } catch(e) {
        loading.value = false
        getImgVerifyCodeInEmail()
    }
}

onMounted(getImgVerifyCodeInEmail)
</script>

<style scoped lang="sass">
.page-login
    width: 100%
    height: 100vh
    // background: url("@/assets/svg/login-bg.png")
    background-size: 100%
    background-position: right center
.login-panel
    // left: 250px
    left: 50%
    transform: translate(-50%, -50%)
    background-color: #fff
    .msg
        display: none
        @apply mt-2 text-xs text-red-600 dark:text-red-500
    .error
        .msg
            display: block
        input
            @apply bg-red-50 border border-red-500 text-red-900
.getcode
    cursor: pointer
    display: block
    height: 100%
    line-height: 42px
    font-size: 12px
    color: #aaa
    padding-right: 10px
.imgCode
    height: 40px
</style>