<template lang="pug">
page-sidebar(:menuList="menuList()")
page-body
    h1.page-title {{ route.meta.title }}
    .bg-white.p-5.rounded-lg.min-h-full
        router-view
</template>

<script lang="ts" setup>
import { menuList } from './menu'
import PageSidebar from '@/views/components/page-sidebar.vue';
import PageBody from '@/views/components/page-body.vue';

const route = useRoute()

watchEffect(() => {

    const pageClass = (route.meta.pageClass as string) || ''
    if (pageClass) {
        document.body.classList.add(pageClass)
    }
})

onBeforeRouteUpdate((to, from) => {
    const pageClass = (from.meta.pageClass as string) || ''
    if (pageClass) {
        document.body.classList.remove(pageClass)
    }
})


</script>
<style lang="sass">
.user-login
    flex:1
    width: 100%
    height: 100%
    background: #F7F8F9
.page-full-content,.page-register,.page-login,.page-reset-pwd
    a:hover
        @apply text-blue-800
    .page-siderbar-wrap,.page-thumb
        display: none
    .page-header
        .opts
            .login-hide
                display: none !important
    &.en
        background: none
.page-login
    width: 100%
    height: 100vh
    background: url("@/assets/svg/login-bg.png") no-repeat
    background-size: cover
    background-position: right center
#form_item_password
    border-radius: 0 !important
.page-register,.page-login,.page-reset-pwd
    background-color: #F9FAFB
    .imgCode
        height: 40px
    .getCode
        cursor: pointer
        display: block
        font-size: 14px
        color: #666
        width: 100px
        height: 40px
        line-height: 40px
        box-sizing: border-box
        border: 1px solid #d9d9d9
        margin-left: 16px
        text-align: center
        background: #fff
        user-select: none
        &.disabled
            opacity: 0.6
            border-color: #d9d9d9 !important
            box-shadow: none !important
            color: #666 !important
        &:hover
            color: #000
            box-shadow: 0 0 0 1px #1C64FF
            border-color: #1c64ff
    .page-wrapper
        background-color: transparent
    .ant-form-item-explain-error
        font-size: 12px
    .ant-input-group-addon
        color: rgba(107, 114, 128, 1) !important
    .footer
        position: fixed
        bottom: 0
        text-align: center
</style>