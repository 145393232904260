import { i18n } from '@/i18n'
// 需要展示的code
export const warningCode: any = {
    6018: i18n.global.t('gameError.e6018'),
    6506: i18n.global.t('gameError.e6506'),
    6041: i18n.global.t('gameError.e6041'),
    6043: i18n.global.t('gameError.e6043'),
    6075: i18n.global.t('gameError.e6075'),
    6071: i18n.global.t('gameError.e6071'),
    6100: i18n.global.t('gameError.e6100'),
    6104: i18n.global.t('gameError.e6104'),
    6106: i18n.global.t('gameError.e6106'),
    6114: i18n.global.t('gameError.e6114'),
    6116: i18n.global.t('gameError.e6116'),
    6121: i18n.global.t('gameError.e6121'),
    6141: i18n.global.t('gameError.e6141'),
    6142: i18n.global.t('gameError.e6142'),
    6319: i18n.global.t('gameError.e6319'),
};
// 需要结束游戏的code
export const errorCode: any = {
    1001: i18n.global.t('gameError.e1001'),
    1002: i18n.global.t('gameError.e1002'),
    1003: i18n.global.t('gameError.e1003'),
    1010: i18n.global.t('gameError.e1010'),
    1011: i18n.global.t('gameError.e1011'),
    1012: i18n.global.t('gameError.e1012'),
    1013: i18n.global.t('gameError.e1013'),
    1017: i18n.global.t('gameError.e1017'),
    1018: i18n.global.t('gameError.e1018'),
    1019: i18n.global.t('gameError.e1019'),
    1020: i18n.global.t('gameError.e1020'),
    1030: i18n.global.t('gameError.e1030'),
    1040: i18n.global.t('gameError.e1040'),
    1050: i18n.global.t('gameError.e1050'),
    1060: i18n.global.t('gameError.e1060'),
    1080: i18n.global.t('gameError.e1080'),
    1110: i18n.global.t('gameError.e1110'),
    1111: i18n.global.t('gameError.e1111'),
    1112: i18n.global.t('gameError.e1112'),
    7012: i18n.global.t('gameError.e7012'),
    7103: i18n.global.t('gameError.e7103'),
    7104: i18n.global.t('gameError.e7104'),
    2040: i18n.global.t('gameError.e2040'),
};

// 按键定义 参考[蔚领云游戏JSSDK对接文档- 6.2 6.2 手柄按键定义]
export const enum BUTTONCODE {
    AXIS_UP = 1,
    AXIS_DOWN = 2,
    AXIS_LEFT = 4,
    AXIS_RIGHT = 8,
    START = 16,
    BACK = 32,
    LEFT_THUMB = 64,
    RIGHT_THUMB = 128,
    LEFT_SHOULDER = 256,
    RIGHT_SHOULDER = 512,
    A = 4096,
    B = 8192,
    X = 16384,
    Y = 32768,
}
// keyBoardEvent.keycode 枚举 已标记废弃
export const enum KEYBOARD_KEYCODE {
    Backspace = 8,
    Tab = 9,
    Enter = 13,
    Shift = 16,
    Control = 17,
    Alt = 18,
    Pause = 19,
    CapsLock = 20,
    Escape = 27,
    Space = 32,
    PageUp = 33,
    PageDown = 34,
    End = 35,
    Home = 36,
    Select = 41,
    Print = 42,
    Execute = 43,
    PrintScreen = 44,
    Insert = 45,
    Delete = 46,
    Help = 47,
    Digit0 = 48,
    Digit1 = 49,
    Digit2 = 50,
    Digit3 = 51,
    Digit4 = 52,
    Digit5 = 53,
    Digit6 = 54,
    Digit7 = 55,
    Digit8 = 56,
    Digit9 = 57,
    a = 65,
    b = 66,
    c = 67,
    d = 68,
    e = 69,
    f = 70,
    g = 71,
    h = 72,
    i = 73,
    j = 74,
    k = 75,
    l = 76,
    m = 77,
    n = 78,
    o = 79,
    p = 80,
    q = 81,
    r = 82,
    s = 83,
    t = 84,
    u = 85,
    v = 86,
    w = 87,
    x = 88,
    y = 89,
    z = 90,
    Meta = 91,
    ContextMenu = 93,
    Sleep = 95,
    NumPad0 = 96,
    NumPad1 = 97,
    NumPad2 = 98,
    NumPad3 = 99,
    NumPad4 = 100,
    NumPad5 = 101,
    NumPad6 = 102,
    NumPad7 = 103,
    NumPad8 = 104,
    NumPad9 = 105,
    Multiply = 106,
    Add = 107,
    Separator = 108,
    Subtract = 109,
    Decimal = 110,
    Divide = 111,
    F1 = 112,
    F2 = 113,
    F3 = 114,
    F4 = 115,
    F5 = 116,
    F6 = 117,
    F7 = 118,
    F8 = 119,
    F9 = 120,
    F10 = 121,
    F11 = 122,
    F12 = 123,
    F13 = 124,
    F14 = 125,
    F15 = 126,
    F16 = 127,
    F17 = 128,
    F18 = 129,
    F19 = 130,
    F20 = 131,
    F21 = 132,
    F22 = 133,
    F23 = 134,
    F24 = 135,
    NumLock = 136,
    ScrollLock = 137,
    Semicolon = 188,
    Equal = 187,
    Comma = 188,
    Minus = 189,
    Period = 190,
    Slash = 191,
    Backquote = 192,
    BracketLeft = 219,
    Backslash = 220,
    BracketRight = 221,
    Quote = 222,
    AltGraph = 225,
    Attn = 246,
    CrSel = 247,
    ExSel = 248,
    EraseEof = 249,
    Play = 250,
    ZoomOut = 251,
    Clear = 254,
    MetaLeft = 91,
    MetaRight = 92,
    NumpadEnter = 13,
    NumpadEqual = 187,
    NumpadComma = 188,
    NumpadDivide = 111,
    NumpadMultiply = 106,
    NumpadSubtract = 109,
    NumpadAdd = 107,
    NumpadDecimal = 110,
    Numpad0 = 96,
    Numpad1 = 97,
    Numpad2 = 98,
    Numpad3 = 99,
    Numpad4 = 100,
    Numpad5 = 101,
    Numpad6 = 102,
    Numpad7 = 103,
    Numpad8 = 104,
    Numpad9 = 105,
    NumpadParenLeft = 57,
    NumpadParenRight = 48,
    NumpadBackspace = 8,
    NumpadMemoryStore = 42,
    NumpadMemoryRecall = 42,
    NumpadMemoryClear = 42,
    NumpadMemoryAdd = 42,
    NumpadMemorySubtract = 42,
    NumpadSignChange = 42,
    NumpadClear = 12,
    NumpadClearEntry = 12,
    NumpadBinary = 42,
    NumpadOctal = 42,
    NumpadHexadecimal = 42,
    IntlBackslash = 220,
    IntlRo = 193,
    IntlYen = 165,
    IntlHash = 51,
    IntlUnderscore = 189,
    IntlPipe = 220,
    IntlTilde = 192,
    IntlBraceLeft = 219,
    IntlBraceRight = 221,
    IntlQuote = 222,
    IntlBackquote = 192,
    IntlSlash = 191,
    ShiftLeft = 16,
    ShiftRight = 16,
    ControlLeft = 17,
    ControlRight = 17,
    AltLeft = 18,
    AltRight = 18,
    CommandLeft = 91,
    CommandRight = 93,
    OSLeft = 91,
    OSRight = 93,
    AudioVolumeDown = 174,
    AudioVolumeUp = 175,
    AudioVolumeMute = 173,
    MediaTrackNext = 176,
    MediaTrackPrevious = 177,
    MediaStop = 178,
    MediaPlayPause = 179,
    MediaSelect = 181,
    LaunchMail = 180,
    LaunchMediaSelect = 181,
    LaunchApplication1 = 182,
    LaunchApplication2 = 183,
    BrowserBack = 166,
    BrowserForward = 167,
    BrowserRefresh = 168,
    BrowserStop = 169,
    BrowserSearch = 170,
    BrowserFavorites = 171,
    BrowserHome = 172,
    Power = 192,
    ArrowDown = 40,
    ArrowLeft = 37,
    ArrowRight = 39,
    ArrowUp = 38,
}
// KeyboardEvent.code 枚举
export const enum KEYBOARD_CODE {
    Backquote = "Backquote",
    Backslash = "Backslash",
    Backspace = "Backspace",
    BracketLeft = "BracketLeft",
    BracketRight = "BracketRight",
    Comma = "Comma",
    Digit0 = "Digit0",
    Digit1 = "Digit1",
    Digit2 = "Digit2",
    Digit3 = "Digit3",
    Digit4 = "Digit4",
    Digit5 = "Digit5",
    Digit6 = "Digit6",
    Digit7 = "Digit7",
    Digit8 = "Digit8",
    Digit9 = "Digit9",
    Enter = "Enter",
    Equal = "Equal",
    IntlBackslash = "IntlBackslash",
    IntlRo = "IntlRo",
    IntlYen = "IntlYen",
    a = "KeyA",
    b = "KeyB",
    c = "KeyC",
    d = "KeyD",
    e = "KeyE",
    f = "KeyF",
    g = "KeyG",
    h = "KeyH",
    i = "KeyI",
    j = "KeyJ",
    k = "KeyK",
    l = "KeyL",
    m = "KeyM",
    n = "KeyN",
    o = "KeyO",
    p = "KeyP",
    q = "KeyQ",
    r = "KeyR",
    s = "KeyS",
    t = "KeyT",
    u = "KeyU",
    v = "KeyV",
    w = "KeyW",
    x = "KeyX",
    y = "KeyY",
    z = "KeyZ",
    Minus = "Minus",
    Period = "Period",
    Quote = "Quote",
    Semicolon = "Semicolon",
    Slash = "Slash",
    AltLeft = "AltLeft",
    AltRight = "AltRight",
    ArrowDown = "ArrowDown",
    ArrowLeft = "ArrowLeft",
    ArrowRight = "ArrowRight",
    ArrowUp = "ArrowUp",
    AudioVolumeDown = "AudioVolumeDown",
    AudioVolumeMute = "AudioVolumeMute",
    AudioVolumeUp = "AudioVolumeUp",
    CapsLock = "CapsLock",
    ControlLeft = "ControlLeft",
    ControlRight = "ControlRight",
    ContextMenu = "ContextMenu",
    Num0 = "Numpad0",
    Num1 = "Numpad1",
    Num2 = "Numpad2",
    Num3 = "Numpad3",
    Num4 = "Numpad4",
    Num5 = "Numpad5",
    Num6 = "Numpad6",
    Num7 = "Numpad7",
    Num8 = "Numpad8",
    Num9 = "Numpad9",
    F1 = "F1",
    F2 = "F2",
    F3 = "F3",
    F4 = "F4",
    F5 = "F5",
    F6 = "F6",
    F7 = "F7",
    F8 = "F8",
    F9 = "F9",
    F10 = "F10",
    F11 = "F11",
    F12 = "F12",
    MetaLeft = "MetaLeft",
    MetaRight = "MetaRight",
    OSLeft = "OSLeft",
    OSRight = "OSRight",
    ShiftLeft = "ShiftLeft",
    ShiftRight = "ShiftRight",
    Space = "Space",
    Tab = "Tab",
}
// KeyboardEvent.keycode KeboardEvent.code 对应表
export const KEYBOARD_KEYCODE_MAP = {
    Backquote: KEYBOARD_KEYCODE.Backquote,
    Backslash: KEYBOARD_KEYCODE.Backslash,
    Backspace: KEYBOARD_KEYCODE.Backspace,
    BracketLeft: KEYBOARD_KEYCODE.BracketLeft,
    BracketRight: KEYBOARD_KEYCODE.BracketRight,
    Comma: KEYBOARD_KEYCODE.Comma,
    Digit0: KEYBOARD_KEYCODE.Digit0,
    Digit1: KEYBOARD_KEYCODE.Digit1,
    Digit2: KEYBOARD_KEYCODE.Digit2,
    Digit3: KEYBOARD_KEYCODE.Digit3,
    Digit4: KEYBOARD_KEYCODE.Digit4,
    Digit5: KEYBOARD_KEYCODE.Digit5,
    Digit6: KEYBOARD_KEYCODE.Digit6,
    Digit7: KEYBOARD_KEYCODE.Digit7,
    Digit8: KEYBOARD_KEYCODE.Digit8,
    Digit9: KEYBOARD_KEYCODE.Digit9,
    Enter: KEYBOARD_KEYCODE.Enter,
    Equal: KEYBOARD_KEYCODE.Equal,
    F1: KEYBOARD_KEYCODE.F1,
    F2: KEYBOARD_KEYCODE.F2,
    F3: KEYBOARD_KEYCODE.F3,
    F4: KEYBOARD_KEYCODE.F4,
    F5: KEYBOARD_KEYCODE.F5,
    F6: KEYBOARD_KEYCODE.F6,
    F7: KEYBOARD_KEYCODE.F7,
    F8: KEYBOARD_KEYCODE.F8,
    F9: KEYBOARD_KEYCODE.F9,
    F10: KEYBOARD_KEYCODE.F10,
    F11: KEYBOARD_KEYCODE.F11,
    F12: KEYBOARD_KEYCODE.F12,
    Tab: KEYBOARD_KEYCODE.Tab,
    KeyA: KEYBOARD_KEYCODE.a,
    KeyB: KEYBOARD_KEYCODE.b,
    KeyC: KEYBOARD_KEYCODE.c,
    KeyD: KEYBOARD_KEYCODE.d,
    KeyE: KEYBOARD_KEYCODE.e,
    KeyF: KEYBOARD_KEYCODE.f,
    KeyG: KEYBOARD_KEYCODE.g,
    KeyH: KEYBOARD_KEYCODE.h,
    KeyI: KEYBOARD_KEYCODE.i,
    KeyJ: KEYBOARD_KEYCODE.j,
    KeyK: KEYBOARD_KEYCODE.k,
    KeyL: KEYBOARD_KEYCODE.l,
    KeyM: KEYBOARD_KEYCODE.m,
    KeyN: KEYBOARD_KEYCODE.n,
    KeyO: KEYBOARD_KEYCODE.o,
    KeyP: KEYBOARD_KEYCODE.p,
    KeyQ: KEYBOARD_KEYCODE.q,
    KeyR: KEYBOARD_KEYCODE.r,
    KeyS: KEYBOARD_KEYCODE.s,
    KeyT: KEYBOARD_KEYCODE.t,
    KeyU: KEYBOARD_KEYCODE.u,
    KeyV: KEYBOARD_KEYCODE.v,
    KeyW: KEYBOARD_KEYCODE.w,
    KeyX: KEYBOARD_KEYCODE.x,
    KeyY: KEYBOARD_KEYCODE.y,
    KeyZ: KEYBOARD_KEYCODE.z,
    Minus: KEYBOARD_KEYCODE.Minus,
    Period: KEYBOARD_KEYCODE.Period,
    Quote: KEYBOARD_KEYCODE.Quote,
    Semicolon: KEYBOARD_KEYCODE.Semicolon,
    Slash: KEYBOARD_KEYCODE.Slash,
    Space: KEYBOARD_KEYCODE.Space,
    AltLeft: KEYBOARD_KEYCODE.AltLeft,
    AltRight: KEYBOARD_KEYCODE.AltRight,
    AudioVolumeDown: KEYBOARD_KEYCODE.AudioVolumeDown,
    AudioVolumeMute: KEYBOARD_KEYCODE.AudioVolumeMute,
    AudioVolumeUp: KEYBOARD_KEYCODE.AudioVolumeUp,
    CapsLock: KEYBOARD_KEYCODE.CapsLock,
    ControlLeft: KEYBOARD_KEYCODE.ControlLeft,
    ControlRight: KEYBOARD_KEYCODE.ControlRight,
    ContextMenu: KEYBOARD_KEYCODE.ContextMenu,
    Delete: KEYBOARD_KEYCODE.Delete,
    End: KEYBOARD_KEYCODE.End,
    Help: KEYBOARD_KEYCODE.Help,
    Home: KEYBOARD_KEYCODE.Home,
    Insert: KEYBOARD_KEYCODE.Insert,
    MetaLeft: KEYBOARD_KEYCODE.MetaLeft,
    MetaRight: KEYBOARD_KEYCODE.MetaRight,
    NumLock: KEYBOARD_KEYCODE.NumLock,
    PageDown: KEYBOARD_KEYCODE.PageDown,
    PageUp: KEYBOARD_KEYCODE.PageUp,
    Pause: KEYBOARD_KEYCODE.Pause,
    ScrollLock: KEYBOARD_KEYCODE.ScrollLock,
    ShiftLeft: KEYBOARD_KEYCODE.ShiftLeft,
    ShiftRight: KEYBOARD_KEYCODE.ShiftRight,
    ArrowDown: KEYBOARD_KEYCODE.ArrowDown,
    ArrowLeft: KEYBOARD_KEYCODE.ArrowLeft,
    ArrowRight: KEYBOARD_KEYCODE.ArrowRight,
    ArrowUp: KEYBOARD_KEYCODE.ArrowUp,
    Escape: KEYBOARD_KEYCODE.Escape,
    PrintScreen: KEYBOARD_KEYCODE.PrintScreen,
    IntlBackslash: KEYBOARD_KEYCODE.IntlBackslash,
    IntlRo: KEYBOARD_KEYCODE.IntlRo,
    IntlYen: KEYBOARD_KEYCODE.IntlYen,
    Numpad0: KEYBOARD_KEYCODE.Numpad0,
    Numpad1: KEYBOARD_KEYCODE.Numpad1,
    Numpad2: KEYBOARD_KEYCODE.Numpad2,
    Numpad3: KEYBOARD_KEYCODE.Numpad3,
    Numpad4: KEYBOARD_KEYCODE.Numpad4,
    Numpad5: KEYBOARD_KEYCODE.Numpad5,
    Numpad6: KEYBOARD_KEYCODE.Numpad6,
    Numpad7: KEYBOARD_KEYCODE.Numpad7,
    Numpad8: KEYBOARD_KEYCODE.Numpad8,
    Numpad9: KEYBOARD_KEYCODE.Numpad9,
    NumpadAdd: KEYBOARD_KEYCODE.NumpadAdd,
    NumpadBackspace: KEYBOARD_KEYCODE.NumpadBackspace,
    NumpadClear: KEYBOARD_KEYCODE.NumpadClear,
    NumpadClearEntry: KEYBOARD_KEYCODE.NumpadClearEntry,
    NumpadComma: KEYBOARD_KEYCODE.NumpadComma,
    NumpadDecimal: KEYBOARD_KEYCODE.NumpadDecimal,
    NumpadDivide: KEYBOARD_KEYCODE.NumpadDivide,
    NumpadEnter: KEYBOARD_KEYCODE.NumpadEnter,
    NumpadEqual: KEYBOARD_KEYCODE.NumpadEqual,
    NumpadMultiply: KEYBOARD_KEYCODE.NumpadMultiply,
    NumpadParenLeft: KEYBOARD_KEYCODE.NumpadParenLeft,
    NumpadParenRight: KEYBOARD_KEYCODE.NumpadParenRight,
    NumpadSubtract: KEYBOARD_KEYCODE.NumpadSubtract,
};


export const gameStatisticsKeyMapping: any = {
    bandWidth: { name: i18n.global.t('gameError.bandWidth'), unit: "Mbps", cls: 'text-green-400' },
    bitrate: { name: i18n.global.t('gameError.bitrate'), unit: "" },
    netWorkDelay: { name: i18n.global.t('gameError.netWorkDelay'), unit: "ms" },
    udp: { name: i18n.global.t('gameError.udp'), unit: "ms" },
    serverFps: { name: i18n.global.t('gameError.serverFps'), unit: "fps" },
    fps: { name: i18n.global.t('gameError.fps'), unit: "fps" },
    packetLossRate: { name: i18n.global.t('gameError.packetLossRate'), unit: "" },
};