<template lang="pug">
div(style="text-align:center;padding: 50px 0 25px")
    h1 Well-Link Platform-as-a-Service (PaaS) (Tesseract) Privacy Policy
    p Last updated: 24, July, 2023
div(style="padding: 25px 100px")
    p At Well-Link, We are committed to protecting Your privacy and data. We understand Your concern about the use and sharing of Your Personal Data and will do Our best to keep Your Personal Data safe and secure. This Privacy Policy (“Policy”) describes Our policies regarding the collection, use, Processing, and disclosure of the content and Personal Data We receive when You use Our Service. It also explains the rights You have in relation to these Processing activities. By accessing and engaging with Our Platform, You acknowledge and agree that You have read and understood this Policy, and hereby give Your consent to Our collection, use, disclosure and Processing of Your Personal Data and other non- personally identifiable related data as outlined in this Policy.
    h2 Quick Links
    p We recommend that You read this Policy in full to ensure You are fully informed. However, if You only want to access a particular section of this Policy, You can click on the relevant link below to jump to that section.
    p
        a(@click="toPosition('t1')") 1.INTERPRETATION AND DEFINITIONS
    //- (@click="toPosition(#t1)")
    p
        a(@click="toPosition('t2')") 2.WHAT PERSONAL DATA DO WE COLLECT
    p
        a(@click="toPosition('t3')") 3.HOW WE USE COOKIES AND RELATED TECHNOLOGIES
    p
        a(@click="toPosition('t4')") 4.PURPOSES FOR USING YOUR PERSONAL DATA
    p
        a(@click="toPosition('t5')") 5.HOW WE SHARE AND DISCLOSE YOUR PERSONAL DATA
    p
        a(@click="toPosition('t6')") 6.HOW WE STORE AND TRANSFER YOUR PERSONAL DATA
    p
        a(@click="toPosition('t7')") 7.HOW WE RETENTION AND DELETE YOUR PERSONAL DATA
    p
        a(@click="toPosition('t8')") 8.HOW WE SECURE YOUR PERSONAL DATA
    p
        a(@click="toPosition('t10')") 9.CHILDREN'S PRIVACY
    p
        a(@click="toPosition('t9')") 10.DATA PROTECTION OFFICER
    p
        a(@click="toPosition('t11')") 11.LINKS TO OTHER WEBSITES
    p
        a(@click="toPosition('t12')") 12.CHANGES TO THIS PRIVACY POLICY
    p
        a(@click="toPosition('t13')") 13.CONTACT US
    
    h2#t1 Interpretation and Definitions
    h4 Interpretation
    p The capitalised words have meanings defined below. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
    h4 Definitions
    p For the purposes of this Policy:
    p 
        b Platform 
        span means Well-Link's dedicated Cloud Gaming Platform-as-a-Service (“PaaS”) designed for the use of cloud gaming developers and operators.  
    p 
        b The PDPA
        span  stands for the Personal Data Protection Act 2012, of Singapore, which regulates the collection, use, disclosure and Processing of individuals’ Personal Data by organisations.
    p 
        b The PDPC 
        span stands for the Personal Data Protection Commission in Singapore which is the regulatory agency responsible for overseeing and enforcing the PDPA.
    p 
        b The PDPR
        span  stands for "The Personal Data Protection Regulations 2021" in Singapore.
    p 
        b Applicable Data Protection Laws 
        span means applicable laws and regulations in any jurisdiction which relate to the collection, disclosure, use and/or Processing of Personal Data, including without limitation to the PDPA, including all amendments thereto and subsidiary legislation enacted thereunder, whether now or in the future.
    p 
        b Account 
        span means a unique account created for You to access Our Service or parts of Our Service.
    p 
        b API 
        span means the application programming interfaces developed, enabled by, or licensed to Provider that permits You to access certain functionality provided by the Service(s).
    p 
        b Software Development Kits
        span (also referred to as SDKs) means programming packages that allow programmers to develop mobile applications that are designed to work on or with a specific platform.
    p 
        b Affiliate 
        span means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for the election of directors or other managing authority.
    p 
        b Company
        span (referred to as either “Well-Link”, "the Company", "We", "Us" or "Our" in this Agreement) refers to WELL-LINK TECH PTE. LTD.
    p 
        b Cookies
        span are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.
    p 
        b Country
        span  refers to Singapore.
    p 
        b Device 
        span means any device that can access the Service such as a computer, a cellphone or a digital tablet.
    p 
        b Personal Data
        span means information, whether true or not, about any person who can be identified from that information, or from that information and other information that an organisation has or is likely to possess.
    p 
        b Process,
        span in relation to Personal Data, means the carrying out of any operation or set of operations in relation to the Personal Data, including without limitation to recording, holding, organising, adapting, altering, modifying, retrieving, combining, transmitting, erasing, or destroying, and Processing shall be construed accordingly.
    p 
        b Service
        span refers to the Website.
    p 
        b Usage Data 
        span refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
    p 
        b Website
        span refers to Well-Link Platform-as-a-Service (PaaS), accessible from https://www.well-linktech.com/.
    p 
        b You
        span means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.

    h2#t2 What Personal Data Do We Collect
    p During Your utilization of Our Service, We adhere strictly to all Applicable Data Protection Laws, including the PDPA and the relevant regulations including the PDPR, and comply with directions from the PDPC. We utilize Your Personal Data for lawful purposes and ensure the security of such data. Based on the above assurances, We collect the following information pertaining to You.
    
    h4 Personal Data
    p While using Our Service, We may ask You to provide Us with certain Personal Data that can be used to contact or identify You, which serves the purpose of establishing communication and validating Your identity. Such Personal Data empowers Us to verify and authenticate Your identity, effectively resolve any login complexities, and gain a deeper comprehension of Your needs. Moreover, it aids Us in customized introductions, product promotions, acquiring insights into prospective clientele, procuring timely and precise feedback on user experiences, augmenting Our PaaS services, and preserving trade secrets through the verification of Your company's bona fide particulars. To this end, We may collect the required Personal Data as follows: 
    p 1.User identity information: when the user is a company or institution: company name, country, email suffix, business registration number, business registration certificate and status.
    p 2. Account-related information: user ID/username, password, registration location, registration date, last login time, last running time and other information related to basic user data.
    
    h4 Usage Data
    p Usage Data is collected automatically when using the Service. To optimize the experience of using Our Service and to understand how You access and use Our Platform, We collect Your usage data records. We and Our partners may use this data for graphical analysis, as well as to inform You about updated or upgraded services and features, to provide customer support, to inform You about new operational events, promotions, etc. 
    p When You use the Service or connect with our technical services through our website, client, API, SDK, etc., We will collect the following information:
    p 1.Log information: We will automatically collect Your detailed usage of Our Services and save them as relevant network logs. For example, Your search query content, IP address, operating system, browser version type, telecom operator, language used, access time, device ID, visited web page records and other information identifiers and other diagnostic data, user screen resolution during operation, user screen frame rate, network IP, game, start and end time, game duration, address location (accurate down to the city), browser information, mobile device information, performance data, crash data, etc.
    p 2.User account support information: Consultation records, fault reporting records and troubleshooting processes for user failures (such as communication or call records) generated by Your use of Services, We will record and analyze this information to respond to Your help requests in a more timely manner and to improve Services.
    p 3.User account information: Consumption records based on Your use of Services, including product information, account balance, product usage time, consumption amount, offline recharge records, usage details and other data.
    p 4.Authorization information: If You use our cloud game development tools, We will receive and record the address-related information of the device You are using according to the specific permissions You have granted during software installation and use, when the client You are using establishes a connection with the server, so as to ensure the security of Your account and provide You with consistent services on different devices.
    p When We provide You with business functions or specific services, We will collect, use, store, provide and protect Your user information in accordance with this policy and the corresponding product service agreement; if We collect Your user information beyond this policy and the corresponding product service agreement, We will separately explain the scope and purpose of information collection to You, and collect Your information necessary to provide corresponding Services after obtaining Your consent.

    h2#t3 How We Use Cookies and Related Technologies
    p By using Cookies, We provide users with a secure and personalized service experience. We and third-party partners will use Cookies, log files, web beacons, programming scripts, tags and other tracking technologies (above contracted) on Your computer or mobile device. (referred to as "Cookies"). We and Our third-party partners may collect Your information through Cookies to remember Your identity, or save Your preferences or other information You previously provided to us to analyze how You use Our services.
    p The collection and use of Your information by third-party partners through Cookies is not governed by this Policy, but by their own information protection statement, and We are not responsible for third-party Cookies or similar technologies.
    p If Your browser or browser add-on services allow, You can modify the acceptance of Cookies or reject Our Cookies. However, if You do this, We may not be able to provide You with the best demo service experience, and some functions may not work properly.

    h2#t4 Purposes for Using Your Personal Data
    p The Company may collect, use, Process and/or disclose Personal Data for the purposes as described in this Policy, including the following purposes:
    p To provide and maintain Our Service, including monitoring the usage of Our Service.
    p 
        b To manage Your Account:
        span to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
    p 
        b For the performance of a contract:
        span the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
    p 
        b For research and development purposes:
        span To conduct research, analysis, and development activities (including but not limited to data analytics, surveys and/or profiling) to improve the Platform and/or Services.
    p 
        b To contact You:
        span To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.To provide You with news, special offers and general information about other goods, services and events which We offer that are similar to those that You have already purchased or enquired about unless You have opted not to receive such information.
    p 
        b To manage Your requests:
        span To attend and manage Your requests to Us.
    p 
        b For business transfers:
        span We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about Our Service users is among the assets transferred.
    p 
        b For compliance with legal and regulatory requirements:
        span To respond to requests for information from public and governmental/regulatory authorities, statutory boards, related companies and for audit, compliance, investigation and inspection purposes, whether within or outside Singapore. 
    p  To comply with any applicable laws, regulations, codes of practice, guidelines, or rules, or to assist in law enforcement and investigations conducted by any governmental and/or regulatory authority, whether within or outside Singapore.
    p 
        b For other purposes: 
        span We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of Our promotional campaigns, evaluating and improving Our Service, products, services, marketing and Your experience. To achieve any other incidental business purposes related to or in connection with the above.

    h2#t5 How We Share and Disclose Your Personal Data
    h4 Share Your Personal Data
    p We will only share Your Personal Data under the following circumstances: 
    p 
        b Obtaining Your consent:
        span Where We have obtained Your explicit authorization or consent in advance;
    p 
        b In compliance with law or as necessary to fulfill statutory obligations:
        span We may share Your Personal Data in accordance with applicable laws and regulations, litigation, dispute resolution, or as permitted or required by administrative and judicial authorities, and other legal obligations;
    p 
        b Protecting the interests or property: 
        span To the extent required or permitted by law, it is necessary to protect the interests, property, or safety of the company, its users, or the general public from damage;
    p 
        b Sharing with other parties:
        span Currently, We do not share Your Personal Data with third parties. However, in order to be associated with Our other products or services, to provide You with better services, or to protect the personal and property safety of affiliated companies, other users, or the public, if Your information needs to be shared within the company's affiliated companies or with Our partners, We will share Your Personal Data in accordance with the applicable laws.

    h4 Disclosure of Your Personal Data
    p We will only disclose Your Personal Data in the following circumstances：
    p 
        b Business transactions: 
        span If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Policy.
    p 
        b Law enforcement: 
        span Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
    p 
        b Other legal requirements: 
        span The Company may disclose Your Personal Data in a good faith belief that such action is necessary to:
    p Comply with a legal obligation
    p Protect and defend the rights or property of the Company
    p Prevent or investigate possible wrongdoing in connection with the Platform and/or Service
    p Protect the personal safety of Users of the Platform and/or Service or the public
    p Protect the Company against legal liability
    h2#t6 How We Store and Transfer Your Personal Data
    p Your information, including Personal Data, is Processed at the Company's operating offices and locations in Singapore.In principle, the Personal Data We collect and generate in Singapore will be stored in Singapore, although We may also transfer Your Personal Data out of Singapore in accordance with the requirements prescribed under the PDPA. After obtaining Your authorization and consent and after We perform Our obligations under the law, Your Personal Data may be transferred to, or be accessed from, the overseas jurisdictions of the country/region where You use the product or service.We will ensure that We provide a standard of protection to the Personal Data so transferred that is comparable to the protection under the PDPA.
    p Your consent to this Policy followed by Your provision of Your information represents Your agreement to such transfer.
    p The Company will comply with conditions under Applicable Data Protection Laws (including the PDPA and all regulations thereunder including the PDPR) to take appropriate steps to ensure that the overseas recipient is bound by legally enforceable obligations or specified certifications. We will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other Personal Data. 
    
    h2#t7 How We Retain and Delete Your Personal Data
    h4 Retention of Your Personal Data
    p The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Policy, and will cease to retain Your Personal Data when retention is no longer necessary for legal or business purposes. We will retain and use Your Personal Data to the extent necessary to comply with Our legal obligations (for example, if We are required to retain Your data to comply with applicable laws), resolve disputes, and enforce Our legal agreements and policies.
    p The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
    p You understand and agree that the Company has the right to conduct technical analysis on the entire user database on the premise of not disclosing the Personal Data. Only the Personal Data that has been analyzed and sorted to achieve anonymization can be used commercially.

    h4 Update, amend or Delete Your Personal Data
    p You have the right to update or amend, or request that We assist in updating or revising, the Personal Data that We have collected about You.
    p Our Service may give You the ability to update, amend, or delete certain information about You from within the Service.
    p You may update, amend, or delete Your information at any time by signing in to Your Account, if You have one, and visiting the account settings section that allows You to manage Your Personal Data. You may also contact Us to request access to or correction of any Personal Data that You have provided to Us. 
    p You also have the right to withdraw Your consent for us to collect, use, Process and/or disclose Your Personal Data. While We respect Your decision to withdraw Your consent, please note that depending on the nature and scope of Your request, We may not be in a position to continue the provision of Services to You or allow You to continue using Our Services if You withdraw Your consent.
    p Please note, however, that We may retain Your information when We have a legal obligation or lawful basis to do so.


    h2#t8 How We Secure Your Personal Data
    p The security of Your Personal Data is important to Us, We will take all reasonably practicable measures to protect Your Personal Data and prevent data from unauthorized access, public disclosure, use, modification, damage or loss.However please remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.

    h2#t9 Data Protection Officer
    p According to the relevant provisions of the PDPA, We have appointed a Singaporean Data Protection Officer. If You believe Your Personal Data has been used in a way that is not consistent with the Policy or Your choices, or if You have further questions, comments or suggestions related to this Policy, please contact the Singaporean Data Protection Officer by filling out an inquiry form.Written inquiries to the Singaporean Data Protection Officer may be addressed to: dpo@well-linktech.com
   
    h2#t10 Children's Privacy
    p Our Service is not directed at children under the age of 18. We do not knowingly collect personally identifiable information from anyone under the age of 18. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 18 without verification of parental consent, We may take steps to remove that information from Our servers.
    p If We need to rely on consent as a legal basis for Processing Your information and Your country requires consent from Your parent or legal guardian, We may require Your parent's or legal guardian’s consent before We collect and use that information.

    h2#t11 Links to Other Websites
    p This Policy is applicable only to Our Platform and does not apply to products or services offered by third parties through Our Website.
    p Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Policy of every site You visit
    p We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.

    h2 Third Party Personal Data
    p By uploading any Personal Data belonging to any third parties (“Third Party Personal Data”) to the Platform or otherwise providing any Third Party Personal Data to Us, You undertake and warrant that:
    p (a)You have the authority to provide such Third Party Personal Data to Us;
    p (b)the Third Party Personal Data provided is accurate, complete and true;
    p (c)You have informed the individual about the purposes for which such Third Party Personal Data is collected, used, disclosed and/or Processed (including by Us); and 
    p (d)the individual has consented to the collection, use and disclosure of such Third Party Personal Data for such purposes. 
    h2#t12 Changes to this Policy
    p We may update Our Policy from time to time, and such revisions shall constitute a part of this Policy. We will not substantially reduce Your rights under this Policy without Your express consent.
    p We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Policy.
    p You are advised to review this Policy periodically for any changes. Changes to this Policy are effective when they are posted on this page.
    h2#t13 Contact Us
    p If You have any questions, comments or suggestions about this Policy or would like to discuss this Policy or any privacy matters with Us,please contact:
    p By email: contact@well-linktech.com
    p By visiting this page on Our website: www.well-linktech.com
</template>

<script lang="ts" setup>
const toPosition = (ID: any) => {
    const ele = document.getElementById(ID);
    console.log(ele, "ele");
    const clientHeight: any = ele!.getBoundingClientRect().top;
    const scrollTop: any = document.documentElement.scrollTop;
    // const eleOffsetTop = ele?.offsetTop;
    // console.log(eleOffsetTop,"eleOffsetTop")
    const eleHeight = scrollTop + clientHeight! - 60;
    // const eleHeight = window.innerHeight + eleOffsetTop!
    console.log(eleHeight, "eleHeight");
    window.scrollTo(0, eleHeight);
};
</script>
<style scoped lang="sass">
h1
    font-size: 22px
    font-weight: 900
h2
    font-size: 20px
    font-weight: 700
    height: 50px
    line-height: 50px
h4
    font-size: 18px
    font-weight: 700
    height: 40px
    line-height: 40px
p
    font-size: 16px
    font-weight: 500
</style>
