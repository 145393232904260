<template lang="pug">
span
    svg-icon(name="close" @click="backVisible = true").close
    a-modal(
        v-model:open="backVisible" :width="432" wrapClassName="popup-delete-project" centered
    )
        div.text-lg.txt-color.text-center.mb-3.font-medium {{ $t('v2.mainProcess.quite-title') }}
        p.p-0.mb-4(class="text-[#86909C] text-sm text-center px-10")
            | {{ $t('v2.mainProcess.quite-cont') }}
        template(#footer)
            .pb-2
                a-button(type="primary" @click="backVisible = false") {{ $t('v2.action.cancel') }}
                a-button( danger :loading="backLoading" @click="onClose") {{ $t('v2.action.confirm') }}
</template>

<script lang="ts" setup>
import API from '@/common/api/console/v2/home'
import { useI18n } from 'vue-i18n';

const { t } = useI18n()
const backVisible = ref(false)
const backLoading = ref(false)

const router = useRouter()
const onClose = () => {
    backLoading.value = true
    API.setStepTerminated().then(data => {
        router.push({ name:'home' })
        backLoading.value = false
    }).catch(() => {
        backLoading.value = false
    })
}
</script>
