<template lang="pug">
.page-content(class="relative flex-1 overflow-hidden m-5 mt-0 rounded-lg")
    h1.page-title(v-if="route?.meta?.showTitle").flex.items-center
        //- a-tooltip(placement="top" color="#fff")
        //-     template(#title)
        //-         span {{ $t('common.back') }}
        //-     span(@click="goBack(route.meta.backName)").mr-2.cursor-pointer
        //-         svg-icon(
        //-             v-if="route.meta.showBack", name="back", :size="32" class="hover:text-blue-700" style="margin-top: -4px;"
        //-         )
        | {{ route.meta.title }}
    slot
</template>

<script lang="ts" setup>
const route = useRoute()
const router = useRouter()

const goBack = (name: unknown) => {
    router.push({ name: name as string })
}
</script>