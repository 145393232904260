export const UPLOAD_TOOLS_URL = {
    mac: 'https://download.well-linktech.com/download/Well-LinkGameUploadTool-v1.0.0.dmg',
    win: 'https://download.well-linktech.com/download/Well-LinkGameUploadTool-v1.0.0.exe'
}

export const SDK_URL = {
    ios: {
        en: 'https://download.well-linktech.com/download/Well-Link Cloud Gaming iOS SDK-3.30-EN.zip',
        ja: 'https://download.well-linktech.com/download/Well-Link Cloud Gaming iOS SDK-3.30-JP.zip'
    },
    android: {
        en: 'https://download.well-linktech.com/download/Well-Link Cloud Gaming Android SDK-3.30-EN.zip',
        ja: 'https://download.well-linktech.com/download/Well-Link Cloud Gaming Android SDK-3.30-JP.zip'
    },
    jssdk: {
        en: 'https://download.well-linktech.com/download/Well-Link Cloud Gaming JSSDK-4.4.20-EN.zip',
        ja: 'https://download.well-linktech.com/download/Well-Link Cloud Gaming JSSDK-4.4.20-JP.zip'
    },
    pc: {
        en: 'https://download.well-linktech.com/download/Well-Link Cloud Gaming PCSDK-3.30.01-EN.zip',
        ja: 'https://download.well-linktech.com/download/Well-Link Cloud Gaming PCSDK-3.30.01-JP.zip'
    }
}