<template lang="pug">
div.w-full.desc.mt-3 请输入邮箱 {{email}} 收到的 6 位验证码，有效期为 15 分钟。如验证码失效，请尝试重新获取验证码
a-form
    div.mt-8.codeNum
        a-input(ref="ref0" v-model:value.trim="code0" maxlength="1" @input="code0=code0.replace(/[^0-9a-zA-Z]/g,'')"
    @change="nextFocus($event,code0,ref1)" )
        a-input(ref="ref1" v-model:value.trim="code1" maxlength="1" @input="code1=code1.replace(/[^0-9a-zA-Z]/g,'')" @change="nextFocus($event,code1,ref2)"
    @keydown="keyDown($event,ref0)") 
        a-input(ref="ref2" v-model:value.trim="code2" maxlength="1" @input="code2=code2.replace(/[^0-9a-zA-Z]/g,'')" @change="nextFocus($event,code2,ref3)"
    @keydown="keyDown($event,ref1)") 
        a-input(ref="ref3" v-model:value.trim="code3" maxlength="1" @input="code3=code3.replace(/[^0-9a-zA-Z]/g,'')" @change="nextFocus($event,code3,ref4)"
    @keydown="keyDown($event,ref2)") 
        a-input(ref="ref4" v-model:value.trim="code4" maxlength="1" @input="code4=code4.replace(/[^0-9a-zA-Z]/g,'')" @change="nextFocus($event,code4,ref5)"
    @keydown="keyDown($event,ref3)")
        a-input(ref="ref5" v-model:value.trim="code5" maxlength="1" @input="code5=code5.replace(/[^0-9a-zA-Z]/g,'')" @change="nextFocus($event,code5,ref5)"
    @keydown="keyDown($event,ref4)")
        //- a-input(v-model:value.trim="code" type="text" :maxlength="6").testCode
div.mt-6.time
  span(v-if="verifyCodeTxt").desc  {{ verifyCodeTxt }}
  span(v-if="verifyCodeTxt").getcodebtn.cursor-not-allowed.disabled.ml-2 重新获取
  span(v-if="!verifyCodeTxt" @click="getCode").getcodebtn.cursor-pointer.ml-2.text-blue-600 重新获取
//- a-button(type="primary") 确认
</template>
<script setup lang="ts">
import { sendSmsCode } from "@/service/userCenter/userCenter"
import { useI18n } from 'vue-i18n'
import API from "@/common/api"
import { message } from "@welink/design-v2";
const props = withDefaults(defineProps<{
    email?: any,
    type?:any
}>(), {
    email: "",
    type:0
})
const { t } = useI18n()
const verifyCodeTxt = ref<any>("")
const showTime = ref(false)
const code0 = ref<any>()
const ref0 = ref()
const ref1 = ref()
const ref2 = ref()
const ref3 = ref()
const ref4 = ref()
const ref5 = ref()
const code1 = ref<any>()
const code2 = ref<any>()
const code3 = ref<any>()
const code4 = ref<any>()
const code5 = ref<any>()
// eslint-disable-next-line no-import-assign, vue/no-dupe-keys
const code = computed(() => code0.value + code1.value + code2.value + code3.value + code4.value + code5.value)
const getCode = async () => {
    const res: any = await API.userCenter.getEmailCode({ userEmail: props.email, verifyCodeType: props.type })
    console.log(res)
    if (res.data.code) {
        let time = 60
        showTime.value = true
        const times = setInterval(() => {
            time--
            verifyCodeTxt.value = time + "s后可点击"
            if (time === 1) {
                clearInterval(times)
                verifyCodeTxt.value = null
                showTime.value = false
            }
        }, 1000)
    }else{
        message.error(res.data.msg)
    }
}
const keyDown = (e: any, ref: any) => {
    if (e.keyCode === 8 && !e.target.value) {
        ref.focus()
    }
}
const nextFocus = (e: any, value: any, ref: any) => {
    if (value.replace(/[^0-9a-zA-Z]/g, '').length === 1) {
        ref.focus()
    }
}
onMounted(async () => {
    const res: any = await API.userCenter.getEmailCode({ userEmail: props.email, verifyCodeType: props.type })
    console.log(res.data)
    if (res.data.code === 0) {
        let time = 60
        showTime.value = true
        const times = setInterval(() => {
            time--
            verifyCodeTxt.value = time + "s后可点击"
            if (time === 1) {
                clearInterval(times)
                verifyCodeTxt.value = null
                showTime.value = false
            }
        }, 1000)
    }else{
        message.error(res.data.msg)
    }
})
defineExpose({ code })
</script>

<style lang="sass">
.desc
  font-size: 12px
  font-weight: 500
  color: #6B7280
  text-align: center
.testCode
    position: absolute !important
    width: 100% !important
    letter-spacing: 1.3rem !important 
    padding-left: 0
    color: #fff !important
    opacity: 0 !important
    left: 0 !important
    height: 100% !important
</style>
<style lang="sass" scoped>
:deep .btns
    display: none !important
.codeNum 
  margin-top: 24px  !important
  width: 100%
  margin: 0 auto
  position: relative
  text-align: left
  display: flex
  .ant-input 
    
    flex-shrink: 1
    margin-right: 17px
    display: inline-block
    width: 36px
    height: 36px
    background: #FFFFFF
    border-radius: 8px
    border: 1px solid #D1D5DB
    text-align: center
    line-height: 36px
    &:first-child
      margin-left: 0
.time
  margin-top: 24px 
  margin-bottom: 32px !important
  text-align: center
  .desc 
    font-size: 12px
    font-family: PingFangSC-Regular, PingFang SC
    font-weight: 400
    color: #6B7280
    line-height: 17px
  .getcodebtn
    font-size: 12px
  .disabled
    font-size: 12px
    font-family: PingFangSC-Regular, PingFang SC
    font-weight: 400
    color: #E5E7EB
    line-height: 17px
    // :deep .ant-input
    //     position: absolute !important
    //     width: 100% !important
    //     letter-spacing: 1.3rem !important 
    //     padding-left: 0
    //     color: #fff !important
    //     opacity: 0 !important
    //     left: 0 !important
    //     height: 100% !important

</style>
