<template lang="pug">
div.flex.flex-col.h-full.bg-white
    div(class="top-banner rounded-lg overflow-hidden shrink-0 mt-10 ml-15 mb-20")
        div(class="text-[32px] leading-10 text-[#424A61]") {{ $t('v2.landingSDK.title') }}
        div(class="text-base text-[#AAADB3]") {{ $t('v2.landingSDK.desc') }}
    .px-15
        .mb-20
            h2(class="text-xl leading-10 txt-color") {{ $t('v2.landingSDK.part1') }}
            a-tabs(v-model:activeKey="activeKey")
                a-tab-pane(key="1" :tab="$t('v2.landingSDK.list-01.title')")
                    .pt-4
                        .font-bold.mb-4.text-lg
                            | {{ $t('v2.landingSDK.list-01.desc') }}
                            a-tag(:bordered="false" color="processing" class="!text-xs !ml-2") {{ $t('v2.landingSDK.list-01.version') }}
                        .flex.text-sm.text-gray-500
                            span {{ $t('v2.landingSDK.updateTime') }}：{{ $t('v2.landingSDK.list-01.time') }}
                            span.ml-4 {{ $t('v2.landingSDK.size') }}：{{ $t('v2.landingSDK.list-01.size') }}
                        .py-4 {{ $t('v2.landingSDK.list-01.intro') }}
                        a-button(type="primary" @click="open('ios')") {{ $t('v2.action.download') }}
                a-tab-pane(key="2" :tab="$t('v2.landingSDK.list-02.title')")
                    .pt-4
                        .font-bold.mb-4.text-lg
                            | {{ $t('v2.landingSDK.list-02.desc') }}
                            a-tag(:bordered="false" color="processing" class="!text-xs !ml-2") {{ $t('v2.landingSDK.list-02.version') }}
                        .flex.text-sm.text-gray-500
                            span {{ $t('v2.landingSDK.updateTime') }}：{{ $t('v2.landingSDK.list-02.time') }}
                            span.ml-4 {{ $t('v2.landingSDK.size') }}：{{ $t('v2.landingSDK.list-02.size') }}
                        .py-4 {{ $t('v2.landingSDK.list-02.intro') }}
                        a-button(type="primary" @click="open('android')") {{ $t('v2.action.download') }}
                a-tab-pane(key="3" :tab="$t('v2.landingSDK.list-03.title')")
                    .pt-4
                        .font-bold.mb-4.text-lg
                            | {{ $t('v2.landingSDK.list-03.desc') }}
                            a-tag(:bordered="false" color="processing" class="!text-xs !ml-2") {{ $t('v2.landingSDK.list-03.version') }}
                        .flex.text-sm.text-gray-500
                            span {{ $t('v2.landingSDK.updateTime') }}：{{ $t('v2.landingSDK.list-03.time') }}
                            span.ml-4 {{ $t('v2.landingSDK.size') }}：{{ $t('v2.landingSDK.list-03.size') }}
                        .py-4 {{ $t('v2.landingSDK.list-03.intro') }}
                        a-button(type="primary" @click="open('web')") {{ $t('v2.action.download') }}
                a-tab-pane(key="4" :tab="$t('v2.landingSDK.list-04.title')")
                    .pt-4
                        .font-bold.mb-4.text-lg
                            | {{ $t('v2.landingSDK.list-04.desc') }}
                            a-tag(:bordered="false" color="processing" class="!text-xs !ml-2") {{ $t('v2.landingSDK.list-04.version') }}
                        .flex.text-sm.text-gray-500
                            span {{ $t('v2.landingSDK.updateTime') }}：{{ $t('v2.landingSDK.list-04.time') }}
                            span.ml-4 {{ $t('v2.landingSDK.size') }}：{{ $t('v2.landingSDK.list-04.size') }}
                        .py-4 {{ $t('v2.landingSDK.list-04.intro') }}
                        a-button(type="primary" @click="open('pc')") {{ $t('v2.action.download') }}
        h2(class="text-[20px] leading-10 text-[#424A61]") {{ $t('v2.landingSDK.part2') }}
        .pb-20
            a-tabs(v-model:activeKey="activeParamsKey")
                a-tab-pane(key="1" :tab="$t('v2.landingSDK.params.tenant')")
                    .txt-color.text-base.mb-4.flex.items-center(v-if="projectInfo.tenantKey")
                        .txt-second-color.mb-1.text-sm.w-30 {{ $t('v2.landingSDK.key') }}
                        .show.items-center(v-if="!keyShow")
                            | ************
                            svg-icon(name="eye" :size="16" @click="keyShow = true").ml-8
                        .items-center(v-if="keyShow" )
                            | {{ projectInfo.tenantKey }}
                            svg-icon(name="eye-close" :size="16" @click="keyShow = false").ml-8
                        svg-icon(name="copy" :size="16" @click="copyText(projectInfo.tenantKey)").ml-2.cursor-pointer
                    .txt-color.text-base.mb-4.flex.items-center(v-if="projectInfo.tenantSecret")
                        .txt-second-color.text-sm.w-30 {{ $t('v2.landingSDK.secret') }}
                        .show.flex.items-center(v-if="!secretShow")
                            | ************
                            svg-icon(name="eye" :size="16" @click="secretShow = true").ml-8
                        .real.flex.items-center(v-if="secretShow")
                            span.truncate(class="w-115") {{ projectInfo.tenantSecret }}
                            svg-icon(name="eye-close" :size="16" @click="secretShow = false").ml-8
                        svg-icon(name="copy" :size="16" @click="copyText(projectInfo.tenantSecret)").ml-2.cursor-pointer
                    .txt-color.text-base.mb-4.flex.items-center(v-if="projectInfo.clientAccessSecret")
                        .txt-second-color.text-sm.w-30 {{ $t('v2.landingSDK.clientAccessSecret') }}
                        .show.flex.items-center(v-if="!clientAccessSecretShow")
                            | ************
                            svg-icon(name="eye" :size="16" @click="clientAccessSecretShow = true").ml-8
                        .real.flex.items-center(v-if="clientAccessSecretShow")
                            span.truncate(class="w-115") {{ projectInfo.clientAccessSecret }}
                            svg-icon(name="eye-close" :size="16" @click="clientAccessSecretShow = false").ml-8
                        svg-icon(name="copy" :size="16" @click="copyText(projectInfo.clientAccessSecret)").ml-2.cursor-pointer

                a-tab-pane(key="2" :tab="$t('v2.landingSDK.params.node')")
                    a-table(:columns="nodeColumn" :data-source="nodeList1" :pagination="false").rounded-lg
                        template(#emptyText)
                            .py-10.txt-third-color {{ $t('v2.action.emptyData') }}
                a-tab-pane(key="3" :tab="$t('v2.landingSDK.params.game')")
                    a-table(:columns="gameColumn" :data-source="gameList1" :pagination="false").rounded-lg
                        template(#emptyText)
                            .py-10.txt-third-color {{ $t('v2.action.emptyData') }}
</template>

<script lang="ts" setup>
import API from '@/common/api/console/v2/home'
import { copyText } from '@/utils/copyText'
import { useI18n } from 'vue-i18n'
import { SDK_URL } from '@/common/constants/url'
import { getLocal } from "@/common/constants/env";

const activeKey = ref('1')
const activeParamsKey = ref('1')
const keyShow = ref(false)
const secretShow = ref(false)
const clientAccessSecretShow = ref(false)
const { t } = useI18n()
const urlSign = getLocal() === 'ja' ? 'ja' : 'en'

const nodeList1 = ref([])
const gameList1 = ref([])

const nodeColumn = [
    { title: t('v2.landingSDK.params.nodeTable.label'), dataIndex: 'label', key: 'label' },
    { title: t('v2.landingSDK.params.nodeTable.value'), dataIndex: 'value', key: 'value' },
]
const gameColumn = [
    { title: t('v2.landingSDK.params.gameTable.label'), dataIndex: 'label', key: 'label' },
    { title: t('v2.landingSDK.params.gameTable.value'), dataIndex: 'value', key: 'value' },
]

const open = (type:string) => {
    switch (type) {
    case 'ios':
        window.open(SDK_URL.ios[urlSign]);
        break;
    case 'android':
        window.open(SDK_URL.android[urlSign]);
        break;
    case 'web':
        window.open(SDK_URL.jssdk[urlSign]);
        break;
    case 'pc':
        window.open(SDK_URL.pc[urlSign]);
        break;
    default:
        break;
    }
}

const projectInfo = reactive({
    tenantKey: '',
    tenantSecret: '',
    clientAccessSecret: ''
})

onMounted(() => {
    API.landingSDK().then((data: any) => {
        const { gameList, nodeList, clientAccessSecret, tenantKey, tenantSecret } = data;
        gameList1.value = gameList.filter((item: any) => item.gameId && item.gameName ).map((item: any) => ({ label: item.gameId, value: item.gameName }))
        nodeList1.value = nodeList.map((item: any) => ({ label: item.nodeId, value: item.nodeName }))
        projectInfo.tenantKey = tenantKey
        projectInfo.tenantSecret = tenantSecret
        projectInfo.clientAccessSecret = clientAccessSecret
    })
})
// ios
// android
// web  chrome
// pc  w11
</script>