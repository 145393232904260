<template lang="pug">
div(class="top-banner rounded-lg overflow-hidden mb-4 shrink-0")
    div(class="absolute top-[68px] left-[68px]")
        div(class="text-[32px] leading-10 text-[#424A61]") {{ $t('v2.console.home.top.title') }}
        div(class="text-base text-[#AAADB3]") {{ $t('v2.console.home.top.desc') }}
        a-button.mt-10(type="primary" @click="startClick") {{ $t('v2.console.home.top.btn') }}
</template>

<script lang="ts" setup>
import { useHomeStore } from '@/store/home'
const store = useHomeStore()
const stepInfo = computed(() => store.stepInfo)
const router = useRouter()

const startClick = () => {
    const projectId = stepInfo.value.lastProjectId
    const packageId = stepInfo.value.lastPackageId
    const resoureId = stepInfo.value.lastResoureId
    console.log('stepInfo.value.step:', stepInfo.value.step)
    switch (stepInfo.value.step) {
    case 1:
        router.push({ name: 'mainProcessStep01' })
        break;
    case 2:
        router.push({ name: 'mainProcessStep02', params: { projectId, packageId } })
        break;
    case 3:
        router.push({ name: 'mainProcessStep03', params: { projectId, packageId } })
        break;
    case 4:
        router.push({ name: 'mainProcessStep04', params: { projectId, packageId } })
        break;
    default:
        break;
    }
}
</script>

<style lang="sass" scoped>
.top-banner
    height: 270px
    background: #fff url(@/assets/images/home/head-banner.png) center right no-repeat
    transition: 0.15s ease-in-out
    background-size: auto 270px
    &:hover
        background-size: auto 300px
</style>