<template lang="pug">
WConfigProvider(theme-type="tesseract", :locale="getLocale()")
    page-header
    section(:lang="getLocale() || 'en'").flex.pt-16.page-wrapper
        router-view
</template>

<script setup lang="ts">
import PageHeader from '@/views/components/page-header.vue'
import { SSO_TOKEN } from '@/utils/sso'
import { getQueryString, objToQueryString } from '@/utils/getQueryString'
import { getLocale } from '@/utils/sso'

onBeforeMount(() => {
    const queryParams: any = getQueryString()
    if (queryParams[SSO_TOKEN.toLocaleLowerCase()]) {
        delete queryParams[SSO_TOKEN.toLocaleLowerCase()]
        const queryString = objToQueryString(queryParams)
        const url = new URL(window.location.href)
        url.search = '?' + queryString
        const redirectUrl = window.localStorage.getItem('WL_REDIRECT_URL')
        if (redirectUrl) {
            window.localStorage.setItem('WL_REDIRECT_URL', '')
            window.location.href = decodeURIComponent(redirectUrl)
        } else {
            window.location.href = url.toString()
        }
    }
})

onMounted(() => {
    window.addEventListener('scroll', () => {
        const scrollTop = window.scrollY
        if (scrollTop >= 88) {
            document.querySelector('.page-header')?.classList.add('active-bg')
        } else {
            document.querySelector('.page-header')?.classList.remove('active-bg')
        }
    })
})

</script>

<style lang="sass">

html,
body
    width: 100%
    height: 100%
    margin: 0
    padding: 0
#app
    min-height: 100%
body
    background-color: #FFFFFF
    font-family: sans-serif, Helvetica Neue, PingFang SC, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Arial, sans-serif
html[lang='en'] body
    font-family: 'Roboto', sans-serif
html[lang="ja"] body
    font-family: 'BIZ UDPGothic', sans-serif
.page-login
    @apply bg-gray-100
    .user,.login
        display: none
.ant-cascader-dropdown
    .ant-cascader-menu
        border-radius: 4px !important
        width: 280px
        height: auto !important
        font-size: 12px
        color:#111827 !important
    .ant-cascader-menu-item
        padding: 8px 5px
        padding-left: 10px
    .ant-cascader-menu-item:hover
        background-color: #bfdbfe !important
        opacity: 0.8
.w-select
    .icon
        top: 12px !important
.placeholder
    color: #aaa !important
.w-picker
    input.dp__pointer
        &::placeholder
            --tw-text-opacity:1 !important
            color: rgba(107 114 128 / var(--tw-text-opacity)) !important
            font-size: 14px !important
            cursor: pointer
            padding: 4px 34px
            padding-left:0
            height: 40px
            font-size: 14px
            border-color: rgb(209, 213, 219)
            font-family: "Microsoft YaHei", Arial, sans-serif
.wl-modal_header
    height: 64px
#app
    background: url('@/assets/images/common/screen-bg.png') center center no-repeat
    background-size: cover
.ant-tooltip .ant-tooltip-inner
    min-height: auto !important
.ant-spin-text
    padding-top: 8px
</style>
