import Request, { Language_MAP } from '@/common/request'
const API = {
    // 获取环境信息
    getEnvInfo: '/portal/environment/get/',
    landingSDK: '/portal/developer/sdk/apply/tenant-param',

    // 弹窗状态
    guidePopup: '/portal/user-guide-step/getGuidePopup',
    setGuidePopup: '/portal/user-guide-step/setGuidePopup',

    /** 步骤存取 */
    setMainStep: '/portal/user-guide-step/setGuideStep',
    getMainStep: '/portal/user-guide-step/load/guideStep',

    // 项目相关
    getProjectSelectList: '/portal/application/selectlist',
    getProjects: '/portal/application/page',
    getProjectList: '/portal/application/list',
    editProject: '/portal/application/update',
    deleteProject: '/portal/application/remove/',
    createProject: '/portal/application/save',
    getProjectInfo: '/portal/application/get/',
    getProjectGames: '/portal/application/game/page',

    // 游戏相关
    createGame: '/portal/game-package/save',
    getGames: '/portal/game-package/page',
    getGameList: '/portal/game-package/list',
    getGameInfo: '/portal/game-package/get/',
    deleteGame: '/portal/game-package/remove/',
    editGame: '/portal/game-package/update',

    // 更新游戏关系
    updateGameRelationship: '/portal/game-application-relationship/save',

    /** 购买商品 */
    resourceNodeList: '/portal/purchase/node/list',
    skuAlreadyAdapterList: '/portal/purchase/node/sku/config',
    getSkuPrice: '/portal/purchase/node/sku/price',
    checkResourceStatus: '/portal/game-package/load/status/',
    getNodeQuota:'portal/purchase/node/resource/quota',


    orderPurchase: '/portal/purchase-resource-order/purchase',
    topList: '/user/product/saleType/topList',
    orderSave: '/user/product/order/save',
    listByType: '/user/product/spu/listByType',
    buyPageInfo: '/user/product/spu/buyPageInfo',
    appAndArea: '/user/product/sku/appAndArea',
    // priceByArea: '/user/product/sku/priceByArea',
    priceByArea: '/portal/config/priceByArea',
    // checkResourceStatus: '/user/product/order/save',
    checkDeployStatus: '/user/product/order/save',

    // 资源列表
    resourceList: '/portal/purchase-resource-order-detail/page',
    setResourceQuantity: '/portal/purchase-resource-order-detail/increment/servers',
    releaseResource: '/portal/purchase-resource-order-detail/release/',


    // 保存表格
    saveAdapterInfo: '/portal/adaptation-requirement-form/submitAdapter',
    checkGameUploaded: '/portal/game-package/checkUpload/',
    getAdapterFormInfo: '/portal/adaptation-requirement-form/get/requirement/',
    modifyAdapterFormInfo: '/portal/adaptation-requirement-form/modify',

    // 适配信息
    adapterList: '/portal/application-adapter/page',
    dispatch: "/portal/landing-page/dispatch/apply",

    // 账单
    billPage: '/portal/bill-record/page',
    billDownload: '/portal/bill-record/review/'
}



export default {

    resetGuider: () => {
        localStorage.setItem('WL_SIDEBAR_TUTORIAL_POPOVER', '');
        return Request.post(API.setGuidePopup, { guidePopupStatus: 1, bubblesPopupStatus: 1,  tutorialGuideStyle: 0 })
    },
    getEnvInfo: (id: string) => Request.get(API.getEnvInfo + id, {}),
    landingSDK: () => Request.post(API.landingSDK, {}),

    /** type 1 引导， 0关闭 */
    getGuidePopup: () => Request.get(API.guidePopup, {}),
    setGuidePopup: (type: number) => Request.post(API.setGuidePopup, { guidePopupStatus: type }),
    setTutorialGuide: (type: number) => Request.post(API.setGuidePopup, { tutorialGuideStyle: type }),
    setBubblesPopupStatus: (type: number) => Request.post(API.setGuidePopup, { bubblesPopupStatus: type }),
    setMainStep: (step: number, params = {}) => Request.post(API.setMainStep, { step, status: 1, ...params  }),
    setStepTerminated: () => Request.post(API.setMainStep, { isTerminated: 1, step: 1, status: 1  }),
    getMainStep: () => Request.get(API.getMainStep, { }),

    getRecentProjects: () => Request.post(API.getProjects, { current: 1, size: 12 }),
    getProjectList: (params: any = {}) => Request.post(API.getProjectList, { current: 1, size: 20, ...params }),
    getProjects: (params: any = {}) => Request.post(API.getProjects, { current: 1, size: 20, ...params }),
    getProjectSelectList: (params: any = {}) => Request.get(API.getProjectSelectList, { ...params }),
    createProject: (applicationName: string ) => Request.post(API.createProject, { type: 1, applicationName }),
    deleteProject: (id: string) => Request.delete(API.deleteProject + id, {}),
    getProjectInfo: (id: string) => Request.get(API.getProjectInfo + id, {}),
    updateProjectName: (id: string, applicationName: string ) => Request.post(API.editProject, { id, applicationName }),

    getGameList: (params = {}) => Request.post(API.getGameList,  { ...params }),
    getGames: (params = {}) => Request.post(API.getGames,  { current: 1, size: 20, ...params }),
    getProjectGames: (params: any = {}) => Request.post(API.getProjectGames, { current: 1, size: 20, ...params }),
    getGameInfo: (id: string) => Request.get(API.getGameInfo + id , {}),
    deleteGame: (id: string) => Request.delete(API.deleteGame + id, {}),
    updateGameName: (id: string, packageName: string ) => Request.post(API.editGame, { id, packageName }),
    createGame: (packageName: string, applicationId = '') => Request.post(API.createGame, { packageName, applicationId }),   // 创建
    updateGameRelationship: (packageId: string, applicationId = '') => Request.post(API.updateGameRelationship, { gameId: packageId, applicationId }),   // 创建

    // 购买商品相关
    getSkuPrice: (params: any) => Request.post(API.getSkuPrice, { ...params }),
    getSkuAlreadyAdapterList: (params: any) => Request.post(API.skuAlreadyAdapterList, { ...params }, { canCustomHandle: true }),
    buyPageInfo: (params: any) => Request.post(API.buyPageInfo, { ...params }),
    orderPurchase: (params: any) => Request.post(API.orderPurchase, { ...params }),
    priceByArea: (params: any) => Request.post(API.priceByArea, { ...params }),


    // 资源列表
    getResourceList: (params = {}) => Request.post(API.resourceList,  { current: 1, size: 20, ...params }),
    getResourceNodeConfig: () => Request.post(API.resourceNodeList, { }),
    setResourceQuantity: (params = {}) => Request.post(API.setResourceQuantity,  { ...params }),
    releaseResource: (id: string) => Request.delete(API.releaseResource + id, {}),
    checkResourceStatus: (id: string) => Request.get(API.checkResourceStatus + id, {  }),
    getNodeQuota: (params: any) => Request.post(API.getNodeQuota, { ...params }),

    topList: (params: any) => Request.post(API.topList, { ...params }),
    listByType: (params: any) => Request.post(API.listByType, { ...params }),
    appAndArea: (params: any) => Request.post(API.appAndArea, { ...params }),
    orderSave: (params: any) => Request.post(API.orderSave, { ...params }, { canCustomHandle: true }),
    checkDeployStatus: (params: any) => Request.post(API.checkDeployStatus, { ...params }),

    // 问卷保存
    checkGameUploaded: (id: string) => Request.get(API.checkGameUploaded + id , {}),
    getAdapterFormInfo: (id:string) => Request.get(API.getAdapterFormInfo + id , {}),
    modifyAdapterFormInfo: (params: any) => Request.post(API.modifyAdapterFormInfo, params),
    saveAdapterInfo: (params: any) => Request.post(API.saveAdapterInfo, { ...params }),

    // 适配信息
    getAdapterList: (params = {}) => Request.post(API.adapterList,  { current: 1, size: 20, ...params }),
    adapterDispatch: <R>(params: any, token?: string, lang?: string) => {
        if (token) {
            return Request.post<any, R>(API.dispatch, params, { headers: {
                Authorization: token,
                "Accept-Language": Language_MAP[lang || 'en']
            } })
        } else {
            return Request.post<any, R>(API.dispatch, params)
        }
    },

    // 账单
    getBillPage: (params = {}) => Request.post(API.billPage,  { current: 1, size: 20, ...params }),
    billDownload: (id: string) => Request.get(API.billDownload + id,  { }),
}