<template lang="pug">
div.home
    //- div.search-box.p-5.flex.justify-between
    //- .search-item.flex
    //-     .search-input
    //-         w-input(v-model.trim="searchParams.applicationName" placeholder="输入要查询的名称" clearable)
    //-         i.search-icon
    //-     w-button(type="default" @click="searchBox") 搜索
    //- w-button(type="default" @click="searchBox()")
    .table-list.mx-4
        w-table(
            :dataSource="IncomeRecord"
            :loading="loading" :columns="columns"
            :pagination="pageinationParams"
            :scroll="{ x: 800 }"
            @pagination-num-change="changeParams"
            @pagination-size-change="sizeChange"
        )
            template(#extra)
                .flex.py-5.gap-4.justify-start.w-picker
                    w-select.w-60(
                        v-model="searchForm.billStatusSet" multiple, :placeholder="$t('cost.billList.statusPlaceholder')" allowClear :options="{ options: recordOptions }")
                    VueDatePicker(
                        v-model="searchForm.monthRange"
                        :locale="l"
                        format="yyyy-MM" value-format="yyyy-MM" month-picker range clearable
                        :select-text="selectText"
                        :cancel-text="cancelText"
                    :placeholder="$t('cost.billList.billDate')" class="!w-60").w-60.h-10
                    w-button.h-10( @click="searchBox") {{ $t('common.search') }}
                    w-button.h-10( type="default" @click="resetBox") {{ $t('common.reset') }}
                .btns.flex.justify-end.mb-5
                    w-button(@click="exportFn") {{ $t("cost.billList.export") }}
            template(#operate="{ record }")
                w-button.mr-2( size="xs" @click="detailBox(record)") {{ $t("cost.billList.details") }}
                w-button.mr-2(v-if="record.billStatus===2" size="xs" @click="repaymentFn(record)") {{ $t("cost.billList.repayment") }}
                w-button.mr-2(v-if="record.billStatus===1" size="xs" @click="confirmBox(record)") {{ $t("cost.billList.ConfirmBilling") }}
w-drawer(
    :title="$t('cost.billList.repaymentTitle')" :visible="drawer" direction="rtl" @close="onCancel"
)
    div.drawer-content.p-4.leading-6
        p
            span(class="w-[120px]") {{ $t('cost.billList.billDate') }} ：
            span.text-sm {{ userPayInfo?.periodStr }}
        p.mt-2
            span.w-24 {{ $t('cost.billList.arrearsAmount') }} ：
            span.text-sm {{ VITE_UNIT }} {{ userPayInfo?.debtFee }}
        p.mt-2
            span.w-24.mt-2 {{ $t('cost.billList.payType') }} ：

            p.mt-2(v-if="userPayInfo?.payFlag===1||userPayInfo?.payFlag===2||userPayInfo?.payFlag===0").pl-8
                span.mr-2.mt-1 {{ $t('cost.billList.Trial') }}  ( {{ VITE_UNIT }}{{ userPayInfo?.trialBalance }})
                // eslint-disable-next-line vue/no-parsing-error
                p.text-slate-500 {{ t("cost.billList.pay") }}：{{VITE_UNIT}} {{ userPayInfo?.trialPayAmount }}
            p.mt-2(v-if="userPayInfo?.payFlag===2||userPayInfo?.payFlag===0").pl-8
                span {{ t("cost.billList.cashe") }}  ( {{ VITE_UNIT }}{{ userPayInfo?.cashBalance }} )
                // eslint-disable-next-line vue/no-parsing-error
                p.text-slate-500 {{ t("cost.billList.pay") }}： {{VITE_UNIT}} {{ userPayInfo?.cashPayAmount }}
    p(v-if="userPayInfo?.payFlag===0").pl-8.text-xs.text-slate-400
        p {{ $t('cost.billList.noAmountDesc') }}
            span.text-blue-500.ml-1.cursor-pointer(@click="openUrl('Recharge')")  {{ $t('cost.billList.noAmountUrl') }}
            span.ml-1.mr-1 {{ $t('cost.billList.or') }}
            // eslint-disable-next-line vue/no-parsing-error
            span.text-blue-500.ml-1.cursor-pointer(@click="openUrl('preFund')")  {{ $t('cost.billList.noAmountUrl2') }}
    template(#footer)
        w-button(v-if="userPayInfo?.payFlag!==0" :loading="loading" @click="onSubmit").mr-4 {{ $t("common.payBtn") }}
        w-button(type="default" @click="onCancel") {{ $t("common.cancel") }}
</template>
<script setup lang="ts">
import { zhCN, enUS, ko, ja } from 'date-fns/locale'
import VueDatePicker from '@vuepic/vue-datepicker';
import API from "@/common/api"
import { useI18n } from 'vue-i18n'
import { Confirm, Toast } from '@welink/design';
import { VITE_UNIT, isForeign } from "@/common/constants/env"
import { getLocale } from '@/utils/sso';

let langEnv = null;
const l: any = isForeign ? getLocale() || 'en' : 'zh'
console.log('l: ', l, isForeign);
if (l === 'en') {
    langEnv = enUS
} else if (l === 'ko') {
    langEnv = ko
} else if (l === 'ja') {
    langEnv = ja
} else if (l === 'zh') {
    langEnv = zhCN
}

console.log('isForeign: ', isForeign);
const router = useRouter()
const { t } = useI18n()
const { billList, billConfirm, payInfo, payment, billListDownload } = API.accountCost
const IncomeRecord = ref([])
const loading = ref(false)
const lang = ref<any>(isForeign ? langEnv : zhCN)
const selectText = t('common.confirm');
const cancelText = t('common.cancel');
const drawer = ref(false)
const userPayInfo = ref<any>({})
const recordOptions = [
    { label: t('common.billStatusList.Unbilled'), value: "0" },
    { label: t('common.billStatusList.TobeConfirmed'), value: "1" },
    { label: t('common.billStatusList.TobePaid'), value: "2" },
    { label: t('common.billStatusList.Settled'), value: "3" },
]

const searchForm = reactive({
    billStatusSet: [],
    monthRange: null
})
const pageinationParams = reactive({
    current: 1,
    size: 10,
    total: 0,
    pageNo: 1,
    pageSize: 10
})
const fetchTableList = async () => {
    const params: any = {
        billStatusSet: searchForm.billStatusSet,
        monthRange: searchForm.monthRange
    }
    if (searchForm?.monthRange && searchForm?.monthRange[0]) {
        console.log(searchForm?.monthRange[0] > 10)
        params.periodStart = params.monthRange[0].year + '-' + ((params.monthRange[0].month > 10) ? (params.monthRange[0].month + 1) : ('0' + (params.monthRange[0].month + 1)))
    }
    if (searchForm?.monthRange && searchForm?.monthRange[1]) {
        console.log(searchForm?.monthRange[0] > 10)
        params.periodEnd = params.monthRange[1].year + '-' + ((params.monthRange[1].month > 10) ? (params.monthRange[1].month + 1) : ('0' + (params.monthRange[1].month + 1)))
    }

    loading.value = true
    IncomeRecord.value = []
    const data: any = await billList({ ...pageinationParams, ...params })
    IncomeRecord.value = data.list
    pageinationParams.total = data.total
    loading.value = false
}
const openUrl = (name: any) => {
    router.push({ name: name })
}
const confirmBox = (row: any) => {
    Confirm({
        title: t('cost.billList.ConfirmDesc'),
        okTxt: t('common.confirm'),
        cancelTxt: t('common.cancel'),
        async onOk() {
            const data: any = await billConfirm({ id: row.id })
            if (data.data.code === 0) {
                Toast.success(t('console.message.addSuccess'))
                searchBox()
            } else {
                Toast.error(data.data.msg)
            }
        },
    })
}
const detailBox = (row: any) => {

    router.push({ name: "billDetail", params: { id: row.id } })
}
const onCancel = () => {
    drawer.value = false
}
const onSubmit = async () => {
    // drawer.value = false
    const data: any = await payment({ billMainId: userPayInfo.value.billMainId })
    console.log(data)
    if (data.data.code === 0) {
        Toast.success(t('console.message.addSuccess'))
        drawer.value = false
        searchBox()
    } else {
        Toast.error(data.data.msg)
    }
}
const repaymentFn = async (row: any) => {
    const data: any = await payInfo({ id: row.id })
    console.log(data)
    if (data.data.code === 0) {
        userPayInfo.value = data.data.data
        drawer.value = true
    } else {
        Toast.error(data.data.msg)
    }

}
const searchBox = () => {
    pageinationParams.current = 1
    pageinationParams.pageNo = 1
    fetchTableList()
}
const resetBox = () => {
    searchForm.billStatusSet = []
    searchForm.monthRange = null
    fetchTableList()
}
const changeParams = (current: number) => {
    pageinationParams.current = current
    pageinationParams.pageNo = current
    fetchTableList()
}
const sizeChange = (size: number) => {
    pageinationParams.size = size
    pageinationParams.pageSize = size
    fetchTableList()
}

const exportFn = async () => {
    const params: any = {
        billStatusSet: searchForm.billStatusSet,
        monthRange: searchForm.monthRange
    }
    if (searchForm?.monthRange && searchForm?.monthRange[0]) {
        console.log(searchForm?.monthRange[0] > 10)
        params.periodStart = params.monthRange[0].year + '-' + ((params.monthRange[0].month > 10) ? (params.monthRange[0].month + 1) : ('0' + (params.monthRange[0].month + 1)))
    }
    if (searchForm?.monthRange && searchForm?.monthRange[1]) {
        console.log(searchForm?.monthRange[0] > 10)
        params.periodEnd = params.monthRange[1].year + '-' + ((params.monthRange[1].month > 10) ? (params.monthRange[1].month + 1) : ('0' + (params.monthRange[1].month + 1)))
    }
    const data: any = await billListDownload({ ...pageinationParams, ...params })
    console.log(data)
    const blob = new Blob([data.data as any])
    const url = window.URL.createObjectURL(blob)
    console.log(url)
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', `${t('cost.billList.exportName')}.xlsx`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
}

const columns = ref<any>([
    {
        title: t("cost.billList.columns.account"),
        prop: 'parentUsername',
        width: 100
    },
    {
        title: t("cost.billList.columns.period"),
        prop: 'periodStr',
        width: 150
    },
    {
        title: t("cost.billList.columns.statementDate"),
        prop: 'effectDate',
        width: 150
    },
    {
        title: t("cost.billList.columns.meet"),
        prop: 'totalFee',
        width: 150
    },
    {
        title: t("cost.billList.columns.paidAmount"),
        prop: 'paidFee',
        width: 150
    },
    {
        title: t("cost.billList.columns.arrearsAmount"),
        prop: 'debtFee',
        width: 150
    },
    // {
    //     title: "余额",
    //     prop: 'operateTime',
    // },
    {
        title: t("cost.billList.columns.PaymentDueDay"),
        prop: 'repayDate',
        width: 150
    },
    {
        title: t("cost.billList.columns.state"),
        prop: 'billStatusDesc',
        width: 200
    },
    {
        title: t("cost.billList.columns.operate"),
        prop: 'operate',
        width: 200
    },

])

onMounted(fetchTableList)

</script>

<style lang="sass">
.dp__pointer
    height: 40px !important
.dp__overlay_cell_active
    background-color: rgb(26, 86, 219) !important
.dp__action_button
    font-size: 12px
    height: 30px !important
    border: 1px solid rgba(0,0,0,.1) !important
    color: #000 !important
    width: 60px !important
    &:hover
        color: #fff !important
        background-color: rgb(26, 86, 219)
</style>