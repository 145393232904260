import { i18n } from '@/i18n'
import { Toast } from '@welink/design'

export const copyText = (txt: string) => {
    const a = document.createElement("input")
    a.value = txt
    document.body.appendChild(a);
    a.select(); //
    document.execCommand("copy");
    Toast.success(i18n.global.t('common.copySuccess'))
    // 复制成功后再将构造的标签 移除
    document.body.removeChild(a);
}