<template lang="pug">
.console-main-process
    step(:current="1")
    .inner.flex.justify-center.py-12.px-4.pt-16
        step-back
        .flex.w-170
            span.order 2.
            .select-game.flex-1
                .mb-6
                    .label.txt-color
                        | {{ $t('v2.gameList.create') }}
                    .flex.flex-1.mt-3
                        a-input(v-model:value="gameInfo.name" :disabled="saveGame" :placeholder="$t('v2.gameList.inputPlaceholder')")
                        a-button.ml-4(class="!h-10" :loading="saveGameLoading" :disabled="!gameInfo.name.trim() || saveGame" @click="saveGameHandle") {{ $t('v2.action.save') }}
                game-upload-tip
                .flex.justify-end.mt-8
                    a-button(type="primary" :loading="createGameLoading" :disabled="!canSubmit" @click="onNext") {{ $t('v2.action.next') }}
</template>

<script lang="ts" setup>
import API from '@/common/api/console/v2/home'
import Step from './components/step.vue'
import StepBack from '@/views/console/v2/components/stepBack.vue'
import gameUploadTip from '@/views/console/v2/components/gameUploadTip.vue'
import { useGlobalStore } from "@/store/global";

const saveGameLoading = ref(false)
const saveGame = ref(false)
const createGameLoading = ref(false)
const router = useRouter()
const route = useRoute()
const projectId = computed(() => route.params.projectId)

const canSubmit = computed(() => gameInfo.name.trim() && saveGame.value)

const gameInfo = reactive({
    name: '',
    id: ''
})

const saveGameHandle = () => {
    saveGameLoading.value = true
    API.createGame(gameInfo.name, projectId.value as string).then((data: any) => {
        saveGameLoading.value = false
        saveGame.value = true
        gameInfo.id = data.id
    }).catch(() => {
        saveGameLoading.value = false
    })
}

const onNext = async () => {
    createGameLoading.value = true
    API.setMainStep(2, {
        lastProjectId: projectId.value,
        lastPackageId: gameInfo.id
    }).then(() => {
        createGameLoading.value = false
        router.push({ name: 'mainProcessStep03', params: { projectId: projectId.value, packageId: gameInfo.id } })
    }).catch(() => {
        createGameLoading.value = false
    })
}
</script>
