<template lang="pug">
div.outer
    div.title {{ $t('workOrder.submit.changeType') }} &nbsp;
        span {{ $t('workOrder.submit.cont') }}
    div.next(v-if="loading")
        div.cont(v-for="(item,index) in firstList" :key="index" :class="idx === index ? 'active hover:shadow cursor-pointer' : 'hover:shadow cursor-pointer'" @click="dayClick(index,item)")
            h2 {{ item.name }}
            h3 {{ item.depict }}
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import API from "@/common/api"
const props = defineProps<{
    problemTypesIdx: null
    fitstId:null
}>();
const emit = defineEmits(['problemTypesChange'])
const { t } = useI18n()
const {topFirstList} = API.workOrder
const loading = ref(false)
const firstList = ref([
    {
        "depict":null,
        "name":null
    }
])
const idx = ref<any>(props.problemTypesIdx)
const dayClick = (index: number,item:any) => {
    idx.value = index
    setTimeout(() => {
        emit("problemTypesChange",{currentStep:3, mindIdx:index,secoundList:item})
    },100)
}
onMounted(async() => {
    try {
        await topFirstList({parentId:props.fitstId}).then((data:any) => {
            firstList.value = data
            loading.value = true
        })
    } catch (e) {
        loading.value = false
    }
})
</script>

<style lang="sass" scoped>
.outer
    margin: 20px 0
    .title
        font-weight: 700
        margin-bottom: 20px
        span
            color: #999
    .next
        display: flex
        flex-direction: row
        flex-wrap: wrap
        max-height: 400px
        overflow-y: scroll
        padding-bottom: 100px
        .cont
            width: 340px
            height: 120px
            padding: 20px
            background: #fff
            border: 1px solid #ccc
            border-radius: 10px
            margin: 12px
            margin-left: 0
            h2
                line-height: 20px
            h3
                color: #999
        .active
            border: 2px solid #2e58e8
</style>
