<template lang="pug">
.account-info.px-5.py-5.bg-white.mt-5.border.ml-4.mr-4
    .cost-amount.border-t-gray-900.flex.list-none.justify-around(class="border-t-orange-900")
        li.w-72.h-20(class="").flex.items-center.pl-16
            svg-icon(name="cycle" class="-mt-0.5").mr-5
            div.flex.flex-col.items-start
                span.text-slate-400.text-xs {{ $t('cost.recharge.cashBalance') }}
                span.text-xl.text-blue-500.font-semibold {{VITE_UNIT}} {{ dataDetail?.cashBalance }}
        li.w-full.h-20.flex.items-center.flex-1.pl-16
            svg-icon(name="amount" class="-mt-0.5").mr-1.mr-5
            div.flex.flex-col.items-start.mt-4
                span.text-slate-400.text-xs {{ $t('cost.recharge.canUseBalance') }}
                span.text-xl.text-blue-500.font-semibold {{VITE_UNIT}} {{ dataDetail?.canUseBalance }}
                span.text-slate-400.text-xs.text-left {{ $t('cost.recharge.desc') }}={{ $t('cost.recharge.canUse') }}=（{{ $t('cost.recharge.canUseBalancedesc') }} {{unit}}{{dataDetail?.cashBalance}}+{{ $t('cost.recharge.trialBalance') }}{{unit}}{{dataDetail?.trialBalance}}+{{ $t('cost.recharge.creditBalance') }}{{unit}}{{dataDetail?.creditBalance}}）-（{{ $t('cost.recharge.currentBillAmount') }}{{unit}}{{dataDetail?.currentBillAmount||0}}+{{ $t('cost.recharge.historyBillAmount') }}{{unit}}{{dataDetail?.historyBillAmount||0}}）
div.px-5.mt-7.desc(class="text-xs font-medium text-gray-500  border-gray-200")
    ul(class="flex flex-wrap -mb-px" )
        li.text-left(class="cursor-pointer").text-left
            span(
                :class="{ 'inline-block px-4 py-2 border-t-2 border-l-2   hover:text-gray-600 hover:border-t-gray-300 !font-semibold': true, '!text-blue-600 !border-t-blue-600': true }"
            ) {{$t('cost.recharge.payType1')}}
        li.text-left(class="cursor-not-allowed").text-left
            span(
                :class="{ 'inline-block px-4 py-2 border-t-2 border ': true, '!text-blue-600 !border-blue-600 !font-semibold' :false }"
            ) {{$t('cost.recharge.payType2')}}.
    div.text-content.leading-4.p-5.border
        .desc.bg-gray-100.p-4.leading-6
            p.text-xs.text-slate-700.mb-1
            ol.list-decimal.pl-4
                li.leading-3.mb-2
                    span.text-xs.text-slate-700 {{$t('cost.recharge.RechargeTypeDesc1')}}
                    router-link(:to="{ name: 'add'}").text-xs.text-blue-600.mx-1 {{$t('cost.recharge.RechargeTypeDesc4')}}
                    span.text-xs.text-slate-700 {{$t('cost.recharge.RechargeTypeDesc5')}}
                li.leading-3.mb-2
                    p.text-xs.text-slate-700.mb-1 {{$t('cost.recharge.RechargeTypeDesc2')}}
                li.leading-3.mb-2
                    p.text-xs.text-slate-700 {{$t('cost.recharge.RechargeTypeDesc3')}}
        //- p.mt-4.text-slate-700 {{$t('cost.recharge.contact')}}
        //- p.mt-2.text-xs.text-slate-400
        //-     | {{$t('cost.recharge.email')}}:
        //-     span.text-gray-700 xxxxxxxxx@xxx.com
        //- p.mt-1.text-xs.text-slate-400
        //-     |  {{$t('cost.recharge.telNumer')}}:
        //-     span.text-gray-700.ml-1 +86 138 1234 5678
.table-list.px-5.py-5.bg-white.mt-7.border.ml-4.mr-4.mb-10
    span.mb-10 {{ $t('cost.recharge.tableTitle') }}
    w-table.mt-2(
        :dataSource="cashRecord"
        :loading="loading"
        :columns="columns"
        :pagination="pageinationParams"
        :scroll="{ x: 800 }"
        @pagination-num-change="changeParams"
        @pagination-size-change="sizeChange"
    )
        template(#extra)
            .flex
                .w-60.mb-4
                    w-picker(
                        v-model="timeRange"
                        :placeholder="$t('cost.recharge.datePlaceholder')"
                        :enable-time-picker="false"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        autoApply
                        range
                    )
</template>
<script lang="ts" setup>
import dayjs from "dayjs";
import API from "@/common/api"
import { getUserInfo } from "@/utils/sso";
import { VITE_UNIT } from "@/common/constants/env"
const unit = VITE_UNIT ? VITE_UNIT : "￥"
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const { accountDetail, cashRecordList } = API.accountCost
const dataDetail = ref<any>()
const userInfo: any = reactive(getUserInfo())
const loading = ref(false)
const cashRecord = ref<any>([])
const timeRange = ref<any>([])
const pageinationParams = reactive<any>({
    current: 1,
    pageNo: 1,
    pageSize: 10,
    size: 10,
    total: 0
})
const columns = ref<any>([
    {
        title: t("cost.creditLimit.columns.serialNumber"),
        prop: 'serialNumber',
        width: 200
    },
    {
        title: t("cost.creditLimit.columns.operateTypeDesc"),
        prop: 'operateTypeDesc',
        width: 150
    },
    {
        title: t("cost.creditLimit.columns.currency"),
        prop: 'currency',
        width: 100
    },
    {
        title: t("cost.creditLimit.columns.operateAmount"),
        prop: 'operateAmount',
        width: 200
    },
    {
        title: t("cost.creditLimit.columns.amountAfterOperate"),
        prop: 'amountAfterOperate',
        width: 250
    },
    {
        title: t("cost.creditLimit.columns.operateTime"),
        appendColumnContent: 'UTC+8:00',
        prop: 'operateTime',
        width: 300
    },
    {
        title: t("cost.creditLimit.columns.operateRemark"),
        prop: 'operateRemark',
        width:100
    },

])
const fetchTableList = async () => {
    const params: any = {}
    if (timeRange.value?.length) {
        params.operateTimeBegin = dayjs(timeRange.value[0]).format('YYYY-MM-DD')
        params.operateTimeEnd = dayjs(timeRange.value[1]).format('YYYY-MM-DD')
    }
    loading.value = true
    cashRecord.value = []
    try {
        const data: any = await cashRecordList({ ...pageinationParams, ...params })
        cashRecord.value = data.list
        pageinationParams.total = data.total
        loading.value = false
    } catch (e) {
        loading.value = false
    }
}

watch(() => timeRange.value, () => {
    console.log(2)
    pageinationParams.current = 1
    pageinationParams.pageNo = 1
    fetchTableList()
})

const searchBox = () => {
    pageinationParams.current = 1
    pageinationParams.pageNo = 1
    fetchTableList()
}
const changeParams = (current: number) => {
    pageinationParams.current = current
    pageinationParams.pageNo = current
    fetchTableList()
}
const sizeChange = (size: number) => {
    pageinationParams.size = size
    pageinationParams.pageSize = size
    fetchTableList()
}
onMounted(() => {
    accountDetail({ username: userInfo.username }).then(data => {
        console.log(data)
        dataDetail.value = data
    })
    fetchTableList()
})

</script>
<style lang="sass" scoped>
.account-info li
    border-right: 1px solid #ccc
    text-align: center

.account-info li:last-child
    border-right: 0px
    text-align: center
.account-info,.table-list
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.08)
    border-radius: 8px
.text-content
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.08)
    border-radius: 0px 0px 8px 8px
.desc li
    // border-right: 1px solid #ccc
    text-align: left
</style>