import { useRequest } from '@/hooks/useRequest'
import Api from "@/common/api"

export const sendSmsCode = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.userCenter.api.sendSmsCode, 'post', params || {}, { manual: true  })
}
export const userTelModify = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.userCenter.api.userTelModify, 'post', params || {}, { canCustomHandle:true,manual: true  })
}
export const userDetail = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.userCenter.api.userDetail, 'post', params || {}, { manual: false  })
}
export const passwordModify = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.userCenter.api.passwordModify, 'post', params || {}, {canCustomHandle:true, manual: true  })
}
export const abroadPasswordModify = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.userCenter.api.abroadPasswordModify, 'post', params || {}, {canCustomHandle:true, manual: true  })
}
export const userInfoModify = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.common.userInfoModify, 'post', params || {}, {canCustomHandle:true, manual: true  })
}
export const industryInfoModify = (params?: Partial<any>) => {
    return useRequest<Partial<any>, any>(Api.common.industryInfoModify, 'post', params || {}, {canCustomHandle:true, manual: true  })
}
