<template lang="pug">
div.outer
    svg-icon(name="stepSuccess" width="90" height="90")
    h2 {{ $t('workOrder.submit.success') }}
    h3 {{ $t('workOrder.submit.time') }}
    w-button.btn(@click="onSubmit") {{ $t('workOrder.submit.sure') }}
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { router } from '@/routers'
const { t } = useI18n()
const form = ref<any>()
const onSubmit = (data: any) => {
    router.push({ name: 'myWorkOrderList' })
}
</script>

<style lang="sass" scoped>
.outer
    text-align: center
    margin-top: 200px
    h2
        font-size: 22px
        font-weight: 500
        line-height: 80px
    .btn
        margin-top: 40px
</style>