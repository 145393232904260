<template lang="pug">
.console-main-process
    step(v-if="!isFromGame" :current="2")
    .inner.flex.justify-center.py-12
        step-back(v-if="!isFromGame")
        .w-170.flex
            span.order(v-if="!isFromGame") 3.
            .select-game.flex-1
                .label.txt-color {{ $t('v2.mainProcess.s3.title') }}
                .text-xs.txt-second-color.mb-6 {{ $t('v2.mainProcess.s3.desc') }}
                template(v-if="loading")
                    .pt-40.flex.justify-center
                        a-spin(size="large" :spinning="loading" :tip="$t('v2.action.loading') + '...'")
                template(v-else)
                    .relative
                        .app.flex.py-4.items-center.border-b
                            .text-sm.text-gray-500.w-26 {{ $t('v2.mainProcess.s3.project') }}
                            | {{ projectInfo.applicationName }}
                        .app.flex.py-4.items-center.border-b
                            .text-sm.text-gray-500.w-26 {{ $t('v2.action.game') }}
                            | {{ projectInfo.packageName }}
                        .app.flex.py-4.border-b.items-start
                            .text-sm.text-gray-500.w-26.shrink-0 {{ $t('v2.mainProcess.s3.resource.title') }}
                            .goods-desc.flex.flex-col.border.border-gray-300.border-dashed.p-4.rounded
                                template(v-if="skuLoading")
                                        a-spin(:spinning="skuLoading")
                                template(v-else)
                                    .content.w-full
                                        span.pb-2(v-for="item in skuList" :key="item.skuName" class="w-1/2" :span="12")
                                            .txt-color.mb-4 {{ item.skuName }}
                                            .text-xs.txt-second-color.mb-4 {{ item.remark }}
                                            .flex.justify-between
                                                .border.py-2.px-1.rounded.w-28.flex-shrink-0
                                                    .text-xs.txt-second-color.mb-1.text-center {{ $t('v2.mainProcess.s3.resource.server') }}
                                                    .txt.text-sm.text-center {{ item.operatingSystem }}
                                                .border.py-2.px-1.rounded.w-28.flex-shrink-0
                                                    .text-xs.txt-second-color.mb-1.text-center {{ $t('v2.mainProcess.s3.resource.resolution') }}
                                                    .txt.text-sm.text-center {{ item.resolution }}
                                                .border.py-2.px-1.rounded.w-28.flex-shrink-0
                                                    .text-xs.txt-second-color.mb-1.text-center {{ $t('v2.mainProcess.s3.resource.rate') }}
                                                    .txt.text-sm.text-center {{ item.fps }}
                                                .border.py-2.px-1.rounded.w-28.flex-shrink-0
                                                    .text-xs.txt-second-color.mb-1.text-center {{ $t('v2.mainProcess.s3.resource.bitrate') }}
                                                    .txt.text-sm.text-center {{ item.bitRate }}
                        .app.flex.py-4.items-center.border-b
                            .text-sm.text-gray-500.w-26.flex-shrink-0 {{ $t('v2.mainProcess.s3.region') }}
                            .flex.gap-4.flex-wrap
                                template(v-for="(node) in nodeOptions" :key="node.value")
                                    a-button(:type="node.type" @click="addNode(node)")  {{ node.label }}
                            //- a-select(v-model:value="nodeIds" label-in-value mode="multiple" size="sm" :placeholder="$t('v2.mainProcess.s3.region-select')" :options="nodeOptions").w-60
                        .app.flex.py-4.items-start.border-b(v-if="nodeIds.length")
                            .text-sm.text-gray-500.w-26.pt-2.flex-shrink-0 {{ $t('v2.mainProcess.s3.nums') }}
                            .w-full
                                template(v-for="(item, index) in nodeIds" :key="index")
                                    .border-b.border-dashed.mb-5.pb-5(:class="{'!border-none !pb-0': nodeIds.length === (index + 1)}")
                                        .flex.items-start.w-full.justify-between
                                            div.flex
                                                span.w-24.select-none.text-left.txt-color.text-sm(class="pt-2 mr-3") {{ item.label }}
                                                div
                                                    a-input-number(
                                                        v-model:value="item.quantity" :controls="false" min="1" :precision="0" autofocus
                                                        maxlength="5"
                                                        :status="item.quantity > item.maxQuantity ? 'error' : ''"
                                                    ).w-34.text-center
                                                        template(#addonBefore)
                                                            span.action(:class="{ 'disabled' : item.quantity <= 1}" @click="item.quantity > 1 ? item.quantity-- : null") -
                                                        template(#addonAfter)
                                                            span.action(@click="item.quantity++") +
                                            div.flex.gap-5
                                                .price.text-left
                                                    .text-xs.txt-second-color.mb-1 {{ $t('v2.mainProcess.s3.resource.price-unit') }}
                                                    span.text-xs.mr-1.txt-second-color US{{ VITE_UNIT }}
                                                    span.txt-color.text-xs {{ item.price }}
                                                    span.text-xs.txt-second-color  /{{ $t('v2.mainProcess.s3.resource.hour') }}
                                                .price.text-left.w-31.flex-shrink-0
                                                    .text-xs.txt-second-color.mb-1 {{ $t('v2.mainProcess.s3.resource.price') }}
                                                    span.text-xs.mr-1.txt-second-color US{{ VITE_UNIT }}
                                                    span.txt-color.text-xs {{ (item.quantity * item.price * 100/100).toFixed(2) }}
                                                    span.text-xs.txt-second-color  /{{ $t('v2.mainProcess.s3.resource.hour') }}
                                        .text-red-500.text-xs.pt-1.pl-27(v-if="item.quantity > item.maxQuantity")
                                            | {{ $t('v2.mainProcess.s3.resource.quantityTip') }}: {{ item.maxQuantity }}.
                        .app.flex.py-4.items-center.border-b.justify-end.mb-6
                            .flex.items-end.txt-color.font-bold
                                .text-xs {{ $t('v2.mainProcess.s3.price') }} US{{ VITE_UNIT }}：
                                .span.font-semibold
                                    .font-bold.txt-color {{ totalPrice || '-' }}
                                span.ml-1.txt-second-color.text-xs  /{{ $t('v2.mainProcess.s3.resource.hour') }}
                        .app.flex.items-center.justify-end.mt-6
                            a-button(type="primary" :loading="buyLoading" :disabled="!canSubmit" @click="onSubmit").font-medium
                                | {{ $t('v2.action.buy') }}
</template>

<script lang="ts" setup>
import API from '@/common/api/console/v2/home';
import { VITE_UNIT } from "@/common/constants/env";
import Step from './components/step.vue'
import StepBack from '@/views/console/v2/components/stepBack.vue'

const skuList = ref<any[]>([])
const loading = ref(false)
const buyLoading = ref(false)
const skuLoading = ref(false)
const totalPrice = ref('')
const route = useRoute()
const router = useRouter()
const nodeIds = ref<any>([])
const nodeOptions = ref()
const unitName = ref('')

const isFromGame = computed(() => route.query.fromGame)
const projectInfo = ref({
    applicationName: '',
    packageName: ''
})
const canSubmit = computed(() => nodeIds.value.length ? nodeIds.value.every((item: any) => item.quantity && item.quantity <= item.maxQuantity) : false)

const onSubmit = async () => {
    buyLoading.value = true
    const { projectId, packageId } = route.params
    const nodeList = nodeIds.value.map((item: any) => ({ nodeId: item.value, quantity: item.quantity }))
    const skuId = skuList.value[0].skuId
    try {
        const res = await API.orderPurchase({
            applicationId: projectId,
            nodeList,
            packageId,
            skuId
        })
        if (isFromGame.value) {
            window.history.back()
        } else {
            await API.setMainStep(3, { lastProjectId: projectId, lastPackageId: packageId })
            buyLoading.value = false
            if (res) {
                router.push({ name: 'mainProcessStep04', params: { projectId, packageId } })
            }
        }
    } catch (e) {
        buyLoading.value = false
    }
}

watch(nodeIds, (v) => {
    let total = 0;
    nodeIds.value.forEach((node: any) => {
        total += node.quantity * node.price
    })
    totalPrice.value = (total * 100 / 100).toFixed(2)
}, { deep: true })

const getPrice = async (node: any) => {
    const nodeId = node.value
    const skuId = skuList.value[0].skuId
    const data: any = await API.getSkuPrice({ nodeId, skuId })
    const quota: any = await API.getNodeQuota({ nodeId })
    node.maxQuantity = quota.quantity
    node.price = Number(data.skuPrice)
}

const addNode = async (node: any) => {
    console.log('node:', node)
    if(node.type === 'default') {
        node.type = 'primary'
        node.quantity = 1
        nodeIds.value.push(node)
        // await getPrice(node)
    } else {
        node.type = 'default'
        const index = nodeIds.value.findIndex((n: any) => n.value === node.value)
        nodeIds.value.splice(index,1)
    }
}


onBeforeMount(async () => {
    const { projectId, packageId } = route.params
    loading.value = true
    const skuParams: any = {
        applicationId: projectId,
        packageId
    }
    try {
        const nodeList: any = await API.getResourceNodeConfig()
        const gameInfo: any = await API.getGameInfo(packageId as string)
        const skuConfigList: any = await API.getSkuAlreadyAdapterList(skuParams)
        skuList.value = skuConfigList.data.data

        if (skuConfigList.data.code === 200) {
            projectInfo.value = gameInfo
            nodeOptions.value = nodeList.map((item: any) => ({label: item.nodeName, value: item.nodeId, quantity: 1, type: 'default', price: 0, maxQuantity: 1 }))
            for (const node of nodeOptions.value) {
                await getPrice(node)
            }
            loading.value = false
        }

    } catch (e) {
        console.log('e:', e)
        loading.value = false
    }
})
</script>