import { regPassword, regUserName, regPhone, regEmail } from "@/utils/validate"
import { i18n } from '@/i18n'
export const validatePwd = (newValue: string, oldValue: string) => {
    if (oldValue !== newValue) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(i18n.global.t("forgetPassword.rePasswordError"))
    } else {
        return Promise.resolve();
    }
}

export const getVerifyCodeMsg = i18n.global.t('userCenter.getImgCode')
export const getVerifyCodeIng = i18n.global.t("forgetPassword.Obtaining")

export const FormConst = {
    username: {
        label: i18n.global.t("forgetPassword.Account"), value: '', hideLabel: true,
        rules: [
            { required: true, message: i18n.global.t("forgetPassword.AccountEmpty") },
            { pattern: regUserName.pattern, message: regUserName.message }
        ],
        placeholder: i18n.global.t("forgetPassword.accountPleaceholder"), autocomplete: 'off'
    },
    userEmail: {
        label:  i18n.global.t("forgetPassword.Email"), value: '', hideLabel: true,
        rules: [
            { required: true, message:  i18n.global.t("forgetPassword.EmailEmpty") },
            { pattern: regEmail.pattern, message:  i18n.global.t("forgetPassword.EmailFormat") }
        ],
        placeholder: i18n.global.t("forgetPassword.emailPlaceholder"), autocomplete: 'off'
    },
    password: {
        label:  i18n.global.t("forgetPassword.password"), value: '', type: 'password', hideLabel: true,
        rules: [
            { required: true, message: i18n.global.t("forgetPassword.passwordEmpty") },
            { pattern: regPassword.pattern,message: i18n.global.t("forgetPassword.passwordFormat") }
        ],
        minlength: 8, maxlength: 30,
        placeholder: i18n.global.t("forgetPassword.passwordPlaceholder"), autocomplete: "new-password"
    },
    confirmPassword: {
        label: i18n.global.t("forgetPassword.rePassword"), type: 'password', value: '', hideLabel: true,
        validateFirst: true,
        rules: [
            { required: true, message: i18n.global.t("forgetPassword.rePasswordEmpty") },
            { message: i18n.global.t("forgetPassword.rePasswordFormat"), pattern: regPassword.pattern },
        ],
        placeholder: i18n.global.t("forgetPassword.rePasswordPlaceholder"), autocomplete: "new-password"
    },
    imgVerifyCode: {
        label: i18n.global.t("forgetPassword.ImageVerificationCode"), value: '', hideLabel: true,
        rules: [
            { required: true , message: i18n.global.t("forgetPassword.ImageVerificationCodeEmpty")},
        ],
        maxlength: 4, placeholder: i18n.global.t("forgetPassword.ImageVerificationCodePlaceholder")
    },
    userTel: {
        label: '手机号', value: '', addonBefore: "+86", hideLabel: true,
        rules: [
            { required: true,  message: '手机号不能为空'},
            { message: '手机号格式错误', pattern: regPhone.pattern }
        ],
        maxlength: 11, placeholder: "请输入手机号"
    },
    smsCode: {
        label: '短信验证码', value: '', hideLabel: true,
        rules: [
            { required: true , message: '短信验证码不能为空'},
        ],
        maxlength: 6, placeholder: "请输入短信验证码"
    },
    verifyCodeEmail: {
        label: i18n.global.t("forgetPassword.EmailVerificationCode"), value: '', hideLabel: true,
        rules: [
            { required: true , message: i18n.global.t("forgetPassword.EmailVerificationCodeEmpty")},
        ],
        maxlength: 6, placeholder: i18n.global.t("forgetPassword.EmailVerificationCodePleaceholder")
    },
}
