<template lang="pug">
h1.console-title.pl-6.pt-4
    span(class="text-[#86909C]")
        router-link(:to="{ name: 'projectList' }") {{ $t('v2.projectList.title') }}
        span.mx-1 /
        span(@click="goBack").cursor-pointer {{ gameInfo.applicationName }}
        span.mx-1 /
    | {{ gameInfo.packageName }}
.bg-white.p-6.rounded-lg.min-h-full(class="mt-[14px] !pr-10")
    a-spin(size="large" :spinning="loading" :tip="$t('v2.action.loading') + '...'")
        .flex.justify-between.mb-18
            .flex
                .thumb(class="mr-[50px]")
                    span.text-sm.txt-second-color.block.mb-1.font-bold {{ $t('v2.gameList.thumb') }}:
                    .cover.flex.items-center.justify-center.rounded-lg
                        img.w-20.h-20.transition-all(src="@/assets/images/console/game-cover.png")
                .info
                    span.text-sm.txt-second-color.block.my-1 {{ $t('v2.action.project') }}
                    .text-base.txt-color.block.mb-3.font-medium {{ gameInfo.applicationName || '-' }}
                    span.text-sm.txt-second-color.block.mb-1 {{ $t('v2.action.game') }}
                    .text-base.txt-color.block.mb-3.font-medium {{ gameInfo.packageName }}
                    span.text-sm.txt-second-color.block.mb-1 {{ $t('v2.gameList.fileSize') }}
                    .text-base.txt-color.block.mb-2.font-medium {{ bytesToSize(gameInfo.packageSize) }}
            .pt-5.flex.flex-col
                template(v-if="canPlay")
                    a-button(type="primary" @click="play('')").block.mb-5.w-full
                        svg-icon(name="play" :size="16" class="-mt-1 mr-2")
                        span.font-bold {{ $t('v2.action.play') }}
                template(v-else)
                    a-tooltip(:title="playTip" overlayClassName="header-tooltip")
                        a-button(type="primary" disabled).block.mb-5.w-full
                            svg-icon(name="play" :size="16" class="-mt-1 mr-2")
                            span.font-bold {{ $t('v2.action.play') }}
                a-button.font-bold(@click="deleteVisible = true")
                    svg-icon(name="trash" :size="16" class="-mt-1 mr-1")
                    span.font-bold {{ $t('v2.action.delete') }}
        .resource
            .flex.justify-between
                .text-base.font-bold.txt-color.mb-5 {{ $t('v2.gameList.resourceList') }}
                a-button(class="!font-bold" size="small" @click="router.push({ name: 'mainProcessStep03', params: { projectId: gameInfo.applicationId || 2, packageId: route.params.gameId }, query: { fromGame: 1 } })") {{ $t('v2.gameList.addResource') }}
            a-table(
                :columns="columns" :data-source="gameInfo.resourceList" :pagination="false"
                :scroll="{ x: 1500 }"
            ).rounded-lg
                template(#emptyText) 
                    .py-10.txt-third-color {{ $t('v2.action.emptyData') }}
                template(#bodyCell="{ column, text, record }")
                    template(v-if="column.key === 'status'")
                        a-tag(:color="resourceStatusMap[text].color") {{ resourceStatusMap[text].text }} 
                    template(v-if="column.key === 'createTime'")
                        span(v-if="text") {{ dayjs(text).format('YYYY-MM-DD HH:mm:ss') }}
                    template(v-if="column.key === 'action'")
                        template(v-if="[3,4].includes(record.status)")
                            a-button(type="primary" size="small" @click="play(record.nodeId)")  {{ $t('v2.action.play') }}
                        template(v-else) -
    a-modal(
        v-model:open="deleteVisible" :width="432" :closable="false" wrapClassName="popup-delete-project" centered
    )
        .flex.items-center.justify-center
            svg-icon(name="popup-trash" :size="80").block.mb-2
        div.text-lg.txt-color.text-center.mb-8 {{ $t('v2.projectList.deleteTitle') }}
        p.p-0.mb-8(class="text-[#86909C] text-sm px-10")
            | {{ $t('v2.projectList.deleteDesc') }}
            strong.txt-color {{ $t('v2.projectList.deleteDescPrefix') }}
        template(#footer)
            a-button(type="primary" @click="deleteVisible = false") {{ $t('v2.action.cancel') }}
            a-button( danger :loading="deleteLoading" @click="deleteHandleOk") {{ $t('v2.action.delete') }}

    a-modal(v-model:open="gameVisible" :footer="null" width="800" centered :title="$t('console.adapter.stepEdit.preview')")
        .flex.justify-center.m-auto.text-sm.py-5
            .filed.text-center
                .font-semibold.mb-2 {{ $t('console.adapter.stepEdit.phone') }}
                .text-gray-500.mb-7.px-4 {{ $t('console.adapter.stepEdit.qrcode') }}
                .border.rounded.p-2.inline-block
                    .w-36.h-36.bg-slate-200autoFullPackage 
                        vue-qrcode(:value="qrCodeLink" tag="svg" :options="{ margin: 0, width: 144, height: 144 }").qrcode
            .border-r.mx-16
            .filed.text-center
                .font-semibold.mb-2 {{ $t('console.adapter.stepEdit.web') }}
                .text-gray-500.mb-7 {{ $t('console.adapter.stepEdit.webPreview') }}
                .block.mb-5
                    svg-icon(name="web-demo" :size="100").ml-2
                a-button(type="primary" @click="goWebPreview") {{ $t('console.adapter.stepEdit.look') }}
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { getLocale, getToken } from '@/utils/sso';
import { isForeign } from "@/common/constants/env"
import API from '@/common/api/console/v2/home'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { bytesToSize } from '@/utils/bytesConvert'
import { resourceStatusMap } from '@/views/console/v2/constants/index'
import { Toast } from '@welink/design';
import dayjs from 'dayjs'
import { useGlobalStore } from '@/store/global'

const store = useGlobalStore()
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const loading = ref(true)
const gameVisible = ref(false)

const gameInfo = ref<any>({
    applicationId: null,
    applicationName: null,
    packageName: null,
    packageVersion: null,
    packageSize: 0,
    resourceList: []
})
const playTip = ref('')
const canPlay = computed(() => {
    // 资源状态为运行中/变更中，允许支持试玩；
    const list = gameInfo.value.resourceList || []
    const canPlayItem = list.findIndex((item: any) => [3,4].includes(item.status))
    console.log('canPlayItem:', canPlayItem)
    if (canPlayItem !== -1) {
        return true
    }
    // 资源状态为已删除/无资源记录，点play提示请购买资源；
    const canBuy = list.every((item: any) => item.status === 1) || !list.length 
    console.log('canBuy:', canBuy)
    if (canBuy) {
        playTip.value = t('v2.resourceList.buyTip')
        return false
    }
    // 2、资源状态为开机中/部署中，点击play提示暂不支持试玩；
    const deployStatus = list.findIndex((item: any) => [0,2].includes(item.status))
    console.log('deployStatus:', deployStatus)
    if (deployStatus) {
        return false
    }
    playTip.value = t('v2.resourceList.notSupportTip')
    return false
})
const goBack = () => {
    window.history.back()
}
const columns = reactive<any[]>([
    { title: t('console.appInfo.resourceList.nodeName'), dataIndex: 'nodeName', key: 'nodeName' },
    {
        title: t('v2.console.sidebar.resource'),
        dataIndex: 'skuName', key: 'skuName',
        width: 200
    },
    { title: t('console.appInfo.resourceList.skuName'), dataIndex: 'specfication', key: 'skuName' },
    { title: t('console.appInfo.resourceList.actualStartTime'), dataIndex: 'createTime', key: 'createTime' },
    { title: t('console.appInfo.resourceList.skuNumber'), dataIndex: 'quantity', key: 'quantity' },
    {
        title: t('v2.resourceList.table.extensionAmount'),
        prop: 'extensionAmount',
        width: 200
    },
    { title: t('console.appInfo.resourceList.status'), dataIndex: 'status', key: 'status' },
    { title: t('common.action'), dataIndex: 'action', key: 'action', width: 100, fixed: 'right' },
])

const deleteVisible = ref(false)
const deleteLoading = ref(false)
const currentNodeId = ref('')

const deleteHandleOk = () => {
    deleteLoading.value = true
    API.deleteGame(route.params.gameId as string).then(() => {
        deleteLoading.value = false
        Toast.success(t('v2.action.deleteSuccess'))
        window.history.back()
    })
}

API.getGameInfo(route.params.gameId as string).then((data: any) => {
    gameInfo.value = data
    loading.value = false
}).catch(() => {
    loading.value = false
})

const play = (nodeId?:string) => {
    gameVisible.value = true
    if (nodeId) {
        currentNodeId.value = nodeId
    } else {
        currentNodeId.value = ''
    }
}

const goWebPreview = () => {
    const { gameId } = route.params
    const canPlayItem = gameInfo.value.resourceList.find((item: any) => [3,4].includes(item.status))
    const nodeId = currentNodeId.value || canPlayItem.nodeId
    console.log('nodeId:', nodeId)
    router.push({ name: 'projectGamePreview', params: { 
        projectId: gameInfo.value.applicationId, 
        gameId,
        nodeId
    } })
}
const qrCodeLink = computed(() => {
    const { gameId } = route.params
    const canPlayItem = gameInfo.value.resourceList.find((item: any) => [3,4].includes(item.status))
    const nodeId = currentNodeId.value || canPlayItem.nodeId
    const host = `${window.location.origin}/#/console/${gameInfo.value.applicationId}/${gameId}/${nodeId}/preview`
    return `${host}?lang=${isForeign ? getLocale() || 'en' : 'zh'}&loginToken=${getToken()}&envId=${store.envId}&isH5=1`
})

</script>

<style lang="sass" scoped>
.cover
    border: 1px solid #E5E6EB
    width: 270px
    height: 164px
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.74) 0%, rgba(255, 255, 255, 0.74) 100%), linear-gradient(285deg, #2053F3 -55.98%, #FEDFFF 105.41%)
    &:hover
        img
            width: 100px
            height: 100px
</style>