<template lang="pug">
div.flex-1.text-center
    a-button(type="primary" size="small" @click="changePhone").flex-1.text-center.mr-5 {{ t("userInfo.bind") }}
a-modal(:key="dialogFormEditPhone" v-model:open="dialogFormEditPhone",
    title="身份验证"
    ok-Text="确认"
    cancel-Text="取消"
    width="364px"
    :footer="null"
)
    getTestCode(ref="testCodeRef" :Tel="Tel")
    a-button(type="primary" @click="submitFn" ).w-full 确认
    div.w-full.text-center.cursor-pointer.mt-2
        span.text-xs.cursor-pointer.ml-2.text-blue-600(type="link" @click="changePhone") 原手机号无法接接收证码
a-modal(:key="dialogFormNewPhone" v-model:open="dialogFormNewPhone",
    title="身份验证"
    ok-Text="确认"
    cancel-Text="取消"
    width="364px"
    :footer="null"
    @cancel="cancelBox"
)
    getTestCode(ref="testCodeRefNew" :Tel="newPhone" :type="1")
    a-button(type="primary" @click="submitNewCode" ).w-full 确认

a-modal(v-model:open="dialogFormChange",
    title="绑定手机号"
    ok-Text="确认"
    cancel-Text="取消"
    @cancel="cancelBox"
    @ok="onSubmit"
)
    w-biz-form.add-form.p-5(
        ref="formRef"
        hide-labels
        layout="form"
        :class="{ 'one-line' : true, '!p-4':true }"
        :loading="loading"
        :formState="formStatePhone",
        hideSubmit
        hideReset
        :formLayout="{ layout: 'horizontal', labelCol: { span: 0 }, gap: 20 }"
    )  

a-modal( v-model:open="dialogFormChangePhone",
    title="修改手机号1"
    ok-Text="确认"
    cancel-Text="取消"
    @cancel="cancelBoxFn"
    @ok="onSuccess"
)
    w-biz-form.add-form.p-5(
        ref="formRefs"
        layout="form"
        :class="{ 'one-line' : true, '!p-4':true }"
        :loading="loading"
        :formState="formStatePhoneChange",
        hideSubmit
        hideReset
    )  
        template(#appendField="{ filedName }")
            template(v-if="filedName === 'smsCode'")
                a-button(v-if="!showTime" class="!absolute" @click="getPhoneCode").top-0.right-0.z-50 {{ verifyCodeTxt }}
                a-button(v-else class="!absolute" disabled).top-0.right-0.z-50 {{ verifyCodeTxt }}
</template>
<script lang="ts" setup>
import { regPhone } from "@/utils/validate"
import { sendSmsCode, userTelModify } from "@/service/userCenter/userCenter"
import { useI18n } from 'vue-i18n'
import { validate } from "vee-validate";
import { message } from '@welink/design-v2';
import getTestCode from "./component/getTest.vue"
import { cloneDeep } from "lodash-es";
import API from "@/common/api"
const props = withDefaults(defineProps<{
    Tel?: any,
}>(), {
    Tel: ""
})
const emits = defineEmits(["getUserDetail"])
const { t } = useI18n()
const formRef = ref()
const testCodeRef = ref()
const testCode = ref()
const testCodeRefNew = ref()
// const code = ref<any>(4)
const editKey = ref()
const changeKey = ref()
const formRefs = ref()
const verifyCodeTxt = ref<any>("获取验证码")
const showTime = ref(false)
const dialogFormEditPhone = ref(false)
const dialogFormChangePhone = ref(false)
const dialogFormNewPhone = ref(false)
const dialogFormChange = ref(false)
const newPhone = ref<any>()
const formStatePhone = ref<any>(cloneDeep({
    userTel: { label: '', value: '', rules: [{ required: true,message:"手机号不能为空"  }, { pattern: new RegExp(regPhone.pattern, "g"), message: regPhone.message }], addonBefore: "+86", placeholder: "请输入手机号" },
}))
// 修改手机号保存
const { data: phoneInfo, loading, run: runPhoneSave } = userTelModify()
const submitFn = () => {
    if (testCodeRef.value.code.length === 6) {
        // formRef.value.formRef.resetFields()
        // editKey.value = new Date().getTime()
        // dialogFormEditPhone.value = false
        testCode.value = testCodeRef.value.code
        dialogFormEditPhone.value = false
        // code.value = 4
        dialogFormChange.value = true


    } else {
        message.error("请输入6位数字验证码")
    }

}
const changePhone = () => {
    // formRef.value.formRef.resetFields()
    console.log('aaaaaa',dialogFormEditPhone.value)
    // code.value = 41
    dialogFormEditPhone.value = false
    dialogFormChange.value = true


}
const submitNewCode = async () => {
    // console.log(testCodeRef.value.code)
    if (testCodeRefNew.value.code.length === 6) {

        runPhoneSave({ srcUserTel: props.Tel, srcSmsCode: testCode?.value, userTel: formRef?.value?.formModel?.userTel, smsCode: testCodeRefNew.value.code }).then((res: any) => {
            if (phoneInfo.value.data.code === 0) {
                dialogFormNewPhone.value = false
                formRef.value.formRef.resetFields()
                
                message.success(t('userCenter.basicInpormation.operateSucess'))
                emits("getUserDetail")
            } else {
                message.error(phoneInfo.value.data.msg)
            }
        })
        // } else {
        //     const res:any = runPhoneSave({ smsCode: testCodeRefNew.value.code, userTel: formRef.value.formModel.userTel })
        //     console.log(phoneInfo.value)
        //     if (phoneInfo.value.data.code === 0) {
        //         // message.success("修改成功")
        //         dialogFormNewPhone.value = false
        //         formRef.value.formRef.resetFields
        //         message.success(t('userCenter.basicInpormation.operateSucess'))
        //         emits("getUserDetail")
        //     } else {
        //         message.error(phoneInfo.value.data.msg)
        //     }
        // }

    } else {
        message.error("请输入6位数字验证码")
    }
}
const formStatePhoneChange = ref<any>({
    userTel: { label: '新手机号', value: '', rules: [{ required: t('common.not_empty'), }], placeholder: "请输入新手机号" },
    smsCode: { label: '新手机验证码', value: '', rules: { required: t('common.not_empty') }, type: "test-code", placeholder: "请输入验证码" },
})

const editPasswordBox = () => {
    console.log("修改密码")
    editKey.value = new Date().getTime()
    dialogFormEditPhone.value = true
}
const cancelBox = () => {
    console.log(formRef.value.formRef.resetFields)
    console.log(formRef.value.formRef.resetFields)
    formRef.value.formRef.resetFields()
    dialogFormEditPhone.value = false
    dialogFormChange.value = false
}
const cancelBoxFn = () => {

    formRefs.value.formRef.resetFields()
    dialogFormEditPhone.value = false
    dialogFormChange.value = false
}
const getPhoneCode = async (e: any) => {
    e.preventDefault();
    if (!regPhone.pattern.test(formRefs.value.formModel.userTel)) {
        message.error("新手机号格式错误")
        return
    }

    const { data: codeData, run: runCode } = sendSmsCode()
    runCode({ userTel: formRefs.value.formModel.userTel, verifyCodeType: 41 })
    if (codeData) {
        let time = 60
        showTime.value = true
        const times = setInterval(() => {
            time--
            verifyCodeTxt.value = time + t('userCenter.basicInpormation.afterMin')
            if (time === 1) {
                clearInterval(times)
                verifyCodeTxt.value = t('userCenter.basicInpormation.getverification')
                showTime.value = false
            }
        }, 1000)
    }



}


const onSubmit = () => {
    // formRef.value.formRef.resetFields()
    formRef.value.formRef.validate().then(async (data: any) => {
        console.log(formRef.value.formRef.validate)
        newPhone.value = data.userTel

        dialogFormNewPhone.value = true
        dialogFormChange.value = false
    })
}
// 修改手机号保存
// const { data: phoneInfo, loading, error: phoneError, run: runPhoneSave } = userTelModify()
const onSuccess = () => {
    console.log(formRefs.value.formRef.validate)
    formRefs.value.formRef.validate().then(async (data: any) => {
        console.log(validate)
        runPhoneSave({ ...data }).then(() => {
            if (phoneInfo.value.data.code === 0) {
                dialogFormEditPhone.value = false
                // run()
                message.success(t('userCenter.basicInpormation.operateSucess'))
            } else {
                message.error(phoneInfo.value.data.msg)
            }

            // router.push({ path: "/user_center/login" })

        })
    })
}

watch(testCodeRef.value, (newVal) => {
    console.log(newVal)
}, { deep: true })
</script>
<style lang="sass" scoped>
    :deep .btns
        display: none !important
    :deep .ant-input
        border-radius: 8px !important
    :deep #form_item_userTel
        border-top-left-radius: 0px !important
        border-bottom-left-radius: 0px !important
</style>