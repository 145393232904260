import Request from "@/common/request"
import refer from "./refer"
import serviceLog from "./serviceLog"

const common = {
    // /** 现金余额变更记录列表 */
    // cashRecordList: '/user/account/cash/recordList',
}

export default {
    api: common,
    ...refer,
    ...serviceLog,
    // cashRecordList: (params: any) => Request.post(common.cashRecordList, { ...params }),
}