<template lang="pug">
div.outer
    .cont
        w-form(
            ref="formRef" :key="timmer"
            :loading="loading" :formState="formState" :footer="null"
        )
</template>
<script setup lang="ts">
import Request from "@/common/request"
import API from "@/common/api/index"
import axios from "axios"
import { getCurrentUrl } from '@/common/constants/env'
import { Toast } from '@welink/design';
import { useI18n } from 'vue-i18n'
import { LOCALE, isForeign } from '@/common/constants/env'
const props = defineProps<{
    secoundId: null
}>();
const emit = defineEmits(['submitForm'])
const { t } = useI18n()
const { getFile } = API.workOrder
const fileList = ref<any>([])
const { getContact } = API.workOrder
const loading = ref(false)
const formRef = ref()
const timmer = ref<any>(null)
const typeCode = ref<any>()
const getFastList = () => {
    return (params: any) => Request.get(API.common.fastList, {}).then((data: any) => {
        return data
    })
}
const formState = ref<any>({
    urgencyLevel: {
        label: t('workOrder.submit.urgency'), value: '', allowClear: true, type: "select", rules: { required: t('common.not_empty') }, placeholder: t('workOrder.submit.import_s'), selectOption: {
            allowSearch: true,
            allowClear: true,
            selectedLabel: 'nameDisplay',
            options: [
            ],
            labelText: 'desc',
            valueText: 'code',
            api: {
                fn: getFastList(),
                immediately: true,

            }
        }

    },
    depict: { label: t('workOrder.submit.question'), value: '', type: 'textarea', rules: { required: t('common.not_empty') }, placeholder: t('workOrder.submit.import') },
    accessoryInfoList: {
        label: t('workOrder.submit.file'), value: '', type: 'upload', rules: { required: false }, uploadOption: {
            mode: 'card',
            title: t('workOrder.submit.upload'),
            listType: 'list',
            description: t('workOrder.submit.format'),
            onBeforeUpload(file: any) {
                console.log('file1: ', file);
                return false
            },
            onUpdate(data: any) {
                console.log('data', data)
                // Toast.error("格式不正确")
                fileList.value = data
            },
            async onUpload(file: any) {
                const type = file.name.slice(file.name.lastIndexOf('.') + 1)
                console.log(type)
                const typeList = ['png', "jpg", 'txt', "rar", "doc", "xls", "zip", "7z", "mp4"]
                console.log(typeList.indexOf(type))
                if (typeList.indexOf(type) > -1) {
                    console.log(true)
                    const isFile20M = file.size / 1024 / 1024 > 20
                    if (isFile20M) {
                        Toast.error(t('workOrder.submit.uploadSize'))
                    } else {
                        const fdParams = new FormData();
                        fdParams.append("file", file)
                        if (fileList.value.length < 5) {
                            await axios.post(`${getCurrentUrl().API_HOST}/user/common/file/upload`, fdParams, {
                                headers: { 'Content-Type': 'multipart/form-data', Authorization: localStorage.getItem("WL_SSO_TOKEN") },
                            }).then(response => {
                                console.log(response.data.data)
                                if (response.data.code === 0) {
                                    fileList.value.push({ suffix: response.data.data.suffix, name: response.data.data.filePath, url: response.data.data.filePath, val: response.data.data.filePath, typeCode: response.data.data.typeCode })
                                } else {
                                    Toast.error(response.data.msg)
                                }

                                console.log(fileList.value)
                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                            }).catch(() => { })
                            return new Promise((res) => {
                                res(fileList.value)
                            })
                        } else {
                            Toast.error(t('workOrder.submit.maxfive'))
                            return new Promise((res) => {
                                res(fileList.value)
                            })
                        }
                    }

                } else {
                    Toast.error(t('validate.formatError'))
                    return new Promise((res) => {
                        res(fileList.value)
                    })
                }

            }
        }
    },
    contactWay: {
        label: t('workOrder.submit.phone'), value: '', rules: {
            required: t('common.not_empty'), regex: isForeign ? {
                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/,
                message: t('validate.emailMsg')
            } : { pattern: /^1[3578]\d{9}$/, message: "联系方式格式不正确" }
        }, placeholder: isForeign ? t('workOrder.submit.importEmail') : "请输入手机号"
    }

})
defineExpose({ formRef, typeCode })
onMounted(async () => {
    try {
        loading.value = true
        await getContact({}).then((data: any) => {
            formState.value.contactWay.value = data.value
            typeCode.value = data.typeCode
            timmer.value = new Date().getTime()
        })
    } catch (e) {
        loading.value = false
    }
})


</script>

<style lang="sass" scoped>
.outer
    margin: 20px 0
    .cont
        width: 600px
    .btn
        margin-right: 20px
:deep .icon
    top: 12px !important
</style>