import Request from '@/common/request'
import Login from './login'
import activation from './activation'
import { getToken } from '@/utils/sso'
import info from "./info"
const common = {
    /** 获取验证码 */
    verifyCode: '/user/common/verifyCode',
    sendSmsCode: '/user/common/sendSmsCode',
    userTelModify: '/user/user/userTelModify',
    userDetail: '/user/user/detail',
    passwordModify: '/user/user/passwordModify/v1',
    abroadPasswordModify: '/user/user/abroadPasswordModify/v1',
    authDetail:"/user/enterpriseAuth/detail",
    addInfo: '/user/enterpriseAuth/addInfo',
    InfoDetail: '/user/enterpriseAuth/detail',
    InfoModify: '/user/enterpriseAuth/modify',
    getUserInfoByToken: '/user/feign/user/getInfoByToken'
}


export default {
    api: common,
    ...activation,
    ...Login,
    ...info,
    // 验证码场景：1注册 2登录 3发送短信验证码 4发送邮箱验证码
    getImgVerifyCodeInRegister: <R>() => {
        return Request.post<any, R>(common.verifyCode, { type: 1 },)
    },
    getImgVerifyCodeInLogin: <R>() => {
        return Request.post<any, R>(common.verifyCode, { type: 2 })
    },
    getImgVerifyCodeInSms: <R>() => {
        return Request.post<any, R>(common.verifyCode, { type: 3 })
    },
    getImgVerifyCodeInEmail: <R>() => {
        return Request.post<any, R>(common.verifyCode, { type: 4 })
    },
    getPhoneCode: <R>(params: any) => {
        return Request.post<any, R>('/user/common/sendSmsCodeIgnoreLogin', params,{canCustomHandle:true})
    },
    getUserInfoByToken: (token: string) => {
        return Request.post(common.getUserInfoByToken, { token: token || getToken() })
    }

}