<template lang="pug">
.console-main-process
    step(:current="0")
    .inner.pt-39
        step-back
        .flex.justify-center.items-start
            span.order 1.
            div
                .label.mb-3 {{ $t('v2.projectList.create') }}
                a-input.block(
                    size="large"
                    class="!w-160"
                    v-model:value="projectName"
                    :status="error ? 'error' : null"
                    :placeholder="$t('v2.projectList.createPlaceholder')"
                )
                .error.text-xs.pt-1(v-if="error" class="text-[#ff4d4f]") {{ $t('v2.projectList.createTip') }}
                .flex.justify-right.mt-7
                    a-button(type="primary" :disabled="!projectName.trim() || error" :loading="loading" @click="saveProject") {{ $t('v2.action.next') }}
</template>

<script lang="ts" setup>
import API from '@/common/api/console/v2/home'
import StepBack from '@/views/console/v2/components/stepBack.vue'
import { PROJECT_NAME_LENGTH } from '@/views/console/v2/constants/index'
import Step from './components/step.vue'

const projectName = ref('')
const loading = ref(false)
const error = ref(false)
const router = useRouter()


watch(projectName,(v) => {
    error.value = v.trim().length > PROJECT_NAME_LENGTH
})

const saveProject = async () => {
    loading.value = true
    try {
        const res: any = await API.createProject(projectName.value.trim())
        await API.setMainStep(1, { lastProjectId: res.id })
        loading.value = false
        router.push({ name: 'mainProcessStep02', params: { projectId: res.id }, query: { pname: projectName.value} })
    } catch(e) {
        loading.value = false
    }
}
</script>