
import console from './console';
import packages from './package';
import userCenter from './userCenter';
import countMange from './countMange';
import addCount from './countMange';
import open_prohibitCount from './countMange';
import resetPassword from './countMange'
import accountCost from "./accountCost"
import workOrder from "./workOrder"

const common = {
    /** 获取验证码 */
    verifyCode: '/user/common/verifyCode',
    industry: '/user/common/industry',
    chinaAreaList: '/user/common/chinaAreaList',
    fastList: '/user/support/manager/feedback/urgencyLevel',
    userInfoModify: '/user/user/userInfoModify',
    industryInfoModify: '/user/user/industryInfoModify',
}

export interface Pagination {
    /** 当前页码 */
    pageNo?: number;
    /** 每页大小 */
    pageSize?: number;
    /** 总页数 */
    total?: number;
}


export interface Response<T> {
    /** 状态码 */
    code: number;
    /** 返回数据 */
    data: T;
    /** 结果 */
    msg: string;
}

export interface HttpErrorType {
    error: string;
    message: string;
    path: string;
    status: string;
    timestamp: string;
}
export default {
    common,
    console,
    packages,
    userCenter,
    countMange,
    addCount,
    open_prohibitCount,
    resetPassword,
    accountCost,
    workOrder
};