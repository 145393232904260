<template lang="pug">
.flex.mb-4.justify-between.items-center
    h1.page-title.mb-0.pt-0 {{ $t('console.router.resourceList') }}
    .search.flex.gap-5.pt-1.pr-1
        a-select(
            v-model:value="searchForm.applicationId"
            allowClear
            show-search
            :options="projectList"
            :filter-option="filterOptions"
            :placeholder="$t('v2.resourceList.selectProject')"
            class="!w-40"
        )
        //- a-select(v-model:value="searchForm.packageId" allowClear :options="packageList" show-search :filter-option="filterOptions" :placeholder="$t('v2.resourceList.selectPackage')" class="!w-40")
        a-range-picker(
            v-model:value="searchForm.timeRange" allowClear :placeholder="[$t('v2.resourceList.startTime'), $t('v2.resourceList.endTime')]"
            :disabled-date="disabledDate"
        )
.bg-white.pb-5.pt-0.px-4.rounded-lg.min-h-full.common-console-list
    .left-top
    .right-top
    .left-bottom
    .right-bottom
    w-biz-table(
        :data="dataResult.records"
        :columns="columns"
        :loading="tableLoading"
        :pagination="{ current: dataResult.current, total: dataResult.total, pageSize: dataResult.size, size: 'small' }"
        @change="changeSize"
    )
        template(#emptyText)
            .py-10.txt-third-color {{ $t('v2.action.emptyData') }}
        template(#customColumn="{ record, column, text}")
            template(v-if="column.prop === 'status'")
                a-tag(:color="resourceStatusMap[text].color") {{ resourceStatusMap[text].text }}
            template(v-if="column.prop === 'action'")
                template(v-if="record.status === 3")
                    span.pri-color.cursor-pointer.mr-2(hover="underline" @click="addQuantityOpen(record)") {{ $t('v2.resourceList.setQuantity') }}
                template(v-if="![1,6].includes(record.status)")
                    span.pri-color.cursor-pointer.text-red-500(hover="underline" @click="onRelease(record.id)") {{ $t('v2.action.release') }}
    a-modal(
        v-model:open="addQuantityVisible" :width="432" centered wrapClassName="popup-edit-project" :title="$t('v2.resourceList.setQuantity')"
    )
        .flex.items-center.justify-center.pt-2.pb-1
            | {{ $t('v2.resourceList.quantity') }}：
            a-input-number(
                v-model:value="addQuantityNum"
                :controls="false" :min="1" :precision="0" autofocus
                :status="disabledScale ? 'error' : ''"
                maxlength="5"
            )
                template(#addonBefore)
                    span.action(:class="{ 'disabled' : addQuantityNum <= 1}" @click="addQuantityNum > 1 ? addQuantityNum-- : null") -
                template(#addonAfter)
                    span.action(@click="addQuantityNum++") +
        .text-red-500.text-xs.pt-1.pl-27.mb-3(v-if="disabledScale")
            | {{ $t('v2.mainProcess.s3.resource.quantityTip') }}: {{ currentResource.maxQuantityNum }}.

        template(#footer)
            .flex.justify-end
                a-button(@click="addQuantityVisible = false").mr-2 {{ $t('v2.action.cancel') }}
                a-button(type="primary" :loading="addQuantityLoading" :disabled="disabledScale" @click="addQuantityHandle") {{ $t('v2.action.confirm') }}
    a-modal(
        v-model:open="releaseVisible" :width="432" :closable="false" wrapClassName="popup-delete-project" centered
    )
        .flex.items-center.justify-center
            svg-icon(name="popup-trash" :size="80").block.mb-2
        div.text-lg.txt-color.text-center.mb-8 {{ $t('v2.projectList.deleteTitle') }}
        p.p-0.mb-8(class="text-[#86909C] text-sm px-10")
            | {{ $t('v2.projectList.deleteDesc') }}
            strong.txt-color {{ $t('v2.projectList.deleteDescPrefix') }}
        template(#footer)
            a-button(type="primary" @click="releaseVisible = false") {{ $t('v2.action.cancel') }}
            a-button(danger :loading="releaseLoading" @click="releaseHandleOk") {{ $t('v2.action.release') }}
</template>


<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import API from '@/common/api/console/v2/home'
import { resourceStatusMap } from '@/views/console/v2/constants/index'
import { Toast } from '@welink/design';
import { cloneDeep } from 'lodash-es'
import dayjs, { Dayjs } from 'dayjs';

const { t } = useI18n()

const tableLoading = ref(false)
const addQuantityVisible = ref(false)
const addQuantityNum = ref(1)
const currentResource = ref({ id: 0, nodeId: '', originQuantity: 0, quantity: 0, maxQuantityNum: 1 })
const addQuantityLoading = ref(false)
const releaseVisible = ref(false)
const releaseId = ref('')
const releaseLoading = ref(false)
const isMinScale = ref(false) // 缩容时候 不判断限额
const disabledScale = computed(() => !isMinScale.value && (addQuantityNum.value > currentResource.value.maxQuantityNum))

const searchForm = reactive<any>({
    timeRange: [],
    applicationId: null,
    packageId: null
})
const projectList = ref([])
const packageList = ref([])

const dataResult = ref({
    current: 1,
    total: 0,
    size: 10,
    records: []
})

const disabledDate = (current: Dayjs) => {
    return current && current > dayjs().endOf('day');
};

const columns = ref<any>([
    {
        title: t('console.resource.table.id'),
        prop: 'id',
        width: 250
    },
    {
        title: t('console.resource.table.applicationName'),
        prop: 'applicationName',
        width: 140
    },
    {
        title: t('v2.console.sidebar.resource'),
        prop: 'skuName',
        width: 200
    },
    {
        title: t('console.resource.table.skuName'),
        prop: 'specfication',
        width: 200
    },
    {
        title: t('console.resource.table.nodeName'),
        prop: 'nodeName',
        width: 200
    },
    {
        title: t('console.resource.table.cuuNumber'),
        prop: 'quantity',
        width: 200
    },
    {
        title: t('v2.resourceList.table.extensionAmount'),
        prop: 'extensionAmount',
        width: 200
    },
    {
        title: t('console.resource.table.status'),
        prop: 'status',
        slot: "status",
        width: 150
    },
    {
        title: t('v2.resourceList.table.createTime') + t('v2.common.gmt'),
        prop: 'createTime',
        width: 200
    },
    {
        title: t('v2.resourceList.table.updateTime') + t('v2.common.gmt'),
        prop: 'updateTime',
        width: 200
    },
    {
        title: t('common.action'),
        width: 200,
        fixed: 'right',
        prop: 'action'
    },
])

const fetchList = (params = {}) => {
    const current = dataResult.value.current
    const size = dataResult.value.size
    tableLoading.value = true
    API.getResourceList({ current, size, ...params}).then((data: any) => {
        dataResult.value = data
        tableLoading.value = false
    }).catch(() => {
        tableLoading.value = false
    })
}

const reFetchList = (form: any) => {
    const params = cloneDeep(form)
    params.current = 1
    if (params.timeRange && params.timeRange?.length) {
        params.startTime = dayjs(params.timeRange[0]).format('YYYY-MM-DD 00:00:00')
        params.endTime = dayjs(params.timeRange[1]).format('YYYY-MM-DD 23:59:59')
    }
    delete params.timeRange
    fetchList(params)
}

const changeSize = ({ current, pageSize}: any) => {
    fetchList({ current, size: pageSize })
}

const addQuantityOpen = (record: any) => {
    addQuantityNum.value = record.quantity
    addQuantityVisible.value = true
    API.getNodeQuota({ nodeId: record.nodeId }).then((data: any) => {
        record.maxQuantityNum = Math.floor(data.quantity + record.quantity)
        record.originQuantity = record.quantity
        currentResource.value = record
    })
}

const addQuantityHandle = () => {
    const { nodeId, quantity, id } = currentResource.value

    if (quantity === addQuantityNum.value) {
        addQuantityVisible.value = false
        return
    }

    let operationType = '';
    // 大于设置值是扩容，小于是缩容
    operationType = addQuantityNum.value > quantity ? 'K' : 'S'

    const params = {
        id,
        nodeId,
        operationType, // K,S
        extensionAmount: addQuantityNum.value
    }
    addQuantityLoading.value = true
    API.setResourceQuantity(params).then((data: any) => {
        addQuantityLoading.value = false
        addQuantityVisible.value = false
        fetchList(searchForm)
    }).catch(e => {
        addQuantityLoading.value = false
        addQuantityVisible.value = false
    })
}

const onRelease = (id: string) => {
    releaseVisible.value = true
    releaseId.value = id
}

const releaseHandleOk = () => {
    releaseLoading.value = true
    API.releaseResource(releaseId.value).then(res => {
        releaseLoading.value = false
        releaseVisible.value = false
        Toast.success(t('v2.common.actionSuccess'))
        fetchList(searchForm)
    }).catch(() => {
        releaseLoading.value = false
    })
}

watch(addQuantityNum, (newV, oldV) => {
    isMinScale.value = newV < currentResource.value.originQuantity
})

watch(searchForm, (v) => {
    reFetchList(v)
}, { deep: true })

watch(() => searchForm.projectId, (v: any) => {
    fetchGameData(v)
})

const fetchProjectData = async () => {
    const projectRes: any = await API.getProjectSelectList()
    projectList.value = projectRes.map((item: any) => ({ label: item.applicationName, value: item.id }))
}
const filterOptions = (input: string, option: any) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

const fetchGameData = async (id = '') => {
    const gameRes: any = await API.getGameList({ applicationId: id || searchForm.projectId })
    packageList.value = gameRes.map((item: any) => ({ label: item.packageName, value: item.id }))
}


onMounted(() => {
    fetchProjectData()
    fetchGameData()
    fetchList()
})
</script>