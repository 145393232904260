export function fullScreen(element: any) {
    const runfullScreen = element.requestFullscreen
        || element.mozRequestFullScreen
        || element.webkitRequestFullScreen
        || element.msRequestFullscreen;

    if (runfullScreen) runfullScreen.call(element);
    else {
        console.error('当前浏览器不支持部分全屏！');
    }
}

/**
    * 工具方法 - 获取视频宽高
    * 根据当前浏览器可视区宽高以及调度的游戏分辨率获取同比例合适的宽高
    * 并返回当前横竖屏状态
    * @returns { Object }
 */

export const getVideoSize = (gameContainer: HTMLElement) => {
    const { width: gameWidth, height: gameHeight } = gameContainer?.getBoundingClientRect() || {};
    // 根据调度的分辨率获取游戏的宽高
    const { width: clientWidth, height: clientHeight } = document.querySelector('body')?.getBoundingClientRect() || { width: 0, height: 0 };

    let width;
    let height;
    let rotate;

    const isPortraitScreenGame = gameWidth < gameHeight;

    if (isPortraitScreenGame) {
        // 如果是竖屏游戏
        if (clientWidth > clientHeight) {
            width = clientHeight;
            height = clientWidth;
            rotate = 1;
        } else {
            // 移动端竖屏转改下，需要 rotate
            width = clientWidth;
            height = clientHeight;
            rotate = 0;
        }
    } else {
        // 如果是横屏游戏
        if (clientWidth > clientHeight) {
            width = clientWidth;
            height = clientHeight;
            rotate = 0;
        } else {
            // 移动端竖屏转改下，需要 rotate
            width = clientHeight;
            height = clientWidth;
            rotate = 1;
        }
    }

    if (gameHeight / gameWidth > height / width) {
        // 现在的情况要降低宽度
        width += height / (gameHeight / gameWidth) - width;
    } else {
        // 现在的情况要降低高度
        height += (gameHeight / gameWidth) * width - height;
    }

    return {
        width: width,
        height: height,
        rotate,
    };
};