<template lang="pug">
.base_info.p-2
    a-form(ref="formRef" :model="form" :rules="rules")
        .base
            .header
                span {{ t("userInfo.Information") }}
            .content.flex.pt-3
                div.pr-2
                    svg-icon(name="user" size="36" class="")
                a-descriptions(title="")
                    a-descriptions-item
                        span.accountName {{ dataDetail?.username }}
                        svg-icon(name="copy", :size="12" class="hover:pri-color"
                        style="margin-top: 2px;" @click="copyUserName").ml-2.cursor-pointer.mr-2
                        span.accountType(v-if="dataDetail?.userType===0") {{ t("userInfo.masterAccount") }}
                        span.accountType(v-else) {{ t("userInfo.subAccount") }}
                    a-descriptions-item(:label="t('userInfo.Nickname')").flex.items-center.nickName.relative
                        span(v-if="!showNickName") {{ dataDetail?.nickName }}
                        a-form-item(v-if="showNickName" prop="nickName" name="")
                            a-input(
                                v-model:value.trim="form.nickName" class="!w-[160px] !h-[30px]" @focus="showData" @blur="hideData"
                                @input="inputBox"
                            )
                        div.absolute.left-30.top-12.border.p-2.bg-white.z-50(v-if="showValidate")
                            p.text-xs.m-0.mb-1
                            svg-icon(v-if="valLength===0" name="validate-true" size="12" class="-mt-0.5 !mr-1")
                            svg-icon(v-if="valLength===1" name="validate-error" size="12" class="-mt-0.5 !mr-1")
                            svg-icon(v-if="valLength===2" name="validate-normal" size="12" class="-mt-0.5 !mr-1")
                            span.text-xs  {{ t('userInfo.nickNameRule1') }}
                            p.m-0.mb-1
                            svg-icon(v-if="valFirst===0" name="validate-true" size="12" class="-mt-0.5 !mr-1")
                            svg-icon(v-if="valFirst===1" name="validate-error" size="12" class="-mt-0.5 !mr-1")
                            svg-icon(v-if="valFirst===2" name="validate-normal" size="12" class="-mt-0.5 !mr-1")
                            span.text-xs    {{ t('userInfo.nickNameRule2') }}
                            p.m-0.mb-1
                            svg-icon(v-if="valFormat===0" name="validate-true" size="12" class="-mt-0.5 !mr-1")
                            svg-icon(v-if="valFormat===1" name="validate-error" size="12" class="-mt-0.5 !mr-1")
                            svg-icon(v-if="valFormat===2" name="validate-normal" size="12" class="-mt-0.5 !mr-1")
                            span.text-xs {{ t('userInfo.nickNameRule3') }}
                        span(v-if="showNickName" type="link" @click="saveNickName").text-xs.pri-color.ml-2.mr-2.cursor-pointer {{ t('common.confirm') }}
                        span.mr-1.pri-color.cursor-pointer.text-xs(v-if="showNickName" type="link" @click="hiddenNickName") {{ t('common.cancel') }}

                        svg-icon(v-if="!showNickName" name="edit", :size="12" class="hover:pri-color"
                @click="showNickName=!showNickName").ml-2.cursor-pointer
                    //- a-descriptions-item(label="认证状态") {{ dataDetail?.authStatusDesc }}
                    a-descriptions-item(:label="t('userInfo.AccountID')")
                        span {{ dataDetail?.id }}
                            svg-icon(name="copy", :size="12" class="hover:pri-color !-mt-1"
                            style="margin-top: 2px;" @click="copyUserId").ml-2.cursor-pointer

                    a-descriptions-item(v-if="!isForeign" :label="t('userInfo.BusinessIndustry')").flex.items-center
                        span(v-if="!showindustry") {{ dataDetail?.industryInfoDesc?dataDetail?.industryInfoDesc:'--' }}
                        svg-icon(v-if="!showindustry" name="edit", :size="12" class="hover:pri-color"
                        @click="showindustry=!showindustry").ml-2.cursor-pointer
                        a-select(v-if="showindustry" v-model:value.trim="form.industryInfo" placeholder="" size="mini"
class="!w-[160px] !h-[30px]" )
                            a-select-option(v-for="item in industryList" :key="item.code" :value="item.code" :lable="item.name"
class="!w-[160px] !h-[30px]" ) {{ item.name }}
                        span(v-if="showindustry" type="link" @click="changeIndustryInfo").ml-2.mr-4.pri-color.cursor-pointer {{ t('common.confirm') }}
                        span(v-if="showindustry" type="link" @click="hiddenindustryInfo").pri-color.ml-2.mr-2.cursor-pointer {{ t('common.cancel') }}
                    //- a-descriptions-item(label="注册来源")
                    //-     span {{ dataDetail?.registerTypeDesc }}
                    a-descriptions-item(:label="t('userInfo.lastLoginTime')")
                        span {{ dataDetail?.lastLoginTime }}
                    a-descriptions-item(:label="t('userInfo.registerTime')" :span="3")
                        span {{ dataDetail?.registerTime }}
                    a-descriptions-item(label="" :span="3")
                        span(v-if="dataDetail?.authStatus===0").authStatusError
                            svg-icon(name="error-red" size="10" class="-mt-0.5 !mr-1")
                            | {{ t('userInfo.NotVerified') }}
                        span(v-if="dataDetail?.authStatus===1").authStatusSuccess
                            svg-icon(name="success" size="10" class="-mt-0.5 !mr-1")
                            | {{ t('userInfo.Verified') }}
                        span(v-if="dataDetail?.authStatus===2").authStatusSuccess
                            svg-icon(name="warn" size="10" class="-mt-0.5 !mr-1")
                            | 认证中
                        router-link(v-if="dataDetail?.authStatus===0 && !isForeign" to="/certification").ml-2 去认证
                        router-link(v-if="dataDetail?.authStatus===2 && !isForeign" to="/user_center/authentication").ml-2 查看
        .secure.mt-4.flex.justify-around
            .left.w-full
                .header
                    span {{ t('userInfo.AccountSecurity') }}
                .content-item.pr-5.flex.item-center.mt-6(v-if="!isForeign")
                    div.mr-3
                        svg-icon(name="phone" size="48")
                    div.flex-1.mr-3
                        .top
                            span.title 安全手机
                            span.success.ml-3.text-xs(v-if="dataDetail?.userTel")
                                svg-icon(name="success" size="14" class="-mt-0.5 !mr-1")
                                |已绑定
                            span.warn.ml-3.text-xs(v-else)
                                svg-icon(name="warn" size="14" class="-mt-0.5 !mr-1")
                                |未绑定
                        .bottom.mt-1
                            span.text-xs.desc {{ isForeign?"":'+86' }} {{ dataDetail?.userTel }} 用于接收消息、验证身份 (如找回密码、变更安全设置等)，绑定后可支持手机验证码登录
                    div
                        // eslint-disable-next-line vue/v-on-event-hyphenation
                        bindPhone(v-if="!dataDetail?.userTel" @getUserDetail="getDataInfo")
                        // eslint-disable-next-line vue/v-on-event-hyphenation
                        editPhone(v-else :Tel="dataDetail?.userTel" :type="0" @getUserDetail="getDataInfo")
                .content-item.pr-5.flex.item-center.mt-6
                    div.mr-3
                        svg-icon(name="email" size="48")
                    div.flex-1.mr-3
                        .top
                            span.title {{ t('userInfo.email') }}
                            span.success.ml-3.text-xs(v-if="dataDetail?.userEmail")
                                svg-icon(name="success" size="14" class="-mt-0.5 !mr-1")
                                | {{ t('userInfo.Bound') }}
                            span.warn.ml-3.text-xs(v-else)
                                svg-icon(name="warn" size="14" class="-mt-0.5 !mr-1")
                                | {{ t('userInfo.Unbound') }}
                        .bottom.mt-2
                            span.text-xs.desc {{dataDetail?.userEmail}} 
                    div
                        // eslint-disable-next-line vue/v-on-event-hyphenation
                        bindEmail(v-if="!dataDetail?.userEmail&&!isForeign" @getUserDetail="getDataInfo" )
                        // eslint-disable-next-line vue/v-on-event-hyphenation
                        editEmail(v-if="!isForeign&&dataDetail?.userEmail" @getUserDetail="getDataInfo")
                .content-item.pr-5.flex.item-center.mt-6.mb-8
                    div.mr-3
                        svg-icon(name="password" size="48")
                    div.flex-1.mr-3
                        .top
                            span.title {{ t("userInfo.LoginPassword") }}
                        .bottom.mt-2
                            //- span.text-xs.desc {{ t("userInfo.passwordText") }}
                    div
                        //- a-button(type="default" size="small").px-4 修改
                        // eslint-disable-next-line vue/v-on-event-hyphenation
                        editPassword(@getUserDetail="getDataInfo" )
        .secure.mt-4.flex.justify-around
            .right.w-full(v-if="getUserInfo('userType') === 0")
                .header
                    span {{ t('userInfo.sessionTitle') }}
                .content-item.pr-5.flex.item-center.mt-6
                    div.mr-3
                        svg-icon(name="sessionTime" size="48")
                    div.flex-1.mr-3
                        .top
                            span.title {{ t('userInfo.sessionTitle2') }}
                        .bottom.mt-1.max-w-2xl
                            span.text-xs.desc {{ t('userInfo.sessionText') }}
                    div
                        // eslint-disable-next-line vue/v-on-event-hyphenation
                        sessionTime(:key="dataDetail?.tokenSurvival" :tokenSurvival="dataDetail?.tokenSurvival" @getUserDetail="getDataInfo")
</template>

<script lang="ts" setup>
import { getUserInfo } from '@/utils/sso'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import { authDetail } from "@/service/userCenter/authentication"
import { industryInfoModify, userDetail, passwordModify, userInfoModify, abroadPasswordModify } from "@/service/userCenter/userCenter"
import { message } from '@welink/design-v2';
import editPassword from "./edit-password.vue"
import editPhone from "./edit-phone.vue"
import bindPhone from "./bind-phone.vue"
import editEmail from "./edit-email.vue"
import bindEmail from "./bind-email.vue"
import sessionTime from "./session-time.vue"
import Request from "@/common/request"
import API from "@/common/api"
import Detail from '../authentication/detail.vue';
import { LOCALE, isForeign } from '@/common/constants/env'
const formRef = ref()
const showValidate = ref(false)
const userName = getUserInfo('username')
const showNickName = ref(false)
const showindustry = ref(false)
const valFirst = ref(2)
const valLength = ref(2)
const valFormat = ref(2)
const { data: dataDetail, loading, run } = userDetail({ username: userName })
const { data:authDetailData, run:authDetailRun } = authDetail({})
const industryList = ref<any>([])
const form = ref({
    nickName: dataDetail.nickName,
    industryInfo: dataDetail.industryInfo
})
const rules = ref({
    nickName: [{ pattern: new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9\.\@\_\[\]\、\-\:\(\)]{3,29}$/, "g"), message: "4-30位，支持英文字母、数字、符号@、._[]-:(),首字母不为符号" }]
})

const getIndustryList = () => {
    Request.post(API.common.industry, {}).then((data: any) => {
        console.log(data)
        industryList.value = data
    })
}
const hiddenNickName = () => {
    showNickName.value = !showNickName.value
    form.value.nickName = dataDetail.value.nickName
}

const hiddenindustryInfo = () => {
    form.value.industryInfo = dataDetail.value.industryInfo
    showindustry.value = !showindustry.value

}
const inputBox = () => {
    const reg = /^([a-zA-Z0-9])/
    const regFormat = /^[a-zA-Z0-9\.\@\_\、\[\]\-\:\(\)]{3,29}$/
    if(form.value.nickName.length >= 4 && form.value.nickName.length <= 30) {
        valLength.value = 0
    }else{
        valLength.value = 1
    }
    if(reg.test(form.value.nickName)){
        valFirst.value = 0
    }else{
        valFirst.value = 1
    }

    if(regFormat.test(form.value.nickName)){
        valFormat.value = 0
    }else{
        valFormat.value = 1
    }
}
const showData = () => {
    console.log("focus")
    inputBox()
    showValidate.value = true

}
const hideData = () => {
    showValidate.value = false
}
const { data: dataNickName, loading: saveName, run: runName } = userInfoModify({ nickName: form.value.nickName })
const { data: dataInfoModify, loading: saveInfoModify, run: runInfoModify } = industryInfoModify({ ndustryInfo: dataDetail.industryInfo })
const getDataInfo = async () => {
    await run().then(res => {
        form.value.nickName = dataDetail.value.nickName
        form.value.industryInfo = dataDetail.value.industryInfo
    })
}
const saveNickName = () => {
    console.log(formRef)
    formRef.value.validate().then((data: any) => {
        console.log(data)
        runName({ nickName: form.value.nickName }).then((res: any) => {
            console.log(dataNickName.value.data)
            if (dataNickName.value.data.code === 0) {
                message.success(t("common.operateSuccess"))
                showNickName.value = false
                getDataInfo()
            } else {
                message.error(dataNickName.value.data.msg)
                getDataInfo()
            }
        })
    })

}
const changeIndustryInfo = () => {
    runInfoModify({ industryInfo: form.value.industryInfo }).then((res: any) => {
        console.log(res)
        if (dataInfoModify.value.data.code === 0) {
            message.success(t("common.operateSuccess"))
            showindustry.value = false
            getDataInfo()
        } else {
            message.error(dataInfoModify.value.data.msg)
            getDataInfo()
        }
    })
}
const copyUserName = () => {
    const a = document.createElement("input")
    const values = dataDetail.value.username

    a.value = JSON.stringify(values)
    document.body.appendChild(a);
    a.select(); //
    document.execCommand("copy");
    // message.success("复制成功")
    message.success(t('userCenter.accountManage.copySucess'))
    // 复制成功后再将构造的标签 移除
    document.body.removeChild(a);
}
const copyUserId = () => {
    const a = document.createElement("input")
    const values = dataDetail.value.id
    a.value = JSON.stringify(values)
    document.body.appendChild(a);
    a.select(); //
    document.execCommand("copy");
    // message.success("复制成功")
    message.success(t('userCenter.accountManage.copySucess'))
    // 复制成功后再将构造的标签 移除
    document.body.removeChild(a);
}
onMounted(async () => {
    getDataInfo()
    await getIndustryList()
    await authDetailRun()
})
</script>
<style lang="sass">

.base_info
    .nickName
        .ant-form-item
            margin: 0 !important
    .a-descriptions-item
        width: 350px !important
    .ant-descriptions-item-container
        display: flex
        align-items: center
    .ant-descriptions-item-content
        display: flex !important
        align-items: center !important
    .ant-descriptions-item-label
        // width: 100px !important
        color: #111928 !important
        // margin-right: 80px !important
        font-size: 14px !important
    .ant-descriptions-item-content
        height: 28px
        color: #6B7280 !important
        font-size: 14px !important
        .ant-form-item-explain-error
            display: none
        // .ant-select-selector
        //     height: 30px !important
        //     line-height: 30px
        // .ant-select-selection-item
        //     height: 30px !important
        //     line-height: 30px !important
        //     color: rgba(0, 0, 0, 0.88) !important
</style>
<style lang="sass" scoped>
.accountName
    font-size: 18px
    // line-height: 25px
.accountType
    // width: 56px
    padding-left: 10px
    padding-right: 10px
    height: 20px
    background: rgba(255,255,255,0.1)
    border-radius: 10px
    border: 1px solid #1C64F2
    color: #1C64F2
    text-align: center
    font-size: 12px
.content-item
    .nickName
        .ant-form-item
            margin: 0 !important

    display: flex
    align-items: center
    :deep .ant-btn
        padding-left: 12px
        padding-right: 12px
    .desc
        color:#86909C
    .success
        color: rgba(18, 184, 98, 1)
    .warn
        color: rgba(250, 150, 0, 1)

    .title
        font-weight: 500
        color: #111928
.authStatusError
    background: #FDE8E8
    border-radius: 4px
    border: 1px solid #E02424
    color:#E02424
    padding: 2px
    padding-left: 4px
    padding-right: 4px
    font-size: 12px
.authStatusSuccess
    background: #F9FAFB
    border-radius: 4px
    border: 1px solid #E5E7EB
    padding: 2px
    padding-left: 4px
    padding-right: 4px
    font-size: 12px
</style>

