<template lang="pug">
.modify-panel(class="w-3/4 relative left-1/2 -translate-x-1/2 mt-4 py-4")
    router-link(v-if="!showSuccess" class="absolute left-6 top-8 text-xs text-blue-500" :to="{name: 'userCenterLogin'}") {{ $t('common.back') }}
    h1(v-if="!showSuccess").h1.title.m-auto.mb-6.text-center.register-title.text-xl.font-medium.pt-12(class="max-sm:text-lg max-sm:font-semibold") {{ $t('userCenter.resetPassword.title') }}
    div(v-if="!showSuccess").text-xs.block.m-auto.mb-5.text-center.register-title.text-gray-600
        router-link(:to="{ name: 'userCenteModifyPwd'}")
        span
            | {{ $t('userCenter.login.already') }}
            router-link.text-blue-500(:to="{ name: 'userCenterLogin'}") {{ $t('userCenter.login.go') }}
    .form(v-if="!showSuccess")
        w-biz-form(
            ref="form" :formState="formState" hide-labels
            :footer="{okTxt:$t('common.submit'),cancelTxt:$t('common.reset'), cls: 'flex justify-center'}"
            :submitText="$t('common.submit')"
            hideReset
            @on-submit="onSubmit")
            template(#appendField="{ filedName }")
                template(v-if="filedName === 'imgVerifyCode'")
                    img.imgCode.absolute.right-0.p-1.top-0.cursor-pointer(:src="imgVerifyCode.base64Image" class="rounded" @click="getImgCode").py-1.h-full.mr-1
                template(v-if="filedName === 'verifyCode'")
                    label(for="form_item_smsCode")
                        span.getCode.absolute.right-0.top-0.rounded-lg(:class="{'disabled !cursor-not-allowed': disabledSms }" @click="getVerifyCode") {{ verifyCodeTxt }}
    div.outer(v-if="showSuccess")
        svg-icon(name="stepSuccess" width="64" height="64")
        h2 密码重置成功，请重新登录
        w-button.mt-2(@click="toLogin") {{ $t('workOrder.submit.sure') }}
</template>


<script lang="ts" setup>
import API from '@/common/api'
import { isForeign } from '@/common/constants/env';
import { router } from '@/routers';
import { Toast } from '@welink/design';
import { useI18n } from 'vue-i18n'
import { FormConst, validatePwd, getVerifyCodeMsg, getVerifyCodeIng } from '../native/form'
import { cloneDeep } from 'lodash-es'
import { regEmail, regPhone, regUserName } from "@/utils/validate"
import { message } from '@welink/design-v2';

const { userEmail, verifyCodeEmail, username, password, confirmPassword, imgVerifyCode: imgVerifyCodeForm, userTel, smsCode } = cloneDeep(FormConst)

const { t } = useI18n()
const showSuccess = ref(false)
const form = ref()
const route = useRoute()
const verifyCodeTxt = ref(t('userCenter.getImgCode'))
const showTime = ref(false)
const timer = ref<any>(null)
const imgVerifyCode = ref<any>({
    base64Image: '',
    code: null,
    expiresAt: '',
    width: '',
    height: '',
    verifyCodeKey: ''
})
confirmPassword.rules.push({
    validator: async (_rule: any, value: string) => {
        console.log('value: ', value, formState.password.value)
        return validatePwd(value, formState.password.value);
    }
} as any)
const formState = reactive<any>({
    username,

})

if (isForeign) {
    // userEmail['disabled'] = true
    formState.userEmail = userEmail
    formState.userEmail.disabled = true
    formState.password = password
    formState.confirmPassword = confirmPassword
    formState.imgVerifyCode = { ...imgVerifyCodeForm }

    formState.verifyCode = verifyCodeEmail
} else {
    formState.userTel = userTel
    formState.userTel.disabled = true
    formState.userTel.rules = true
    formState.password = password
    formState.confirmPassword = confirmPassword
    formState.imgVerifyCode = { ...imgVerifyCodeForm }
    formState.verifyCode = { ...smsCode }
}
const isFetchSms = ref(false)
const disabledSms = computed(() => (
    isForeign ? !(formState?.imgVerifyCode?.value.length === 4 && formState?.userEmail.value) || isFetchSms.value || showTime.value : !(formState?.imgVerifyCode?.value.length === 4 && formState?.userTel.value) || isFetchSms.value || showTime.value
))

const getImgCode = async () => {
    const res: any = await API.userCenter.getImgVerifyCodeInEmail()
    imgVerifyCode.value = res
}

const getVerifyCode = async (e: any) => {
    if (disabledSms.value) { return }
    isFetchSms.value = true
    verifyCodeTxt.value = getVerifyCodeIng
    if (isForeign) {
        const res: any = await API.userCenter.sendEmailCode({
            imageVerifyCode: form.value.formModel.imgVerifyCode,
            imageVerifyCodeKey: imgVerifyCode.value.verifyCodeKey,
            userEmail: form.value.formModel.userEmail,
            verifyCodeType: 5,
        })
        console.log(res.data)
        if (res.data.code === 0) {
            let time = 60
            showTime.value = true
            const times = setInterval(() => {
                time--
                verifyCodeTxt.value = time + t('userCenter.resetPassword.regetCode')
                if (time === 1) {
                    clearInterval(times)
                    verifyCodeTxt.value = t('userCenter.getImgCode')
                    showTime.value = false
                    console.log(!(formState?.imgVerifyCode?.value.length === 4 && regEmail.pattern.test(formState?.userEmail.value)), isFetchSms.value, showTime.value)
                }

            }, 1000)
        } else {
            Toast.error(res.data.msg)
            getImgCode()
            verifyCodeTxt.value = getVerifyCodeMsg
        }
        isFetchSms.value = false
        // }
    } else {
        const res: any = await API.userCenter.sendSmsCodeIgnoreLoginByUsername({
            imageVerifyCode: form.value.formModel.imgVerifyCode,
            imageVerifyCodeKey: imgVerifyCode.value.verifyCodeKey,
            username: form.value.formModel.username,
            verifyCodeType: 5,
        })
        if (res.data.code === 0) {
            let time = 60
            showTime.value = true
            const times = setInterval(() => {
                time--
                verifyCodeTxt.value = time + t('userCenter.resetPassword.regetCode')
                if (time === 1) {
                    clearInterval(times)
                    verifyCodeTxt.value = t('userCenter.getImgCode')
                    showTime.value = false
                }
            }, 1000)
        } else {
            getImgCode()
            Toast.error(res.data.msg)
            verifyCodeTxt.value = getVerifyCodeMsg
        }
        isFetchSms.value = false
    }
}

const onSubmit = async (values: any) => {
    const res: any = await API.userCenter.modifyPwd(values)
    if (res.data.code === 0) {
        Toast.success(t('common.action') + t('common.success'))
        showSuccess.value = true
        
    } else {
        getImgCode()
        Toast.error(res.data.msg)
    }
}
const toLogin = async (values: any) => {
    router.push({ name: 'userCenterLogin' })
}
onMounted(() => {
    getImgCode()
    if (route.query.username) {
        formState.username.value = route.query.username
        timer.value = new Date().getTime()
    }
    const userName = document.getElementById("form_item_username")
    console.log(userName)
    userName?.addEventListener("blur", async () => {
        if (!isForeign) {
            if (regUserName.pattern.test(formState.username.value)) {
                const res:any = await API.userCenter.verifyCodeReceiveMode({ username: formState.username.value })
                console.log(res)
                if (res.data.code === 0) {
                    console.log(form.value.formModel)
                    form.value.formModel.userTel = res.data.data
                }else{
                    message.warn(res.data.msg)
                    form.value.formModel.userTel = ''
                }

            }

        }
    })
})
</script>

<style scoped lang="sass">
.form
    width: 360px
    margin: 0 auto
    :deep .wl-form_field
        position: relative
:deep #form_item_userTel
    border-top-left-radius: 0px !important
    border-bottom-left-radius: 0px !important
:deep .btns
    .ant-btn
        width: 100%
        border-radius: 8px
:deep .ant-input
    border-radius: 8px !important
:deep .ant-input-password
    border-radius: 8px !important
:deep #form_item_verifyCode
    width: 244px
:deep .btns
    .ant-btn
        width: 100%
        border-radius: 8px
.outer
    text-align: center
    margin-top: 200px
    h2
        font-size: 22px
        font-weight: 500
        line-height: 80px
</style>