<!-- eslint-disable no-useless-concat -->
<template lang="pug">
.bill-detail.p-5
    .action.w-full.justify-end.flex 
        w-button.mr-2.px-2(v-if="accountInfo?.billStatus===2" size="xs" @click="repaymentFn(accountInfo)") {{ $t("cost.billList.repayment") }}
        w-button.mr-2(v-if="accountInfo?.billStatus===1" size="xs" @click="confirmBox(accountInfo)") {{ $t("cost.billList.ConfirmBilling") }}
        w-button(type="default" @click="exportFn")
            svg-icon(name="stretching" :size="12" class="-mt-0.5").mr-1
            | {{ $t('cost.billDetail.export') }}
    .user-info.w-full.h-full.border.bg-white.p-4.mt-4.rounded-lg
        span.text-lg {{ $t('cost.billDetail.BasicInfo') }} 
            span ( {{ accountInfo?.billStatusDesc }} )
        .content.flex.justify-around.mt-5.text-sm.pl-2
            .item-info.w-full.text-sm
                label.text-slate-400.mr-1 {{ $t('cost.billDetail.bill') }}：
                span.text-slate-400 {{ accountInfo?.parentUsername }}
            .item-info.w-full.text-sm
                label.text-slate-400.mr-1 {{ $t('cost.billDetail.currency') }}：
                span {{ accountInfo?.currency }}
            .item-info.w-full.text-sm
                label.text-slate-400.mr-1 {{ $t('cost.billDetail.username') }}：
                span {{ accountInfo?.authInfo }}
        .content.flex.justify-around.mt-4.text-sm.pl-2
            .item-info.w-full.text-sm
                label.text-slate-400.mr-1 {{ $t('cost.billDetail.billDate') }}：
                span.text-slate-400 {{ accountInfo?.periodStr }}
            .item-info.w-full.text-sm
                label.text-slate-400.mr-1 {{ $t('cost.billDetail.statementDate') }}：
                span {{ accountInfo?.effectDate }}
            .item-info.w-full.text-sm
                label.text-slate-400.mr-1 {{ $t('cost.billDetail.PaymentDueDay') }}：
                span {{ accountInfo?.repayDate }}
    .user-info.w-full.h-full.border.bg-white.p-4.mt-4.rounded-lg.pb-20
        span.text-lg {{ $t('cost.billDetail.billTableTitle') }}： 
        .content.mt-5.text-sm.pl-2
            w-table.mt-2(
                :dataSource="accountInfo?.collectList"
                :loading="loading"
                :columns="columns"
            )
    .user-info.w-full.h-full.border.bg-white.p-4.mt-4.rounded-lg.pb-20
        span.text-lg {{ $t('cost.billDetail.productTableTitle') }}： 
        .content.mt-5.text-sm.pl-2
            w-table.mt-2(
                :dataSource="accountInfo?.productList"
                :loading="loadingProduct"
                :columns="columnsProduct"
            )
    .user-info.w-full.h-full.border.bg-white.p-4.mt-4.rounded-lg.pb-20
        span.text-lg {{ $t('cost.billDetail.otherTableTitle') }}： 
        .content.mt-5.text-sm.pl-2
            w-table.mt-2(
                :dataSource="accountInfo?.otherList"
                :loading="loadingProduct"
                :columns="columnsOther"
            )
w-drawer(
    :title="$t('cost.billList.repaymentTitle')" :visible="drawer" direction="rtl" @close="onCancel"
)
    div.drawer-content.p-4.leading-6
        p
            span(class="w-[120px]") {{ $t('cost.billList.billDate') }} ：
            span.text-sm {{ userPayInfo?.periodStr }}
        p.mt-2
            span.w-24 {{ $t('cost.billList.arrearsAmount') }} ：
            span.text-sm {{ VITE_UNIT }} {{ userPayInfo?.debtFee }}
        p.mt-2
            span.w-24.mt-2 {{ $t('cost.billList.payType') }} ：

            p.mt-2(v-if="userPayInfo?.payFlag===1||userPayInfo?.payFlag===2||userPayInfo?.payFlag===0").pl-8
                span.mr-2.mt-1 {{ $t('cost.billList.Trial') }}  ( {{ VITE_UNIT }}{{ userPayInfo?.trialBalance }})
                // eslint-disable-next-line vue/no-parsing-error
                p.text-slate-500 {{ t("cost.billList.pay") }}：{{VITE_UNIT}} {{ userPayInfo?.trialPayAmount }} 
            p.mt-2(v-if="userPayInfo?.payFlag===2||userPayInfo?.payFlag===0").pl-8
                span {{ t("cost.billList.cashe") }}  ( {{ VITE_UNIT }}{{ userPayInfo?.cashBalance }} )
                // eslint-disable-next-line vue/no-parsing-error
                p.text-slate-500 {{ t("cost.billList.pay") }}： {{VITE_UNIT}} {{ userPayInfo?.cashPayAmount }} 
    p(v-if="userPayInfo?.payFlag===0").pl-8.text-xs.text-slate-400
        p {{ $t('cost.billList.noAmountDesc') }}
            span.text-blue-500.ml-1.cursor-pointer(@click="openUrl('Recharge')")  {{ $t('cost.billList.noAmountUrl') }}
            span.ml-1.mr-1 {{ $t('cost.billList.or') }}
            // eslint-disable-next-line vue/no-parsing-error
            span.text-blue-500.ml-1.cursor-pointer(@click="openUrl('preFund')")  {{ $t('cost.billList.noAmountUrl2') }}
    template(#footer)
        w-button(v-if="userPayInfo?.payFlag!==0" :loading="loading" @click="onSubmit").mr-4 {{ $t("common.payBtn") }}
        w-button(type="default" @click="onCancel") {{ $t("common.cancel") }}
</template>
<script lang="ts" setup>

import API from "@/common/api"
const { billDetail, billDetailDownload, payment, payInfo, billConfirm } = API.accountCost
import { useI18n } from 'vue-i18n'
import { Confirm, Toast } from '@welink/design';
import { VITE_UNIT } from "@/common/constants/env"
const accountInfo = ref<any>()
const drawer = ref(false)
const { t } = useI18n()
const route = useRoute()
const loading = ref(false)
const loadingProduct = ref(false)
const userPayInfo = ref<any>({})
const router = useRouter()
const exportFn = async () => {
    const data: any = await billDetailDownload({ id: route.params.id })
    console.log(data)
    const blob = new Blob([data.data as any])
    const url = window.URL.createObjectURL(blob)
    console.log(url)
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', accountInfo.value.periodStr + `${t("cost.billDetail.billTableTitle")}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
}
const repaymentFn = async (row: any) => {
    const data: any = await payInfo({ id: row.id })
    console.log(data)
    if (data.data.code === 0) {
        userPayInfo.value = data.data.data
        drawer.value = true
    } else {
        Toast.error(data.data.msg)
    }

}
const onSubmit = async () => {
    // drawer.value = false
    const data: any = await payment({ billMainId: userPayInfo.value.billMainId })
    console.log(data)
    if (data.data.code === 0) {
        Toast.success(t('common.operateSuccess'))
        drawer.value = false
        getDetail()
    } else {
        Toast.error(data.data.msg)
    }
}
const confirmBox = (row: any) => {
    Confirm({
        title: t('cost.billList.ConfirmDesc'),
        okTxt: t('common.confirm'),
        cancelTxt: t('common.cancel'),
        async onOk() {
            const data: any = await billConfirm({ id: row.id })
            if (data.data.code === 0) {
                Toast.success(t('common.operateSuccess'))
                getDetail()
            } else {
                Toast.error(data.data.msg)
            }
        },
    })
}
const onCancel = () => {
    drawer.value = false
}
const openUrl = (name: any) => {
    router.push({ name: name })
}
// nextTick(() => {
//     getDetail()
// })
const getDetail = async() => {
    const data = await billDetail({ id: route.params.id })
    console.log(data,111)
    accountInfo.value = data

}
onMounted(async () => {
    const data = await billDetail({ id: route.params.id })
    console.log(data)
    accountInfo.value = data
    console.log(accountInfo.value?.currency,"accountInfo.value?.currency")
    // columns.value.map((item: { title: any; }) => { item.title =  item.title + accountInfo.value?.currency})
    // eslint-disable-next-line no-useless-concat
    const symbolBill =  "(" + `${accountInfo.value?.currency === "CNY" ? "￥" : "$"}` + ")"
    columns.value[0].title = t("cost.billDetail.columns.original") + symbolBill
    columns.value[1].title = t("cost.billDetail.columns.meet") + symbolBill
    columns.value[2].title = t("cost.billDetail.columns.arrears") + symbolBill
    columnsProduct.value[1].title = t("cost.billDetail.productColumns.original") + symbolBill
    columnsProduct.value[2].title = t("cost.billDetail.productColumns.meet") + symbolBill
    columnsProduct.value[3].title =  t("cost.billDetail.productColumns.arrears") + symbolBill
    columnsOther.value[4].title = t("cost.billDetail.columnsOther.meet") + symbolBill
    columnsOther.value[5].title = t("cost.billDetail.columnsOther.arrears") + symbolBill

})

const columns = ref<any>([
    {
        title: t("cost.billDetail.columns.original"),
        prop: 'totalFee',
    },
    {
        title:  t("cost.billDetail.columns.meet") ,
        prop: 'payFee',
    },
    {
        title: t("cost.billDetail.columns.arrears") ,
        prop: 'debtFee',
    },

])

const columnsProduct = ref<any>([
    {
        title: t("cost.billDetail.productColumns.prodcutName"),
        prop: 'feeName',
    },
    {
        title: t("cost.billDetail.productColumns.original"),
        prop: 'totalFee',
    },
    {
        title: t("cost.billDetail.productColumns.meet") ,
        prop: 'payFee',
    },
    {
        title: t("cost.billDetail.productColumns.arrears"),
        prop: 'debtFee',
    },
])
const columnsOther = ref<any>([
    {
        title: t("cost.billDetail.columnsOther.name"),
        prop: 'feeName',
    },
    {
        title: t("cost.billDetail.columnsOther.rule"),
        prop: 'ruleText',
    },
    {
        title: t("cost.billDetail.columnsOther.number"),
        prop: 'count',
    },
    {
        title: t("cost.billDetail.columnsOther.unit"),
        prop: 'unit',
    },
    {
        title: t("cost.billDetail.columnsOther.meet") ,
        prop: 'totalFee',
    },
    {
        title: t("cost.billDetail.columnsOther.arrears") ,
        prop: 'debtFee',
    },
])


</script>
<style lang="sass">
.user-info
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.08)
</style>