import { createI18n } from "vue-i18n"
import { getLocale, setLocale } from '@/utils/sso'
import { LOCALE, isForeign } from '@/common/constants/env'
import { getQueryString } from '@/utils/getQueryString'
import zh from "./assets/i18n/zh.json"
import en from "./assets/i18n/en.json"
import ja from "./assets/i18n/ja.json"

import enV2 from './assets/i18n_v2/en.json'
import jaV2 from './assets/i18n_v2/ja.json'

// import ko from "./assets/i18n/en.json"

console.log('当前环境： ', isForeign ? '国外' : '国内', getLocale());

const messages: any = {
    zh,
    en: {
        ...en,
        ...enV2,
    },
    ja: {
        ...ja,
        ...jaV2
    }
}

// 从官网过来，默认继承语言
const urlLang: any = getQueryString('lang')
if (urlLang && getQueryString('wl_sso_token')) {
    if (messages[urlLang]) {
        setLocale(urlLang)
    } else {
        setLocale('en')
    }
}

const locale = isForeign ? getLocale() || LOCALE || 'en' : 'en';

document.querySelector('html')?.setAttribute('lang', locale)

export const i18n = createI18n({
    globalInjection: true,
    locale,
    messages,
    fallbackLocale: 'en',
    legacy: false,
})

