import Request from '@/common/request'
import { isForeign } from '@/common/constants/env'

export const API = {
    sendEmailCode:"/user/common/sendEmailCode",
    userEmailModify:"/user/user/userEmailModify",
    enterpriseAuthCanCel:"/user/enterpriseAuth/cancel",
    tokenSurvival:"/user/user/tokenSurvival",
    bindUserTel:"/user/user/bind/userTel",
    auth:"/application-console/customer/user/auth",
    checkModifyUserTel:"/user/user/checkModifyUserTel",
    checkModifyUserEmail:"/user/user/checkModifyUserEmail",
}

export default {
    getEmailCode: (params: any) => {
        return Request.post<any, any>(API.sendEmailCode, params,{canCustomHandle:true})
    },
    userEmailModify: (params: any) => {
        return Request.post<any, any>(API.userEmailModify, params,{canCustomHandle:true})
    },
    enterpriseAuthCanCel: (params: any) => {
        return Request.post<any, any>(API.enterpriseAuthCanCel, params,{canCustomHandle:true})
    },
    tokenSurvival: (params: any) => {
        return Request.post<any, any>(API.tokenSurvival, params,{canCustomHandle:true})
    },
    bindUserTel: (params: any) => {
        return Request.post<any, any>(API.bindUserTel, params,{canCustomHandle:true})
    },
    auth: (params: any) => {
        return Request.get<any, any>(API.auth, params,{canCustomHandle:true})
    },
    checkModifyUserTel: (params: any) => {
        return Request.post<any, any>(API.checkModifyUserTel, params,{canCustomHandle:true})
    },
    checkModifyUserEmail: (params: any) => {
        return Request.post<any, any>(API.checkModifyUserEmail, params,{canCustomHandle:true})
    },
}
