import cost from "./index.vue"
import income from "./income/index.vue"
// import bill from "./bill/index.vue"
import CreditLimit from "./CreditLimit/index.vue"
import Recharge from "./Recharge/index.vue"
import preFund from "./preFund/index.vue"
import billList from "./bill/list.vue"
import billDetail from "./bill/detail.vue"
import { i18n } from '@/i18n'
const routes: any[] = [
    {
        name: 'cost',
        path: '/cost',
        component: cost,
        meta: { title: i18n.global.t('cost.meauList.cost') },
        redirect: '/cost/income',
        children: [
            {
                name: 'Income',
                path: 'income',
                component: income,
                meta: { title: i18n.global.t('cost.meauList.Income') },
                children: [

                ]
            },
            {
                name: 'CreditLimit',
                path: 'credit_limit',
                component: CreditLimit,
                meta: { title: i18n.global.t('cost.meauList.CreditLimit') },
                children: [

                ]
            },
            {
                name: 'Recharge',
                path: 'recharge',
                component: Recharge,
                meta: { title: i18n.global.t('cost.meauList.Recharge') },
                children: [

                ]
            },
            {
                name: 'preFund',
                path: 'pre_fund',
                component: preFund,
                meta: { title: i18n.global.t('cost.meauList.preFund') },
                children: [

                ]
            },
            {
                name: 'billList',
                path: 'bill_list',
                component: billList,
                meta: { showTitle: false, title: i18n.global.t('cost.meauList.billList') },
                children: [

                ]
            },
            {
                name: 'billDetail',
                path: 'bill_detail/:id',
                component: billDetail,
                meta: { showTitle: false, title: i18n.global.t('cost.meauList.billDetail') },
                children: [

                ]
            },
        ]
    },
    {
        name: 'costs',
        path: '/cost',
        component: cost,
        meta: { title: "" },
        children: [
            {
                name: 'billList',
                path: 'bill_list',
                component: billList,
                meta: { showTitle: false, title: i18n.global.t('cost.meauList.billList') },
                children: [

                ]
            },
            {
                name: 'billDetail',
                path: 'bill_detail/:id',
                component: billDetail,
                meta: { showTitle: false, title: i18n.global.t('cost.meauList.billDetail') },
                children: [

                ]
            },
        ]
    }

]

export default routes