<template lang="pug">
template(v-if="loading")
    .flex.items-center.justify-center.pt-50
        a-spin(size="large" :spinning="loading" :tip="$t('v2.action.loading') + '...'")
template(v-else-if="!result.records.length || !total")
    div.text-center.relative(class="top-1/2 -translate-y-1/2 -mt-9")
        template(v-if="fromSearch")
            svg-icon(name="empty" :size="100").pri-color
            div(class="mt-4 mb-1 text-xs text-[#C9CDD4]") {{ $t('v2.projectList.searchTitle') }}
            div(class="text-base text-[#4E5969] font-medium")  {{ $t('v2.projectList.searchDesc') }}
        template(v-else)
            img(class="w-45 m-auto" src="@/assets/images/home/create-project.png")
            div( v-if="type ==='recent'" class="mt-2 text-xs text-[#C9CDD4] mb-1") {{ $t('v2.projectList.tip') }}
            div(class="text-base txt-second-color font-medium")  {{ $t('v2.projectList.text') }}
template(v-else)
    .flex.justify.mb-4(v-if="showTitle")
        span(v-if="result.total" class="text-[#86909C] text-xs") {{ total }} {{ $t('v2.projectList.include') }}
    .list-wrap
        project-item(v-for="i in result.records" :key="i.id" :item="i" @onDelete="total--")
    .flex.justify-end(v-if="!hidePagination && result.total > result.size")
        a-pagination(
            v-model:current="result.current"
            size="small"
            :total="result.total"
            :pageSize="result.size"
            @change="currentChange"
        )
</template>

<script lang="ts" setup>
import API from '@/common/api/console/v2/home'
import ProjectItem from '../components/projectItem.vue'

const props = withDefaults(defineProps<{
    type?: string,
    showTitle?: boolean,
    hidePagination?: boolean,
    params?: Record<string, any>
    item?: any
}>(), {
    params: () => ({ size: 20, current: 1 }),
    type: ''
})

const total = ref(0)
const { current, size } = props.params || {}

const result = ref<any>({
    current,
    total: 0,
    size,
    records: []
})
const loading = ref(false)
const fromSearch = ref('')
const fetchList = (params: any = {}) => {
    fromSearch.value = params.applicationName
    loading.value = true
    API.getProjects({ ...props.params, ...params }).then((data: any) => {
        result.value = data
        total.value = data.total
        loading.value = false
    }).catch(() => {
        loading.value = false
    })
}

const currentChange = (page: string) => {
    fetchList({ current: page, applicationName: fromSearch.value })
}

defineExpose({
    fetchList
})

onBeforeMount(fetchList)
</script>