import { wlRequest } from '@welink/request'
import { getCurrentUrl } from '@/common/constants/env'
import { EncryptCBC } from '@/utils/encrypt'
import { handlePermisson, httpErrorHandle } from '@/common/permisson/api'
import { getLocale } from '@/utils/sso'
import {isForeign } from '@/common/constants/env'
const { API_HOST } = getCurrentUrl()

export const NEED_ENCRYPT_URL = [
    '/user/login/register/v1',
    '/user/login/login/v1',
    '/user/login/abroadLogin/v1',
    '/user/login/activation/v1',
    '/user/user/passwordModify/v1',
    '/user/user/passwordModify/v2',
    "/user/user/passwordForget/v2",
    '/user/user/abroadPasswordModify/v1',
    '/user/user/passwordForget/v1',
    '/user/user/abroadPasswordForget/v1',
]

export const NO_NEED_ENVID = [
    '/portal/bill-record/page'
]

export const Language_MAP: any = {
    'en': 'en-US',
    'ja': 'ja-JP',
    'ko': 'ko-KR',
    'zh': 'zh-CN'
}

const passwordParams = ['password', 'srcPassword', 'confirmPassword']

const request = wlRequest({
    baseURL: API_HOST || window.location.origin,
    addHeaders: {
        sign: "dispatch-test",
        "Accept-Language": Language_MAP[isForeign ? getLocale() || 'en' : 'en']
    },
    addPostParams: {
        environmentId: () => window.localStorage.getItem('WL_ENV_ID') || ''
    },
    handleResult: (data: any) => {
        return handlePermisson(data.code, data.msg || '')
    },
    handleRequest: (config: any) => {
        const { url } = config
        if (NEED_ENCRYPT_URL.includes(url)) {
            // const params = JSON.parse(config.data)
            passwordParams.forEach((param: string) => {
                const p = config.data[param]
                if (p) {
                    config.data[param] = EncryptCBC(p)
                }
            })
        }
        if (NO_NEED_ENVID.includes(url)) {
            delete config.data.environmentId
        }
        return config
    },
    handleNetWorkError: (error: any) => {
        httpErrorHandle(error)
        return false
    }
} as any)

export default request