import { Confirm } from '@welink/design';
import { i18n } from '@/i18n'
import { clearLoginInfo, getLocale, getToken, getUserInfo } from '@/utils/sso'
import AdapterLanding from '@/views/console/adapter/onboarding.vue'
import { saveRedirectUrl, isForeign, VITE_WEBSITE_URL } from '@/common/constants/env';
import ForeignLogin from '@/views/userCenter/foreign/login/index.vue'
const routes = (function () {
    const files = import.meta.glob('@/views/**/router.ts', { eager: true }) as any
    return Object.keys(files)
        .reduce((configs, key) => configs.concat(files[key].default), [])
        .filter(Boolean)
}());
const baseRouter: any = [
    {
        name: 'index',
        path: '/',
        redirect: '/console/home'
    },
    {
        name: 'userCenterLogin',
        path: '/login',
        meta: { title: i18n.global.t('userCenter.router.login'), noAuth: true, pageClass: 'page-login' },
        component: ForeignLogin,
    },
    {
        name: 'applicationOnboarding',
        path: '/application/adapter/onboarding',
        meta: { title: '项目适配', noAuth: true },
        component: AdapterLanding
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'page404',
        component: () => import('@/views/error/404.vue')
    }
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        ...baseRouter,
        ...routes,
    ],
    scrollBehavior() {
        return { top: 0 }
    },
})
router.beforeEach(async (to, from, next) => {

    // if (!to.meta.pageClass) {

    // }

    // 不需要授权页面，跳走
    if (to?.meta?.noAuth) {
        next()
        return false
    }

    // 没有权限，跳转登录
    if (!getToken()) {
        if (isForeign) {
            saveRedirectUrl()
            if (getLocale()) {
                if (getLocale() !== 'en') {
                    window.location.href = `${VITE_WEBSITE_URL}/${getLocale()}/login.html`
                    clearLoginInfo()
                } else {
                    window.location.href = `${VITE_WEBSITE_URL}/login.html`
                    clearLoginInfo()
                }

            } else {
                window.location.href = `${VITE_WEBSITE_URL}/login.html`
                clearLoginInfo()
            }
        } else {
            next({ name: 'userCenterLogin' });
        }
        return false;
    }

    next()
    if (from.name === "userCenterLogin") {
        if (getUserInfo('needModifyPassword')) {
            Confirm({
                title: '当前为初始密码，请修改密码',
                okTxt: '修改密码',
                onOk() {
                    router.push({ name: "userInfo", query: { edit: '1' } })
                },
                hiddenCancel: true
            })
            return false
        }

    }

})

router.afterEach((to, from) => {
    const title = to.meta.title
    if (title) {
        document.title = `${i18n.global.t('pageTitle')} - ${title}`
    }
})