<template lang="pug">
div.flex-1.text-center
    a-button(type="default" size="small" @click="editEmailBox").flex-1.text-center.mr-5 {{ t("userInfo.Modify") }}
a-modal(v-model:open="dialogFormBindEmail",
    title="修改邮箱"
    ok-Text="确认"
    cancel-Text="取消"
    width="460px"
    :footer="null"
    @cancel="cancelBox"
    @ok="onSubmit"
)
    w-biz-form.add-form.pt-8.px-4(
        ref="formRef"
        hide-labels
        layout="form"
        :class="{ 'one-line' : true, '!p-4':true }"
        :formState="formStateBindEmail",
        hideSubmit
        hideReset
        :formLayout="{ layout: 'horizontal', labelCol: { span: 4 } }"
    )
    .flex.justify-center
        a-button(type="primary" class="w-[300px]" @click="onSubmit" ).mb-4 下一步
a-modal(:key="dialogEmailCode" v-model:open="dialogEmailCode",
    title="身份验证"
    ok-Text="确认"
    cancel-Text="取消"
    :footer="null"
    width="364px"
    @cancel="cancelBox"
    @ok="onSubmit"
)
    getEmailCode(ref="emailCodeRef" :email="emailAddress" :type="11")
    a-button(type="primary" @click="bindEmailFn" ).w-full 确认
</template>
<script lang="ts" setup>
import { isForeign } from '@/common/constants/env'
import getEmailCode from './component/getEmailCode.vue'
import { regEmail, } from "@/utils/validate"
import API from "@/common/api"
import { useI18n } from 'vue-i18n'
import { message } from '@welink/design-v2';
const emits = defineEmits(["getUserDetail"])
const { t } = useI18n()
const router = useRouter()
const formRef = ref()
const emailCodeRef = ref()
const emailAddress = ref<any>(null)
const showTime = ref(false)
const dialogEmailCode = ref(false)
const formStateBindEmail = ref<any>({
    emailAddress: { label: '邮箱', value: '', rules: [{ required: true, message: "新邮箱不能为空" }, { pattern: new RegExp(regEmail.pattern, "g"), message: regEmail.message }], placeholder: "请输入新邮箱" },
})
const verifyCodeTxt = ref(t('userCenter.getImgCode'))
const dialogFormBindEmail = ref(false)
const editEmailBox = () => {
    dialogFormBindEmail.value = true
}
const bindEmailFn = async () => {
    if (emailCodeRef.value.code.length === 6) {
        const res: any = await API.userCenter.userEmailModify({ emailAddress: formRef.value.formModel.emailAddress, emailCode: emailCodeRef.value.code, verifyCodeType: 11 })
        console.log(res.data.code)
        if (res.data.code === 0) {
            emits("getUserDetail")
            message.success("操作成功")
            dialogEmailCode.value = false
        } else {
            message.error(res.data.msg)
        }
    } else {
        message.error("请输入6位数字验证码")
    }

}
const cancelBox = () => {
    formRef.value.formRef.resetFields()
    dialogFormBindEmail.value = false
}
// const { data: codeData, run: runCode } = sendSmsCode()
const onSubmit = () => {
    console.log(formRef.value.formRef.validate)
    formRef.value.formRef.validate().then(async (data: any) => {
        // const res: any = await API.userCenter.userEmailModify({ ...data, emailCode: 1223, verifyCodeType: 10 })
        // console.log(res.data.code)
        // if (res.data.code === 0) {
        //     message.success("操作成功")
        // }else{
        //     message.error(res.data.msg)
        // }
        const res:any = await API.userCenter.checkModifyUserEmail({ ...data })
        if (res.data.data) {
            dialogFormBindEmail.value = false
            dialogEmailCode.value = true
            emailAddress.value = data.emailAddress

        }else{
            message.error(res.data.msg)
        }

    })

}
</script>
<style lang="sass" scoped>
    :deep .btns
        display: none !important
    :deep .ant-form-item-label
        display: none !important
    :deep .ant-input
        border-radius: 8px !important
</style>