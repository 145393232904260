<template lang="pug">
div(v-if="gameHeight && gameWidth && store.envId")
    run-game(:height="gameHeight" :width="gameWidth")
div(v-else)
    .flex.justify-center.items-center.pt-40
        a-spin(size="large" :spinning="true" :tip="$t('v2.action.loading') + '...'")
</template>

<script setup lang="ts">
import { setLocale } from '@/utils/sso';
import RunGame from './runGame.vue'
import { useGlobalStore } from '@/store/global'

const store = useGlobalStore()

const emits = defineEmits(['change'])
const route = useRoute()
const gameHeight = ref(0)
const gameWidth = ref(0)

onBeforeMount(() => {
    if (route.query.isH5) {
        const { envId, lang } = route.query
        store.setEnvId(envId)
        setLocale((lang as string) || 'en')
        document.body.classList.add('page-game-preview','h5')
    } else {
        document.body.classList.add('page-game-preview')
    }
    gameHeight.value = window.innerHeight
    gameWidth.value = window.innerWidth
})


onBeforeUnmount(() => {
    document.body.classList.remove('page-game-preview')
})
</script>

<style lang="sass">
.page-game-preview
    .page-header
        display: none
    .page-wrapper
        padding-top: 0
        .page-content
            margin: 0
            border-radius: 0
    .page-sidebar-wrap
        display: none
</style>