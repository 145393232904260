<template>
    <div v-if="visible" class="fixed top-0 left-0 z-20 h-screen w-screen transition-transform bg-black bg-opacity-60">
        <div class="bg-white pt-14 h-screen absolute right-0" :style="`width:${width}px`">
            <div class="border-b py-3 px-5 flex items-center justify-between">
                <h5 class="inline-flex items-center text-sm text-gray-900 dark:text-gray-400">{{ title }}</h5>
                <button
                    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    @click="emit('close')">
                    <svg-icon name="close"></svg-icon>
                </button>
            </div>
            <div class="content overflow-y-auto p-5">
                <slot></slot>
            </div>

            <div class="footer absolute bottom-0 px-6 py-4 border-t w-full shandow shadow-sm">
                <template v-if="!slots.footer">
                    <w-button @click="emit('close')">{{ $t('common.close') }}</w-button>
                </template>
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
withDefaults(defineProps<{
    title?: string;
    visible?: boolean;
    direction?: string;
    width?: number
}>(), {
    title: '',
    visible: false,
    direction: 'right',
    width: 500
})
const emit = defineEmits(["close"])
const slots = useSlots()
console.log('slots: ', slots);
</script>
