<template>
    <span :style="`width:${width || size}px;height:${height || size}px;`" class="svg-icon">
        <svg aria-hidden="true">
            <use :href="symbolId" :fill="color" />
        </svg>
    </span>
</template>

<script lang="ts">
export default defineComponent({
    name: 'SvgIcon',
    props: {
        prefix: {
            type: String,
            default: 'icon',
        },
        name: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            required: false,
        },
        height: {
            type: String,
            required: false,
        },
        size: {
            type: [Number, String],
            default: 20
        },
        color: {
            type: String,
            default: 'currentColor',
        },
    },
    setup(props) {
        const symbolId = computed(() => `#${props.prefix}-${props.name}`)
        return { symbolId }
    },
})
</script>

<style lang="sass" scoped>
.svg-icon
    display: inline-block
    vertical-align: middle
    overflow: hidden
    svg
        width: 100%
        height: 100%
        object-fit: contain
</style>