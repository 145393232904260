<template lang="pug">
.add-info.mt-4
    w-step(:steps="[$t('workOrder.submit.problem'), $t('workOrder.submit.type'), $t('workOrder.submit.desc')]" :current="current").pl-5
    .overflow-y-auto.scroll.pl-5.mr-5
        stepCommonProblem(
            v-if="current=== 1"
            :common-problem-idx="commonProblemIdx"
            @common-problem-change="commonProblemChange"
        )
        stepProblemTypes(
            v-if="current === 2"
            :fitstId="fitstId"
            :problem-types-idx="problemTypesIdx"
            @problem-types-change="problemTypesChange"
        )
        stepQuestion(
            v-if="current === 3"
            ref="stepQuestionRef"
            :secoundId="secoundId"
        )
    .bottom.h-16.absolute.w-full.left-0.bottom-0.z-10.border-t.border-gray-200.flex.items-center.pl-5.bg-white
        template(v-if="current === 2")
            w-button.btn( type="default" @click="goStep(1)") {{ $t('workOrder.submit.upStep') }}
        template(v-if="current === 3")
            w-button.btn( type="default" @click="goStep(2)") {{ $t('workOrder.submit.upStep') }}
            w-button( @click="onSubmit") {{ $t('workOrder.submit.submit') }}
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import stepCommonProblem from './stepCommonProblem.vue'
import stepProblemTypes from './stepProblemTypes.vue'
import stepQuestion from './stepQuestion.vue'
import success from './success.vue'
import { router } from '@/routers'
import API from "@/common/api"
import { Toast } from '@welink/design';
const { t } = useI18n()
const { setpList } = API.workOrder
const loading = ref(false)
const commonProblemIdx = ref<any>()
const problemTypesIdx = ref<any>()
const current = ref<any>(1)
const fitstId = ref<any>()
const secoundId = ref<any>()
const mainHeight = ref(0)
const form = ref<any>([])
const stepQuestionRef = ref()
const commonProblemChange = (data: any) => {
    current.value = data.currentStep
    commonProblemIdx.value = data.mindIdx
    fitstId.value = data.firstItem.id
}
const problemTypesChange = (data: any) => {
    current.value = data.currentStep
    problemTypesIdx.value = data.mindIdx
    secoundId.value = data.secoundList.id
}
const goStep = (data: any) => {
    current.value = data
}
const onSubmit = (data: any) => {
    // console.log(stepQuestionRef.value.formRef.validate)

    stepQuestionRef.value.formRef.validate().then((data: any) => {
        console.log(data)

        if (data.valid) {
            try {
                loading.value = true
                setpList({ ...stepQuestionRef.value.formRef.values, contactWay: { typeCode: stepQuestionRef.value.typeCode, value: stepQuestionRef.value.formRef.values.contactWay }, problemTypeId: secoundId.value }).then((data: any) => {
                    Toast.success(t('workOrder.submit.handleSuccess'))
                    router.push({ name: 'success' })
                })
            } catch (e) {
                loading.value = false
            }
        }

    })

}
const initScrollHeight = () => {
    const scroll = document.querySelector('.add-info .scroll') as HTMLElement
    const header = document.querySelector('.page-header') as HTMLElement
    mainHeight.value = window.innerHeight - scroll.offsetTop - header.clientHeight
}
onMounted(async () => {
    initScrollHeight()
})
</script>


<style lang="sass" scoped>

.scroll
    &::-webkit-scrollbar-track
        box-shadow: inset 0 0 6px rgba(0,0,0,0.1)
    &::-webkit-scrollbar
        width: 3px
    &::-webkit-scrollbar-thumb
        border-radius: 6px
        border: 2px solid #555555
.btn
    margin-right: 20px
:deep(.wl-form_label)
    width: 140px
</style>