import { i18n } from '@/i18n'

import Index from './index.vue'
import { isForeign } from '@/common/constants/env'
import ModifyPwd from './common/modifyPwd.vue'

import ForeignLogin from './foreign/login/index.vue'

import NativeLogin from './native/login/index.vue'
import NativeRegister from './native/register/index.vue'
import activation from "./activation/index.vue"
import userInfo from "./userInfo/info/base.vue"
import authentication from "./userInfo/authentication/index.vue"
import authenticationSuccess from "./userInfo/authentication/authentication-success.vue"
import account from "./userInfo/account/index.vue"
import legal_notice from "./common/legal-notice.vue"
import user_agreement from "./common/user-agreement.vue"
import legal_notice_foreign from "./common/legal-notice-foreign.vue"
import legal_statement from "./common/legal-statement.vue"
import legal_statement_foreign from "./common/legal-statement-foreign.vue"
import user_agreement_foreign from "./common/user-agreement-foreign.vue"




const routes = [
    {
        name: 'userCenter',
        path: '/user_center',
        meta: { title: i18n.global.t('userCenter.router.name') },
        component: Index,
        children: [
            {
                name: 'userCenterLogin',
                path: 'login',
                meta: { title: i18n.global.t('userCenter.router.login'), noAuth: true, pageClass: 'page-login' },
                component: isForeign ? ForeignLogin : NativeLogin,
            },
            {
                name: 'legal_statement',
                path: '/legal_statement',
                meta: { title: i18n.global.t('userCenter.router.login'), noAuth: true, pageClass: 'page-full-content' },
                component: isForeign ? legal_statement_foreign : legal_statement,
            },
            {
                name: 'legal_notice',
                path: '/legal_notice',
                meta: { title: i18n.global.t('userCenter.router.login'), noAuth: true, pageClass: 'page-full-content' },
                component: isForeign ? legal_notice_foreign : legal_notice,
            },
            {
                name: 'user_agreement',
                path: '/user_agreement',
                meta: { title: i18n.global.t('userCenter.router.login'), noAuth: true, pageClass: 'page-full-content' },
                component: isForeign ? user_agreement_foreign : user_agreement,
                // component: user_agreement,
            },
            {
                name: 'userCenterRegister',
                path: 'register',
                meta: { title: i18n.global.t('userCenter.router.register'), noAuth: true, pageClass: 'page-register' },
                component: NativeRegister
            },
            {
                name: 'userCenteModifyPwd',
                path: 'modify_pwd',
                meta: { title: i18n.global.t('userCenter.router.modify_pwd'), noAuth: true, pageClass: 'page-reset-pwd' },
                component: ModifyPwd
            },
            {
                name: 'userInfo',
                path: 'info',
                meta: { title: i18n.global.t('userCenter.router.info'), hiddenHeader: true },
                component: userInfo
            },
            {
                name: 'secure',
                path: '/secure',
                meta: { title: "安全设置", hiddenHeader: true },
                component: () => import("./userInfo/info/secure.vue")
            },
            {
                name: 'authentication',
                path: 'authentication',
                meta: { title: i18n.global.t('userCenter.router.authentication'), hiddenHeader: true },
                component: authentication
            },
            {
                name: 'authenticationSuccess',
                path: 'authentication_success',
                meta: { title: i18n.global.t('userCenter.router.authentication'), hiddenHeader: true },
                component: authenticationSuccess
            },
            {
                name: 'account',
                path: 'account',
                meta: { title: i18n.global.t('userCenter.router.account') },
                component: account
            },
            {
                name: 'certification',
                path: '/certification/',
                meta: { title: i18n.global.t('userCenter.router.certification') },
                component: () => import("./userInfo/authentication/certification.vue")
            },
            {
                name: 'certification_edit',
                path: '/certification_edit/:id',
                meta: { title: i18n.global.t('userCenter.router.certification') },
                component: () => import("./userInfo/authentication/edit.vue")
            },
            {
                name: 'certification_detail',
                path: '/certification_detail/:id',
                meta: { title: "认证信息" },
                component: () => import("./userInfo/authentication/detail.vue")
            },

            {
                name: 'activation',
                path: '/activation/:id',
                meta: { title: i18n.global.t('userCenter.router.Activate'), noAuth: true, pageClass: 'page-full-content' },
                component: activation,
            }
        ],
    }
]

export default routes