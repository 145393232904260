import { getCurrentUrl } from '@/common/constants/env'
import Adapter from './adapter'
import Package from './package'
import appInfo from './appInfo'
import resourceList from './resourceList'
import changeDeploy from './changeDeploy'

const { API_HOST } = getCurrentUrl()

const API = {
    applicationPage: '/application-console/customer/application/page',
    applicationSave: '/application-console/customer/application/save',
    adapterMangeList: '/application-console/customer/application-adapter/page',
    applicationSelect: '/application-console/customer/application/list',
    parameterCheck: '/application-console/customer/game-record/info/',
    parameter2Check: '/application-console/customer/server-log/info/'
}

export default {
    api: API,
    domain: API_HOST,
    ...Adapter,
    ...Package,
    ...appInfo,
    ...changeDeploy,
    ...resourceList
}