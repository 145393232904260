import CryptoJS from 'crypto-js'

export const EncryptCBC = (text: string) => {

    const key = CryptoJS.enc.Utf8.parse('7ImV9tWzR2HWeMV7');
    const iv = CryptoJS.enc.Utf8.parse('6781231234545678');

    // 加密过程
    const encrypted = CryptoJS.AES.encrypt(text, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    return  encrypted.toString();
}