import Request, { Language_MAP } from '@/common/request'
const API = {
    list: '/application-console/customer/application-adapter/page',
    saveParams: '/application-console/customer/application-adapter/save',
    updateParams: '/application-console/customer/application-adapter/update',
    dispatch: '/application-console/customer/application-adapter/dispatch',
    apply: '/application-console/customer/application-adapter/apply',
    info: '/application-console/customer/application-adapter/get/',
    loggerInfo: '/application-console/customer/game-record/info/',
    serverInfo: ' /application-console/customer/server-log/info/',
    adapterQuery: '/application-console/customer/application-adapter/query',
}

export default {
    getLoggerInfo: (id: string) => Request.get(API.loggerInfo + id, {}),
    getServerInfo: (id: string) => Request.get(API.serverInfo + id, {}),
    getAdapterList: <R>(params: any) => {
        return Request.post<any, R>(API.list, params)
    },
    getAdapterInfo: <R>(id: string) => {
        return Request.get<any, R>(API.info + id, {})
    },
    saveAdapter: <R>(params: any) => {
        // environmentId	query	string	是	环境ID
        // packageId	query	string	是	包体ID
        // versionId	query	string	是	包体版本ID
        // serverId	query	string	是	资源ID
        // gameStartPath	query	string	否	游戏启动路径
        // storagePath	query	string	否	游戏存档路径
        // adapterFilePath	query	string	否	游戏适配路径
        // extConfigParams	query	string	否	扩展参数
        return Request.post<any, R>(API.saveParams, params)
    },
    updateParams: <R>(params: any) => {
        // serverId	query	string	是	资源ID
        // gameStartPath	query	string	否	游戏启动路径
        // storagePath	query	string	否	游戏存档路径
        // adapterFilePath	query	string	否	游戏适配路径
        // extConfigParams	query	string	否	扩展参数
        return Request.post<any, R>(API.updateParams, params)
    },
    adapterDispatch: <R>(params: any, token?: string, lang?: string) => {
        if (token) {
            return Request.post<any, R>(API.dispatch, params, { headers: {
                Authorization: token,
                "Accept-Language": Language_MAP[lang || 'en']
            } })
        } else {
            return Request.post<any, R>(API.dispatch, params)
        }
    },
    adapterApply: <R>(params: any, token?: string) => {
        if (token) {
            return Request.post<any, R>(API.apply, params,{ headers: {
                Authorization: token
            } })
        } else {
            return Request.post<any, R>(API.apply, params)
        }
        // serverId	query	string	是	资源ID
        // gameStartPath	query	string	否	游戏启动路径
        // storagePath	query	string	否	游戏存档路径
        // adapterFilePath	query	string	否	游戏适配路径
        // extConfigParams	query	string	否	扩展参数
    },
    adapterQuery: <R>(params: any) => {
        return Request.post<any, R>(API.adapterQuery, params)
    },
}