<template lang="pug">
.container-preFound.flex.w-full
    .account-info.px-5.bg-white.mt-5.border.ml-5.mr-4.flex.justify-center(class="w-2/4")
        .cost-amount.w-full.border-t-gray-900.flex.list-none.justify-center.items-center.flex-shrink-0(class="border-t-orange-900")
            li.w-full.h-20(class="").flex.justify-center.items-center
                svg-icon(name="cycle" class="-mt-0.5").mr-5
                div.flex.items-center.flex-col
                    span.text-slate-400.text-xs {{ $t('cost.preFund.trialTotalRecharge') }}
                    span.text-xl.text-blue-500.font-semibold {{ VITE_UNIT }}{{ dataDetail?.trialTotalRecharge }}
            li.w-full.h-20.flex.justify-center.items-center.mr-4
                svg-icon(name="amount" class="-mt-0.5").mr-1.mr-5
                div.flex.items-center.flex-col
                    span.text-slate-400.text-xs {{$t('cost.preFund.trialBalance')}}
                    span.text-xl.text-blue-500.font-semibold {{VITE_UNIT}} {{ dataDetail?.trialBalance }}
    .account-info.px-5.py-5.bg-white.mt-5.border.mr-4(class="w-2/4")
        span.text-sm {{ $t('cost.preFund.RechargeType') }}
        .test-content.bg-gray-100.leading-4.p-4.mt-2.rounded.leading-6
            ol.list-decimal.pl-4.text-xs
                li.leading-3.mb-2(class="!text-left")
                    span.text-xs.text-slate-700 {{ $t('cost.preFund.RechargeTypeDesc1') }}
                    router-link(:to="{ name: 'add'}").text-xs.text-blue-600.mx-1 {{ $t('cost.preFund.RechargeTypeDesc4') }}
                    span.text-xs.text-slate-700 {{ $t('cost.preFund.RechargeTypeDesc5') }}
                li.leading-3.mb-2(class="!text-left")
                    p.text-xs.text-slate-700 {{ $t('cost.preFund.RechargeTypeDesc2') }}
//- .container-preFound.flex.w-full
//-     .account-info.px-5.py-5.bg-white.mt-5.border.mr-4.ml-5(class="w-2/4")
//-         span.text-sm {{$t('cost.preFund.contact')}}
//-         .test-content.leading-4.mt-2
//-             p.text-xs.text-slate-400
//-                 | {{$t('cost.preFund.email')}}:
//-                 span.text-gray-700 xxxxxxxxx@xxx.com
//-             p.text-xs.text-slate-400
//-                 |  {{$t('cost.preFund.telNumer')}}:
//-                 span.text-gray-700 +86 138 1234 5678
//-     .account.px-5.py-5.mr-4(class="w-2/4")
</template>
<script lang="ts" setup>
import API from "@/common/api"
import { getUserInfo } from "@/utils/sso";
import { VITE_UNIT } from "@/common/constants/env"
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const { accountDetail, cashRecordList } = API.accountCost
const dataDetail = ref<any>()
const userInfo: any = reactive({})
onMounted(() => {
    accountDetail({ username: getUserInfo().username }).then(data => {
        console.log(data)
        dataDetail.value = data
    })
})

</script>
<style lang="sass" scoped>
.account-info .cost-amount li
    border-right: 1px solid #ccc
    text-align: center
.account-info li:last-child
    border-right: 0px
    text-align: center
.account-info,.table-list
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.08)
    border-radius: 8px
</style>