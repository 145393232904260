<template lang="pug">
a-tour(
    v-if="open"
    rootClassName="console-home-guide-popup" :open="open" :steps="steps"
    @close="handleOpen"
)
    template(#indicatorsRender="{ current }")
        span.circle(:class="`${current === 0 ? 'current': ''}`")
        span.circle(:class="`${current === 1 ? 'current': ''}`")
        span.circle(:class="`${current === 2 ? 'current': ''}`")
        span.skip(v-show="current !== 2" @click="handleOpen") {{ $t('v2.action.skip') }}
</template>

<script lang="ts" setup>
import step1 from '@/assets/images/home/guide-step-01.png'
import step2 from '@/assets/images/home/guide-step-02.png'
import step3 from '@/assets/images/home/guide-step-03.png'
import { useHomeStore } from '@/store/home'
import { useI18n } from 'vue-i18n'
const store = useHomeStore()
const { t } = useI18n()

const open = computed(() => store.guideInfo.guidePopup)
const handleOpen = (): void => {
    store.hideGuidePopup()
};
const steps = [
    {
        title: t('v2.console.guide.g1.title'),
        description: h('div', {}, [t('v2.console.guide.g1.desc1'), h('strong', {}, t('v2.console.guide.g1.desc2'))]),
        cover: h('img', { alt: t('v2.console.guide.g1.title'), src: step1 }),
        nextButtonProps: h('div', {}, t('v2.console.guide.g1.btn')),
        target: null,
    },
    {
        title: t('v2.console.guide.g2.title'),
        description: h('div', {}, [h('strong', {}, t('v2.console.guide.g2.desc1')), t('v2.console.guide.g2.desc2')]),
        cover: h('img', { alt: t('v2.console.guide.g2.title'), src: step2 }),
        nextButtonProps: h('div', {}, t('v2.console.guide.g2.btn')),
        prevButtonProps: h('span', {}, ''),
        target: null,
    },
    {
        title: t('v2.console.guide.g3.title'),
        description: h('div', {}, [t('v2.console.guide.g3.desc1'), h('strong', {}, t('v2.console.guide.g3.desc2')) ]),
        nextButtonProps: h('div', {}, t('v2.console.guide.g3.btn')),
        prevButtonProps: h('span', {}, ''),
        prevButton: false,
        cover: h('img', { alt: t('v2.console.guide.g3.title'), src: step3 }),
        target: null,
    },
]
</script>

<style lang="sass">
.console-home-guide-popup
    border-radius: 10px
    overflow: hidden
    .anticon-close
        color: #fff !important
        z-index: 1000
    .ant-tour-header
        padding-top: 20px !important
        padding-bottom: 12px !important
        text-align: center
        .ant-tour-title
            color: #86909C
            font-size: 12px !important
            font-weight: 400 !important
            line-height: 16px
            letter-spacing: 0.4px
    .ant-tour-description
        padding: 0 40px 24px !important
        text-align: center
        color: #1D2129
        font-size: 20px
        font-weight: 500
        line-height: 25px
        strong
            font-weight: normal
            color: #124EF4
    .ant-tour-cover
        padding: 0 !important
        height: 278px
    .ant-tour-footer
        padding-top: 0 !important
        position: relative
        .ant-tour-indicators
            position: absolute
            left: 50%
            transform: translateX(-50%)
            bottom: 22px
            .circle
                width: 6px
                height: 6px
                margin: 0 6px
                display: inline-block
                background-color: #E5E6EB
                border-radius: 3px
                &.current
                    background-color: #124EF4
            .skip
                color: #424A61
                font-size: 16px
                font-weight: 500
                line-height: 24px
                position: absolute
                top: -2px
                cursor: pointer
                right: -120px
    .ant-tour-next-btn
        color: #fff
        font-size: 16px !important
        display: inline-block
        background-color: #124EF4
        height: 37px !important
        border-radius: 10px !important
        padding: 0px 20px !important
        position: relative
</style>