import { i18n } from '@/i18n'
import workOrder from "./index.vue"
import add from "./add/index.vue"
import serviceLog from "./serviceLog/list.vue"
import success from "./add/success.vue"
const routes = [
    {
        name: 'workOrder',
        path: '/work_order',
        redirect: '/work_order/add',
        meta: { title: i18n.global.t('workOrder.orderManage') },
        component: workOrder,
        children: [
            {
                name: 'workOrderAdd',
                path: 'add',
                meta: { title: i18n.global.t('workOrder.submitOrder') },
                component: add
            },
            {
                name: 'myWorkOrderList',
                path: 'my',
                meta: { title: i18n.global.t('workOrder.serviceRecord') },
                component: serviceLog
            },
            {
                name: 'success',
                path: 'success',
                meta: { title: i18n.global.t('workOrder.success') },
                component: success
            },
        ],

    }
]

export default routes