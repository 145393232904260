import Request from '@/common/request'
export const API = {
    areaList: '/application-console/customer/resource-manager/list/region',
    resourceList: '/application-console/customer/resource-manager/list/ccu'
}

export default {
    ...API,
    // getAreaList: <R>(params: any) => {
    //     return Request.post<any, R>(API.areaList, params)
    // }
}