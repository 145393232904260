import { AxiosError } from 'axios'
import { router } from '@/routers/index'
import { SUCCESS_CODE, NEED_LOGON, NO_PERMISSION, LOGIN_EXPIRED, NO_CERTI } from '@/common/constants/responeCode'
import { message } from "@welink/design-v2"
import { saveRedirectUrl, isForeign, VITE_WEBSITE_URL, NO_AUTH_ROUTE_NAME } from '../constants/env';
import { clearLoginInfo, getLocale } from '@/utils/sso';
const PERMISSON_MAP: any = {
    [NEED_LOGON]: () => {
        const loginRouterName = 'userCenterLogin'
        const redirectRouter = router.currentRoute.value
        const query: any = {}
        if (loginRouterName !== redirectRouter.name) {
            query.redirect = encodeURIComponent(router.currentRoute.value.fullPath)
        }
        if (isForeign) {
            saveRedirectUrl()
            if (getLocale()) {
                if (getLocale() !== 'en') {
                    window.location.href = `${VITE_WEBSITE_URL}/${getLocale()}/login.html?a=1`
                    clearLoginInfo()
                } else {
                    window.location.href = `${VITE_WEBSITE_URL}/login.html`
                    clearLoginInfo()
                }

            } else {
                window.location.href = `${VITE_WEBSITE_URL}/login.html`
                clearLoginInfo()
            }
        } else {
            router.push({ name: 'userCenterLogin', query })
        }


    },
    [NO_PERMISSION]: () => {
        // message.warning('你没有权限，获取数据失败')
    },
    [LOGIN_EXPIRED]: (msg: string) => {
        message.warning(msg)
        const loginRouterName = 'userCenterLogin'
        const redirectRouter = router.currentRoute.value
        const query: any = {}
        if (loginRouterName !== redirectRouter.name) {
            query.redirect = encodeURIComponent(router.currentRoute.value.fullPath)
        }
        if (isForeign) {
            saveRedirectUrl()
            if (getLocale()) {
                if (getLocale() !== 'en') {
                    window.location.href = `${VITE_WEBSITE_URL}/${getLocale()}/login.html`
                    clearLoginInfo()
                } else {
                    window.location.href = `${VITE_WEBSITE_URL}/login.html`
                    clearLoginInfo()
                }

            } else {
                window.location.href = `${VITE_WEBSITE_URL}/login.html`
                clearLoginInfo()
            }
        } else {
            router.push({ name: 'userCenterLogin', query })
        }
    },
    // [NO_CERTI]: (msg: string) => {
    //     console.log(document.getElementsByClassName("wl-modal-popup"))
    //     const model = document.getElementsByClassName("wl-modal-popup")
    //     console.log(model.length)
    //     if (!model.length) {
    //         Confirm({
    //             title: '当前账号尚未进行企业认证！完成企业认证,体验更多功能',
    //             okTxt: '去认证',
    //             onOk() {
    //                 router.push({ name: "certification" })
    //             },
    //             hiddenCancel: true
    //         })
    //     }

    // }
}

export const handlePermisson = async (code: number, content: string) => {
    const currentRouteName = router.currentRoute.value.name
    if (NO_AUTH_ROUTE_NAME.includes(currentRouteName as string)) {
        return
    }
    if (code && code !== SUCCESS_CODE) {
        const handleFn = PERMISSON_MAP[code];
        if (handleFn) {
            handleFn(content)
        } else {
            message.error(content)
        }
        return Promise.reject(content)
    }
}

export const httpErrorHandle = (error: AxiosError) => {
    const data: any = error.response?.data || { error: '', message: '' }
    message.error(data.message || data.error)
}