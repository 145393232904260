<template lang="pug">
page-sidebar(:menuList="menuList()")
page-body
    h1.page-title {{ route.meta.title }}
    .bg-white.p-5.rounded-lg.min-h-full
        router-view
</template>
<script setup lang="ts">
import pageSidebar from '@/views/components/page-sidebar.vue';
import pageBody from '@/views/components/page-body.vue';
import { menuList } from './menu'

const route = useRoute()
</script>





