<template lang="pug">
div.flex-1.text-center
    a-button(type="default" size="small" @click="editPasswordBox").flex-1.text-center.mr-5 {{ t("userInfo.Modify") }}
a-modal(v-model:open="dialogFormEditPassword",
    :title="t('userCenter.basicInpormation.editPassword')"
    :ok-Text="t('common.confirm')"
    :cancel-Text="t('common.cancel')"
    @cancel="cancelBox"
    @ok="onSubmit"
)
    w-biz-form.add-form.p-5(
        ref="formRef"
        layout="form"
        hide-labels
        :class="{ 'one-line' : true, '!p-4':true }"
        :loading="loading"
        :formState="formStatePassWord",
        hideSubmit
        hideReset
        :formLayout="{ layout: 'horizontal', labelCol: { span: 4 }, gap: 20 }"

    )
</template>
<script lang="ts" setup>
import { VITE_ENV, isForeign, VITE_WEBSITE_URL } from '@/common/constants/env'
import { regPassword, regUserName } from "@/utils/validate"
import { userTelModify, userDetail, passwordModify, abroadPasswordModify } from "@/service/userCenter/userCenter"
import { useI18n } from 'vue-i18n'
import { validate } from "vee-validate";
import { message } from '@welink/design-v2';
import { clearLoginInfo, getLocale } from '@/utils/sso';
const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const formRef = ref()

const formStatePassWord = ref<any>({
    srcPassword: { label: t('userCenter.basicInpormation.oldPassword'), value: '', hideLabel: true, type: 'password', rules: [{ trigger: 'blur', required: true, message: t('userCenter.basicInpormation.oldPassword') + t('common.not_empty') }, { trigger: 'blur', pattern: new RegExp(regPassword.pattern, "g"), message: regPassword.message }], allowClear: true, placeholder: t('userCenter.basicInpormation.oldPasswordPlacehoulder') },
    password: { label: t('userCenter.basicInpormation.newPassword'), value: '', hideLabel: true, type: 'password', rules: [{ trigger: 'blur', required: true, message: t('userCenter.basicInpormation.newPassword') + t('common.not_empty') }, { trigger: 'blur', pattern: new RegExp(regPassword.pattern, "g"), message: regPassword.message }, {}], allowClear: true, placeholder: t('userCenter.basicInpormation.newPasswordPlacehoulder') },
    confirmPassword: { label: t('userCenter.basicInpormation.confirmPassword'), hideLabel: true, value: '', type: 'password', rules: [{ trigger: 'blur', required: true, message: t('userCenter.basicInpormation.confirmPassword') + t('common.not_empty') }, { trigger: 'blur', pattern: new RegExp(regPassword.pattern, "g"), message: regPassword.message }], allowClear: true, placeholder: t('userCenter.basicInpormation.confirmPasswordPlacehoulder') }
})
formStatePassWord.value.confirmPassword.rules.push({
    trigger: 'blur',
    validator: async (_rule: any, value: string) => {
        console.log(value)
        if (value !== formStatePassWord.value.password.value) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject(t("userInfo.rePasswordError"))
        } else {
            return Promise.resolve();
        }
    }
} as any)
// 国内修改密码
const { data: dataPassword, error: passwordError, run: runPasswordSave } = passwordModify()
// 国外修改密码
const { data: abroadPasswordData, loading, run: runAbroadPasswordSave } = abroadPasswordModify()
const dialogFormEditPassword = ref(false)
const editPasswordBox = () => {
    console.log("修改密码")

    dialogFormEditPassword.value = true
}
const cancelBox = () => {
    formRef.value.formRef.resetFields()
    dialogFormEditPassword.value = false
}
const onSubmit = () => {
    console.log(formRef.value.formRef.validate)
    formRef.value.formRef.validate().then(async (data: any) => {
        console.log(validate)
        if (!isForeign) {
            await runPasswordSave({ ...data }).then(() => {
                if (dataPassword.value.data.code === 0) {
                    message.success(t('userCenter.basicInpormation.operateSucess'))
                    dialogFormEditPassword.value = false
                    router.push("/user_center/login")
                } else {
                    message.error(dataPassword.value.data.msg)
                }

            })
        } else {
            runAbroadPasswordSave({ ...data }).then(() => {
                if (abroadPasswordData.value.data.code === 0) {
                    message.success(t('userCenter.basicInpormation.operateSucess'))
                    dialogFormEditPassword.value = false

                    if (getLocale()) {
                        if (getLocale() !== 'en') {
                            window.location.href = `${VITE_WEBSITE_URL}/${getLocale()}/login.html`
                            clearLoginInfo()
                        } else {
                            window.location.href = `${VITE_WEBSITE_URL}/login.html`
                            clearLoginInfo()
                        }

                    } else {
                        window.location.href = `${VITE_WEBSITE_URL}/login.html`
                        clearLoginInfo()
                    }
                } else {
                    message.error(abroadPasswordData.value.data.msg)
                }
            })

        }
    })
}
onMounted(() => {
    console.log(route,router)
    if (route.query.edit) {
        dialogFormEditPassword.value = true
    }
})

</script>
<style lang="sass" scoped>
    :deep .btns
        display: none !important
    :deep .ant-input-affix-wrapper
        border-radius: 8px !important
</style>