import { LOCALE, isForeign } from '@/common/constants/env'

export const SSO_TOKEN = 'WL_SSO_TOKEN'
export const SSO_INFO = 'WL_SSO_INFO'
export const WL_ENV_ID = 'WL_ENV_ID'
export const WL_LOCALE = 'WL_LOCALE'

export const getUserInfo = (key = '') => {
    let userInfo: any = {}
    try {
        userInfo = JSON.parse(localStorage.getItem(SSO_INFO) || '')
    } catch (e) {

    }
    if (key) {
        return userInfo[key]
    }
    return userInfo
}

export const clearLoginInfo = () => {
    window.localStorage.setItem(SSO_TOKEN, '')
    window.localStorage.setItem(SSO_INFO, '')
    window.localStorage.setItem(WL_ENV_ID, '')
    window.localStorage.setItem(WL_LOCALE, '')
}

export const setToken = (token?: string) => {
    if (token) {
        window.localStorage.setItem(SSO_TOKEN, token)
    }
}
export const getToken = () => window.localStorage.getItem(SSO_TOKEN)

export const setLocale = (key: string) => {
    window.localStorage.setItem('WL_LOCALE', key)
}
export const getLocale = () => window.localStorage.getItem('WL_LOCALE')

export const saveLoginInfo = (userInfo: any) => {
    setLocale(isForeign ? getLocale() || 'en' : 'zh')
    window.localStorage.setItem(SSO_TOKEN, userInfo.authorization)
    window.localStorage.setItem(SSO_INFO, JSON.stringify(userInfo))
}