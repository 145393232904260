const env = import.meta.env || {}

export const {
    VITE_ENV,
    VITE_API_HOST,
    VITE_GAME_ISP_HOST,
    VITE_GAME_REPORT_HOST,
    VITE_UNIT,
    VITE_WEBSITE_URL
} = env

// 当前构建环境
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const isForeign = _LANGENV_ === 'foreign'

// 当前语言环境
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const LOCALE = _LOCALE_

export const getLocal = () => localStorage.getItem('WL_LOCALE')

export const getCurrentUrl = () => {
    return {
        API_HOST: VITE_API_HOST,
        GAME_ISP_HOST: VITE_GAME_ISP_HOST
    }
}

export const NO_AUTH_ROUTE_NAME = ['projectGamePreview']

export const saveRedirectUrl = () => {
    const redirect_url = encodeURIComponent(window.location.href)
    window.localStorage.setItem('WL_REDIRECT_URL', redirect_url)
}

export default env