<template lang="pug">
.success.p-5
    .anth_status_success(v-if="isReady&&data&&data.approveStatus===1")
        span.success_title
            a-tooltip(placement="top" destroyTooltipOnHide color="#fff")
                    template(#title)
                        span(style="color: #000;") {{ $t('common.back') }}
                    span(@click="goBack").mr-2.cursor-pointer
                        svg-icon(name="back", :size="16" class="hover:text-blue-700"
        style="margin-top: -4px;")
            span 实名认证信息

        .success_content
            div.success_img
                img(style="width: 100%;height:110px;margin-top: 18px;" src="@/assets/svg/暂无记录.svg")
            div.success_desc
                a-descriptions(title="" )
                    a-descriptions-item(label="企业/商户全称")
                        span {{ data?.enterpriseName }}
                    a-descriptions-item(label="经营行业:")
                        span {{ data?.industryInfoDesc }}
                    a-descriptions-item(label="企业官网")
                        span {{ data?.website }}
                    a-descriptions-item(label="经营地址")
                        span {{ data?.provinceName }} {{ data?.cityName }} {{ data?.address }}
                    a-descriptions-item(label="联系方式")
                        span {{ data?.serviceTel }}
                    a-descriptions-item(label="社会统一信用代码")
                        span {{ data?.enterpriseBusinessNo }}
                    //- a-descriptions-item(label="营业执照有效期")
                    //-     span {{ data?.businessCertificateEffectiveDateEnd }}
                    a-descriptions-item(label="法人代表")
                        span {{ data?.corporate }}
                    a-descriptions-item(label="法人证件号" :span="2")
                        span {{ data?.corporateCertificateNo }}
                    a-descriptions-item(label="营业执照" :span="3")
                        a-image.mr-4(v-for="item in data.businessCertificateImageList" :key="item.url" :src="item.url" style="width:115px;height: 65px;")
                            img(:src="item.url")
                    a-descriptions-item(label="法人证件照" :span="3")
                        a-image.mr-4(v-for="item in data.corporateCertificateImageList" :key="item.url" :src="item.url" style="width:115px;height: 65px;")
                            img(:src="item.url")



</template>
<script setup lang="ts">
import { authDetail } from "@/service/userCenter/authentication"
import { Modal, message } from '@welink/design-v2';
import API from "@/common/api"
const { data, run } = authDetail({})
const router = useRouter()
const isReady = ref(false)
const goBack = ()=>{
    router.push({
        name:"authentication"
    })
}
onBeforeMount(async () => {
    await run({})
    isReady.value = true
})
</script>
<style lang="sass">
.anth_status_success .success_desc
    .ant-descriptions-item-container
        display: flex !important
        align-items: flex-start !important
    .ant-descriptions-item-content
        display: flex !important
        
        // align-items: center !important
    // .ant-descriptions-item-label
    //     width: 80px !important
</style>
<style lang="sass" scoped>
.anth_status_success
    box-sizing: border-box
    width: 100%
    // height: 323px
    display: flex
    flex-direction: column
    // margin: 20px
    // padding: 20px
    border:1px solid #E5E7EB
    // background: linear-gradient(180deg, #F3FFF5 0%, #FFFFFF 16%, #FFFFFF 100%)
    .success_title
        // width: 126px

        height: 25px
        font-size: 18px
        font-weight: 500
        // color: #12B862
        line-height: 25px
        margin-left: 20px
        padding-top: 20px

    .success_content
        flex:1
        margin-top: 30px
        padding-left: 36px
        display: flex
    .success_img
        width: 150px
        // background: #ccc
        height: 100%
    .success_desc
        flex: 1
        margin-left: 53px
        margin-right: 50px
        display: flex
        flex-direction: column

        .item-row
            display: flex
            margin-top: 10px
        .desc-item
            width: 32%
            // margin-right: 210px
            height: 30px
            display: flex
            .desc-label
                height: 20px
                font-size: 14px
                font-family: PingFangSC-Regular, PingFang SC
                font-weight: 400
                color: #999999
                line-height: 20px
                min-width: 100px
            .desc-value
                height: 20px
                font-size: 14px
                font-family: PingFangSC-Regular, PingFang SC
                font-weight: 400
                color: #333333
                margin-left: 15px
                line-height: 20px
                display: flex
                flex-wrap: wrap
                img
                    max-width:100px
                    max-height: 100px
                    min-width: 70px
                    min-height: 60px
</style>