import Request from '@/common/request'
import { isForeign } from '@/common/constants/env'

export const API = {
    foreign: {
        login: '/user/login/abroadLogin/v1',
        active: '/user/login/activation/v1',
        modifyPwd: '/user/user/abroadPasswordForget/v2',
        activeLinkCheck: '/user/login/activationLinkCheck',
    },
    modifyPwd: '/user/user/passwordForget/v2',
    loginByUsername: '/user/login/login/v1',
    loginByPhone: '/user/login/smsLogin',
    getAccountList: '/user/login/smsLoginAccountList',
    logout: '/user/login/logout',
    register: '/user/login/register/v1',
    sendEmailCodeIgnoreLogin: '/user/common/sendEmailCodeIgnoreLogin',
    sendSMSCodeIgnoreLogin: '/user/common/sendSmsCodeIgnoreLogin',
    verifyCodeReceiveMode: '/user/common/verifyCodeReceiveMode',
    abroadVerifyCodeReceiveMode: '/user/common/abroadVerifyCodeReceiveMode',
    sendSmsCodeIgnoreLoginByUsername: '/user/common/sendSmsCodeIgnoreLoginByUsername',
}

export default {
    foreignLogin: <R>(params: any) => {
        return Request.post<any, R>(API.foreign.login, params)
    },
    loginByUsername: <R>(params: any) => {
        return Request.post<any, R>(API.loginByUsername, params,{canCustomHandle:true})
    },
    loginByPhone: <R>(params: any) => {
        return Request.post<any, R>(API.loginByPhone, params)
    },
    register: <R>(params: any) => {
        return Request.post<any, R>(API.register, params, { canCustomHandle: true })
    },
    logout: <R>() => {
        return Request.post<any, R>(API.logout, {})
    },
    modifyPwd: <R>(params: any) => {
        return Request.post<any, R>(isForeign ? API.foreign.modifyPwd : API.modifyPwd, params,{canCustomHandle:true})
    },
    sendEmailCode: <R>(params: any) => {
        return Request.post<any, R>(API.sendEmailCodeIgnoreLogin, params,{canCustomHandle:true})
    },
    sendSMSCode: <R>(params: any) => {
        return Request.post<any, R>(API.sendSMSCodeIgnoreLogin, params,{canCustomHandle:true})
    },
    getAccountList: <R>(params: any) => {
        return Request.post<any, R>(API.getAccountList, params,{canCustomHandle:true})
    },
    verifyCodeReceiveMode: <R>(params: any) => {
        return Request.post<any, R>(API.verifyCodeReceiveMode, params,{canCustomHandle:true})
    },
    abroadVerifyCodeReceiveMode: <R>(params: any) => {
        return Request.post<any, R>(API.verifyCodeReceiveMode, params,{canCustomHandle:true})
    },
    sendSmsCodeIgnoreLoginByUsername: <R>(params: any) => {
        return Request.post<any, R>(API.sendSmsCodeIgnoreLoginByUsername, params,{canCustomHandle:true})
    },
}