<template lang="pug">
.CreditLimit.w-full.h-full(v-if="dataDetail.userId")
    .account-info.px-5.pt-5.bg-white.mt-5.border.mr-5.ml-5
        span {{ $t("cost.creditLimit.accountInfo") }} : {{ dataDetail.username }}
        .cost-amount.mt-4.border-t-gray-900.flex.list-none.justify-around(class="border-t-orange-900")
            li.w-full.h-20(class="").flex.justify-center.items-center
                svg-icon(name="cycle" class="-mt-0.5").mr-5
                div.flex.flex-col.items-start
                    span.text-slate-400.text-xs {{ $t("cost.creditLimit.creditBalance") }}
                    span.text-xl.text-blue-500.font-semibold {{ VITE_UNIT }}{{ dataDetail.creditBalance }}
            li.w-full.h-20.flex.justify-center.items-center
                svg-icon(name="amount" class="-mt-0.5").mr-1.mr-5
                div.flex.flex-col.items-start
                    span.text-slate-400.text-xs {{ $t("cost.creditLimit.canUse") }}
                    span.text-xl.text-blue-500.font-semibold {{VITE_UNIT}} {{ dataDetail.creditBalance }}
            li.w-full.h-20.flex.justify-center.items-center
                svg-icon(name="calendar").mr-5
                div.flex.flex-col.items-start
                    span.text-slate-400.text-xs {{ $t("cost.creditLimit.billingCycleDesc") }}
                    span.text-xl.text-blue-500.font-semibold  {{ dataDetail.billingCycleDesc }}
            li.w-full.h-20.flex.justify-center.items-center
                svg-icon(name="date").mr-5
                div.flex.flex-col.items-start
                    span.text-slate-400.text-xs {{ $t("cost.creditLimit.repaymentDate") }}
                    span.text-xl.text-blue-500.font-semibold {{$t("cost.creditLimit.month")}} {{dataDetail.repaymentDate}} {{$t("cost.creditLimit.day")}}
    .table-list.px-5.py-5.bg-white.mt-5.border.mr-5.mr-4.mb-10.ml-5
        span.mb-10  {{ $t("cost.creditLimit.tableTitle") }}
        w-table.mt-2(
            :dataSource="recordDataList"
            :loading="loading"
            :columns="columns"
            :pagination="pagenationParams"
            :scroll="{ x: 1000 }"
            @pagination-num-change="changeParams"
            @pagination-size-change="changeSizeParams"
        )
            template(#extra)
                .flex
                    .w-60.mb-4.mr-4
                        w-select(v-model="recordForm.operateType" allowClear :placeholder="$t('cost.creditLimit.operateTypePlaceholder')" :options="{ options: options }")
                    .w-60.mb-2
                        w-picker(
                            v-model="recordForm.timeRange"
                            :placeholder="$t('cost.creditLimit.datePlaceholder')"
                            :enable-time-picker="false"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            autoApply
                            range
                        )
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import API from "@/common/api"
import { getUserInfo } from '@/utils/sso';
import dayjs from "dayjs";
import { VITE_UNIT } from "@/common/constants/env"
const { accountDetail, creditRecordList } = API.accountCost
const userInfo: any = reactive(getUserInfo())
const dataDetail = ref<any>({})
const recordDataList = ref<any>([])
const loading = ref<any>(false)
const { t } = useI18n()
const options = [
    { label: t('common.CreditOperateTypeEnum.add'), value: '1' },
    { label: t('common.CreditOperateTypeEnum.subtract'), value: '2' },
]
const recordForm = reactive({
    operateType: '',
    operateTimeBegin: '',
    operateTimeEnd: '',
    timeRange: []
})
// console.log(userInfoDatac)
const pagenationParams = ref({
    current: 1,
    pageNo: 1,
    pageSize: 10,
    size: 10,
    total: 0
})
const getDataList = async () => {
    loading.value = true
    recordDataList.value = []
    const params: any = {
        operateType: recordForm.operateType,
    }
    if (recordForm.timeRange?.length) {
        params.operateTimeBegin = dayjs(recordForm.timeRange[0]).format('YYYY-MM-DD')
        params.operateTimeEnd = dayjs(recordForm.timeRange[1]).format('YYYY-MM-DD')
    }
    try {
        const data: any = await creditRecordList({ ...pagenationParams.value, ...params })
        loading.value = false
        recordDataList.value = data.list
        pagenationParams.value.total = data.total
    } catch (e) {
        loading.value = false
    }
}

watch(() => recordForm.operateType, () => {
    getDataList()
})
watch(() => recordForm.timeRange, () => {
    getDataList()
})
const changeParams = (params: any) => {
    pagenationParams.value.pageNo = params
    getDataList()
}
const changeSizeParams = (params: any) => {
    // pagenationParams.value.pageNo = params?.current
    pagenationParams.value.pageSize = params
    getDataList()
}

const columns = ref<any>([
    {
        title: t("cost.creditLimit.columns.serialNumber"),
        prop: 'serialNumber',
        width:200
    },
    {
        title: t("cost.creditLimit.columns.operateTypeDesc"),
        prop: 'operateTypeDesc',
        width:200
    },
    {
        title: t("cost.creditLimit.columns.currency"),
        prop: 'currency',
        width:100
    },
    {
        title: t("cost.creditLimit.columns.operateAmount"),
        prop: 'operateAmount',
        width:200
    },
    {
        title: t("cost.creditLimit.columns.amountAfterOperate"),
        prop: 'amountAfterOperate',
        width:250
    },
    {
        title: t("cost.creditLimit.columns.operateTime"),
        appendColumnContent: 'UTC+8:00',
        prop: 'operateTime',
        width:300
    },
    {
        title: t("cost.creditLimit.columns.operateRemark"),
        prop: 'operateRemark',
        width:100
    },

])
onMounted(() => {
    accountDetail({ username: userInfo.username }).then(data => {
        console.log(data)
        dataDetail.value = data
    })
    getDataList()
})

</script>
<style lang="sass" scoped>
li
    height: 88px
    border-right: 1px solid rgba(229, 231, 235, 1)
    text-align: center
li:last-child
    border-right: 0px
    text-align: center
.cost-amount
    border-top: 1px solid rgba(229, 231, 235, 1)
    // padding-top: 20px
.account-info,.table-list
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.08)
    border-radius: 8px
</style>