<template lang="pug">
.flex.mb-4.justify-between.items-center
    h1.page-title.mb-0.pt-0 {{ $t('console.router.applicationAdapterList') }}
    .search.flex.gap-5.pt-1.pr-1
        a-select(v-model:value="searchForm.applicationId" allowClear show-search :filter-option="filterOptions" :options="projectList" :placeholder="$t('v2.resourceList.selectProject')" class="!w-40")
        a-select(v-model:value="searchForm.packageId" allowClear show-search :filter-option="filterOptions" :options="packageList" :placeholder="$t('v2.resourceList.selectPackage')" class="!w-40")
.bg-white.pb-5.pt-0.px-4.rounded-lg.min-h-full.common-console-list
    .left-top
    .right-top
    .left-bottom
    .right-bottom
    w-biz-table(
        :data="dataResult.records"
        :columns="columns"
        :loading="tableLoading"
        :pagination="{ current: dataResult.current, total: dataResult.total, pageSize: dataResult.size, size: 'small' }"
        @change="changeSize"
    )
        template(#emptyText)
            .py-10.txt-third-color {{ $t('v2.action.emptyData') }}
        template(#customColumn="{ record, column, text}")
            template(v-if="column.prop === 'status'")
                a-tag(:color="AdapterMap[text].color") {{ AdapterMap[text].text }}
            template(v-if="column.prop === 'action'")
                span.pri-color.cursor-pointer(v-if="record.status === 6" hover="underline" @click="sumbitAdapterInfo(record)") {{ $t('v2.adapterList.submit') }}
</template>


<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import API from '@/common/api/console/v2/home'
import { cloneDeep } from 'lodash-es'

const { t } = useI18n()
const AdapterMap: any = {
    '0': { color: '', text: t('v2.adapterList.status0') },
    '1': { color: 'green', text: t('v2.adapterList.status1') },
    '2': { color: 'error', text: t('v2.adapterList.status2') },
    '3': { color: '', text: t('v2.adapterList.status3') },
    '5': { color: '', text: t('v2.adapterList.status5') },
    '6': { color: 'error', text: t('v2.adapterList.status6') },
    '7': { color: 'green', text: t('v2.adapterList.status7') }
}

const listRef = ref()
const keyword = ref('')
const tableLoading = ref(false)
const router = useRouter()
const dataResult = ref({
    current: 1,
    total: 0,
    size: 10,
    records: []
})
const searchForm = reactive<any>({
    applicationId: null,
    packageId: null
})
const projectList = ref([])
const packageList = ref([])

const columns = ref<any>([
    {
        title: t("adapterManage.table.theader.id"),
        prop: 'id',
        width: 240
    },
    {
        title: t("adapterManage.table.theader.userName"),
        prop: 'userName',
        width: 150

    },
    {
        title: t("adapterManage.table.theader.applicationName"),
        prop: 'applicationName',
        width: 150
    },
    {
        title: t("adapterManage.table.theader.packageName"),
        prop: 'packageName',
        width: 150
    },
    {
        title: t("adapterManage.table.theader.status"),
        prop: 'status',
        width: 200
    },
    // {
    //     title: t('console.adapter.list.path'),
    //     prop: 'gameStartPath',
    //     width: 350
    // },
    {
        title: t("adapterManage.table.theader.createTime") + t('v2.common.gmt'),
        prop: 'createTime',
        width: 200
    },
    {
        title: t("common.action"),
        prop: 'action',
        width: 150,
        slot: 'action'
    },
])

const fetchList = (params = {}) => {
    const current = dataResult.value.current
    const size = dataResult.value.size
    tableLoading.value = true
    API.getAdapterList({ current, size, ...params}).then((data: any) => {
        tableLoading.value = false
        dataResult.value = data
    })
}

const changeSize = ({ current, pageSize}: any) => {
    fetchList({ current, size: pageSize })
}

// const sumbitAdapterInfo = ({ packageId, projectId }: any) => {
const sumbitAdapterInfo = ({ packageId = '', applicationId = '', id }: any) => {
    router.push({ name: 'mainProcessStep04', params: { projectId: applicationId, packageId }, query: { fromAdapter: 1, adapterId: id } })
}

const fetchProjectData = async () => {
    const projectRes: any = await API.getProjectSelectList()
    projectList.value = projectRes.map((item: any) => ({ label: item.applicationName, value: item.id }))
}
const fetchGameData = async (id = '') => {
    const gameRes: any = await API.getGameList({ applicationId: id || searchForm.projectId })
    packageList.value = gameRes.map((item: any) => ({ label: item.packageName, value: item.id }))
}
const filterOptions = (input: string, option: any) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

watch(searchForm, (v) => {
    const params: any = cloneDeep(v)
    params.current = 1
    fetchList(params)

}, { deep: true })

watch(() => searchForm.projectId, (v: any) => {
    fetchGameData(v)
})

onMounted(() => {
    fetchProjectData()
    fetchGameData()
    fetchList()
})
</script>