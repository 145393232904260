import Request from "@/common/request"
// import { useRequest } from "@/hooks/useRequest"
const common = {
    /** 获取商品 */
    billList: '/user/bill/pageList',
    billDetail: '/user/bill/detail',
    billDetailDownload: '/user/bill/detail/download',
    billExport: '/user/bill/list/download',
    billConfirm: '/user/bill/confirm',
    payInfo: '/user/bill/payInfo',
    payment: '/user/bill/payment',
}


export default {
    billList: (params: any) => Request.post(common.billList, { ...params }),
    billDetail: (params: any) => Request.post(common.billDetail, { ...params }),
    billDetailDownload: (params: any) => Request.post(common.billDetailDownload, { ...params }, { responseType: 'blob', canCustomHandle: true }),
    billListDownload: (params: any) => Request.post(common.billExport, { ...params }, { responseType: 'blob', canCustomHandle: true }),
    billConfirm: (params: any) => Request.post(common.billConfirm, { ...params }, { canCustomHandle: true }),
    payInfo: (params: any) => Request.post(common.payInfo, { ...params }, { canCustomHandle: true }),
    payment: (params: any) => Request.post(common.payment, { ...params }, { canCustomHandle: true }),
}