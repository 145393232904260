<template lang="pug">
.flex.pl-5.mb-4.justify-between.items-center
    h1.console-title.mb-0 
        span(class="text-[#86909C]")
            router-link(:to="{ name: 'projectList' }") {{ $t('v2.projectList.title') }}
            span.mx-1 /
        | {{ projectName }}
    .search.flex
        a-input(v-model:value="keyword" placeholder="Search" allowClear @keyup.enter="searchBox" )
            template(#prefix)
                .mr-1(class="-mt-1")
                    svg-icon(name="search" :size="14")
        a-button(type="primary" @click="createGame").ml-5
            svg-icon(name="plus" :size="14" class="-mt-0.5 mr-1")
            | {{ $t('v2.action.create') }}
.bg-white.px-6.pt-3.pb-6.rounded-lg.h-full.common-console-list(class="mt-[14px]")
    .left-top
    .right-top
    .left-bottom
    .right-bottom
    game-list(ref="listRef" show-title from-project).relative.z-2.h-full
</template>

<script lang="ts" setup>
import GameList from '@/views/console/v2/components/gameList.vue'
const route = useRoute()
const projectName = decodeURIComponent(route.query.pname as string)

const listRef = ref()
const keyword = ref('')
const router = useRouter()

const searchBox = () => {
    listRef.value.fetchList({
        packageName: keyword.value,
        current: 1,
        size: 20
    })
}

const createGame = () => {
    router.push({ name: 'gameCreate', params: { projectId: route.params.projectId } })
}
</script>