import { defineStore } from "pinia";
import API from '@/common/api'

export const useGlobalStore = defineStore('GlobalVals', {
    state: (): any => ({
        isReady: false,
        /** 环境信息 */
        envId: '',
    }),

    getters: {
    },
    actions: {
        setEnvId(id: string) {
            this.envId = id
            window.localStorage.setItem('WL_ENV_ID', id)
        },
        async getEnvIds() {
            try {
                this.isReady = false
                const list = await API.console.getEnvIdList()
                this.envId = list[0]?.id
                window.localStorage.setItem('WL_ENV_ID', this.envId)
                this.isReady = true
            } catch (e) {
                this.isReady = true
            }
        }
    }
})