<template lang="pug">
.console-main-process
    step(v-if="!isFromAdapter" :current="3")
    .inner.flex.justify-center.py-20
        step-back(v-if="!isFromAdapter")
        template(v-if="submitSuccess")
            .flex.items-center.justify-center.text-center
                div
                    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="#12B862" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                    .px-54.pt-8.txt-color.leading-6.transition-all.success-txts
                        p.text-xl.font-bold.mb-4 {{ $t('v2.mainProcess.s4.yes.successTitle') }}
                        p.text-sm.mb-8 {{ $t('v2.mainProcess.s4.yes.successDesc') }}
                        p
                            a-button(type="primary" @click="router.push({ name: 'home', query: { 'success': 1 } })") {{ $t('v2.console.home.tutorial.step1.tipBtn') }}
        template(v-else)
            .w-170.flex
                span.order(v-if="!isFromAdapter && !loadingCheck") 4.
                .select-game.flex-1
                    template(v-if="loadingCheck")
                        .flex.items-center.justify-center.pt-40
                            a-spin(size="large" :spinning="loadingCheck" :tip="$t('v2.action.loading') + '...'")
                    template(v-else-if="!isUploadedGame && !isFromAdapter")
                        .label.txt-color.mb-3 {{ $t('v2.mainProcess.s4.no.title') }}
                        game-upload-tip(:closable="false" openInfo)
                        .flex.justify-center.pt-4
                            a-button(type="primary" @click="fetchStatus") {{ $t('v2.mainProcess.s4.no.btn') }}
                    template(v-else)
                        .label.txt-color {{ $t('v2.mainProcess.s4.yes.title') }}
                        .text-xs.txt-second-color.mb-1 {{ $t('v2.mainProcess.s4.yes.desc') }}
                        .text-xs.txt-second-color.mb-6 {{ $t('v2.mainProcess.s4.yes.notice') }}
                        w-biz-form.step-04-form.max-w-2xl(
                            ref="formRef"
                            v-model:formState="formState"
                            :formLayout="{ layout: 'vertical' }"
                            :submitText="$t('v2.action.submit')"
                            :loading="loading"
                            :resetText="$t('v2.action.clear')"
                            @on-submit="onSubmit"
                        )
                            template(#appendLabel="{ fieldName }")
                                p.text-xs.txt-second-color.mb-0.w-full.pl-4 {{ $t(`v2.mainProcess.s4.form.${fieldName.replace('part', 'item')}.desc`) }}
                            template(#appendField="{ filedName }")
                                template(v-if="filedName === 'part5'")
                                    .mt-4
                                        input.h-8.rounded-lg.w-full(type="text"  v-model="otherInputValue" v-if="showAppendField" :class="{'border-red': otherInputError}" :placeholder="$t('v2.mainProcess.s4.form.other')")
</template>

<script lang="ts" setup>
import API from '@/common/api/console/v2/home'
import gameUploadTip from '@/views/console/v2/components/gameUploadTip.vue'
import Step from './components/step.vue'
import StepBack from '@/views/console/v2/components/stepBack.vue'
import { Toast } from '@welink/design';
import { useI18n } from 'vue-i18n';

const { t } = useI18n()
const formRef = ref()
const showAppendField = ref(false)
const otherInputError = ref(false)
const submitSuccess = ref(false)
const loadingCheck = ref(false)
const isUploadedGame = ref(false)
const otherInputValue = ref('')

const loading = ref(false)
const router = useRouter()
const route = useRoute()
const fromAdapterSave = ref(false)
const isFromAdapter = computed(() => route.query.fromAdapter)


const formState = reactive<Record<string, any>>({
    part1: {
        label: t('v2.mainProcess.s4.form.item1.title'),
        placeholder: t('v2.mainProcess.s4.form.item1.desc'),
        value: null, rules: [{ required: true, message: t('v2.mainProcess.s4.form.errorTip') }],
        type: 'checkbox', checkBoxOption: {
            options: [
                { label: t('v2.mainProcess.s4.form.item1.v1'), value: t('v2.mainProcess.s4.form.item1.v1') },
                { label: t('v2.mainProcess.s4.form.item1.v2'), value: t('v2.mainProcess.s4.form.item1.v2') },
                { label: t('v2.mainProcess.s4.form.item1.v3'), value: t('v2.mainProcess.s4.form.item1.v3') },
                { label: t('v2.mainProcess.s4.form.item1.v4'), value: t('v2.mainProcess.s4.form.item1.v4') },
                { label: t('v2.mainProcess.s4.form.item1.v5'), value: t('v2.mainProcess.s4.form.item1.v5') },
            ]
        }
    },
    part2: {
        label: t('v2.mainProcess.s4.form.item2.title'), placeholder: t('v2.mainProcess.s4.form.item2.desc'), value: [], type: 'checkbox',
        rules: [{ required: true, message: t('v2.mainProcess.s4.form.errorTip') }],
        checkBoxOption: {
            options: [
                { label: t('v2.mainProcess.s4.form.item2.v1'), value: t('v2.mainProcess.s4.form.item2.v1') },
                { label: t('v2.mainProcess.s4.form.item2.v2'), value: t('v2.mainProcess.s4.form.item2.v2') },
                { label: t('v2.mainProcess.s4.form.item2.v3'), value: t('v2.mainProcess.s4.form.item2.v3') },
            ]
        }
    },
    part3: {
        label: t('v2.mainProcess.s4.form.item3.title'), placeholder: t('v2.mainProcess.s4.form.item3.desc'), value: [], type: 'radio',
        rules: [{ required: true, message: t('v2.mainProcess.s4.form.errorTip') }],
        radioOption: {
            options: [
                { label: t('v2.mainProcess.s4.form.item3.v1'), value: t('v2.mainProcess.s4.form.item3.v1') },
                { label: t('v2.mainProcess.s4.form.item3.v2'), value: t('v2.mainProcess.s4.form.item3.v2') },
            ]
        }
    },
    part4: {
        label: t('v2.mainProcess.s4.form.item4.title'), placeholder: t('v2.mainProcess.s4.form.item4.desc'), value: [], type: 'radio',
        rules: [{ required: true, message: t('v2.mainProcess.s4.form.errorTip') }],
        radioOption: {
            options: [
                { label: t('v2.mainProcess.s4.form.item4.v1'), value: t('v2.mainProcess.s4.form.item4.v1') },
                { label: t('v2.mainProcess.s4.form.item4.v2'), value: t('v2.mainProcess.s4.form.item4.v2') },
            ]
        }
    },
    part5: {
        label: t('v2.mainProcess.s4.form.item5.title'), placeholder: t('v2.mainProcess.s4.form.item5.desc'), value: null, type: 'radio',
        rules: [{ required: true, message: t('v2.mainProcess.s4.form.errorTip') }],
        radioOption: {
            options: [
                { label: t('v2.mainProcess.s4.form.item5.v1'), value: t('v2.mainProcess.s4.form.item5.v1') },
                { label: t('v2.mainProcess.s4.form.item5.v2'), value: t('v2.mainProcess.s4.form.item5.v2') },
                { label: t('v2.mainProcess.s4.form.item5.v3'), value: t('v2.mainProcess.s4.form.item5.v3') },
            ]
        }
    },
    part6: {
        label: t('v2.mainProcess.s4.form.item6.title'), placeholder: t('v2.mainProcess.s4.form.item6.desc'), value: null, type: 'radio',
        rules: [{ required: true, message: t('v2.mainProcess.s4.form.errorTip') }],
        radioOption: {
            options: [
                { label: t('v2.mainProcess.s4.form.item6.v1') , value: t('v2.mainProcess.s4.form.item6.v1') },
                { label: t('v2.mainProcess.s4.form.item6.v2'), value: t('v2.mainProcess.s4.form.item6.v2') },
            ]
        }
    },
    part7: {
        label: t('v2.mainProcess.s4.form.item7.title'), placeholder: t('v2.mainProcess.s4.form.item7.desc'), value: null, type: 'radio',
        rules: [{ required: true, message: t('v2.mainProcess.s4.form.errorTip') }],
        radioOption: {
            options: [
                { label: t('v2.mainProcess.s4.form.item7.v1') , value: t('v2.mainProcess.s4.form.item7.v1') },
                { label: t('v2.mainProcess.s4.form.item7.v2'), value: t('v2.mainProcess.s4.form.item7.v2') },
            ]
        }
    },
    part8: {
        label: t('v2.mainProcess.s4.form.item8.title'), placeholder: t('v2.mainProcess.s4.form.item8.desc'), value: null, type: 'textarea',
        rules: [ { required: false, max: 512, message: t('v2.mainProcess.s4.form.item8.errorMessage') } ]
    },

})

watch(formState, (v) => {
    showAppendField.value = v.part5.value === t('v2.mainProcess.s4.form.item5.v3')
    if (!showAppendField.value) {
        otherInputValue.value = ''
        otherInputError.value = false
    }
})

watch(otherInputValue, (v) => {
    otherInputError.value = !v.trim()
})

const onSubmit = async (values: any) => {
    const { projectId , packageId } = route.params
    if (values.part5 === t('v2.mainProcess.s4.form.item5.v3') && !otherInputValue.value.trim()) {
        otherInputError.value = true
        return
    }

    const requirementList = Object.keys(values).map((item: any, index: number) => {
        const question = formState[item].label
        let solution = Array.isArray(values[item]) ? values[item].join(',') : values[item]
        if (values[item] === t('v2.mainProcess.s4.form.item5.v3')) {
            solution += "：" + otherInputValue.value
        }
        return { question, solution, orderId: index + 1 }
    })

    loading.value = true
    try {
        if (isFromAdapter.value) {
            const params = {
                adapterId: route.query.adapterId as string,
                applicationId: projectId,
                packageId,
                requirementList,
            }
            if (fromAdapterSave.value) {
                await API.saveAdapterInfo(params)
            } else {
                await API.modifyAdapterFormInfo(params)
            }
            loading.value = false
            window.history.back()
        } else {
            const res = await API.saveAdapterInfo({
                applicationId: projectId,
                packageId,
                requirementList,
            })
            loading.value = false
            submitSuccess.value = true
            await API.setMainStep(4, { lastProjectId: route.params.projectId, lastPackageId: packageId })
        }
    } catch(e){
        loading.value = false
    }
}

const fetchStatus = () => {
    const { packageId } = route.params
    loadingCheck.value = true
    API.checkGameUploaded(packageId as string).then((data: any) => {
        isUploadedGame.value = Boolean(data?.id)
        loadingCheck.value = false
    }).catch(() => {
        loadingCheck.value = false
    })
}

onMounted(() => {
    if (isFromAdapter.value) {
        API.getAdapterFormInfo(route.query.adapterId as string).then((data: any) => {
            if (data?.requirementList && data?.requirementList.length) {
                data.requirementList.forEach((item:any, index: number) => {
                    const crt = index + 1
                    if (crt < 3) {
                        formRef.value.updateFormField(`part${crt}`, item.solution.split(','))
                    } else {
                        if(crt === 5 && item.solution.includes(t('v2.mainProcess.s4.form.item5.v3'))) {
                            const [a1, a2] = item.solution.split('：')
                            showAppendField.value = true
                            otherInputValue.value = a2
                            formRef.value.updateFormField(`part${crt}`, a1)
                        } else {
                            formRef.value.updateFormField(`part${crt}`, item.solution)
                        }

                    }
                });
            } else {
                fromAdapterSave.value = true
            }
        })
        return
    }
    fetchStatus()
})



// onMounted(() => {
//     const inner: any = document.querySelector('.console-main-process .inner')
//     if (inner) {
//         gameHeight.value = inner.clientHeight - 80
//     }
// })
</script>

<style lang="scss" scoped>
.success-txts {
    opacity: 0;
    animation: delay .4s cubic-bezier(0.65, 0, 0.45, 1) 1s forwards;
}
.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #12B862;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #12B862;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position: relative;
    top: 5px;
    right: 5px;
    margin: 0 auto;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 4;
    stroke-miterlimit: 10;
    stroke: #12B862;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;

}

.checkmark__check {
    stroke-linejoin: round;
    stroke-width: 3;
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes delay {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes scale {

    0% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }

    100% {
        transform: none;
    }
}

@keyframes fill {
    100% {
        fill: #12B862;
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}

</style>

<style lang="sass" scoped>
:deep(.step-04-form)
    .ant-form-item-control
        padding-left: 28px
    .ant-form-item-required
        display: flex !important
        align-items: flex-start !important
        &:before
            margin-top: 4px
    .render-label
        color: #424A61
        flex-wrap: wrap
    label[for="form_item_part8"]
        padding-left: 14px
    .btns
        .ant-col
            margin-inline-start: 0 !important
        .ant-form-item-control-input-content
            display: flex
            justify-content: flex-end
    .wl-form-radio
        .ant-radio-group
            display: flex
            align-items: center
            width: 100%
            border: none
            .ant-radio-wrapper
                margin-right: 24px
            .ant-radio
                margin-left: -1px !important
    .ant-checkbox-group
        display: flex
        width: 100%
        align-items: center
        border: none
        height: auto
        .ant-checkbox-wrapper
            margin-right: 24px
            .ant-checkbox-inner
                border-radius: 0
        .ant-checkbox
            margin-left: -1px !important
    .btns .ant-form-item-control-input-content
        justify-content: flex-start
        flex-direction: row-reverse
</style>