<template lang="pug">
div.w-full.h-full.home-bg.flex.flex-col
    //- guide
    guide-popup
    //- header
    top-banner
    //- tutorial Guide
    tutorial-guide(v-if="isTutorialGuideHide")
    //- recentProject
    recent-project
    template(v-if="route.query.success")
</template>
<script setup lang="ts">
import RecentProject from './recentProject.vue';
import GuidePopup from './guidePopup.vue';
import TutorialGuide from './tutorialGuide.vue';
import TopBanner from './banner.vue';
import { useHomeStore } from '@/store/home'

const store = useHomeStore()
const isTutorialGuideHide = computed(() => !store.guideInfo.tutorialGuideStyle)
const route = useRoute()

onBeforeMount(() => {
    store.getGuidePopup()
    store.loadGuideStep()
})
</script>