import Request from '@/common/request'
const API = {
    list: '/application-console/web/resource-manager/resource-query',
    resourceBuy: '/application-console/customer/resource-server/list',
    applicationGet: '/application-console/customer/application/get/',
    envGet: '/application-console/customer/environment/get/',
    gameList: '/application-console/customer/package-deployment/page',
    resourceList: '/application-console/customer/resource-server/page',
    deleteGame: '/application-console/customer/package-deployment/remove/',
    notice:'/application-console/customer/resource-server/notice',
    envIdList: '/portal/environment/list'
}

export default {
    getResourceQueryList: <R>(params: any) => {
        return Request.post<any, R>(API.list, params)
    },
    getResourceBuy: <R>(params: any) => {
        return Request.post<any, R>(API.resourceBuy, params)
    },
    getAppInfo: <R>(appId: string) => {
        return Request.get<any, R>(API.applicationGet + appId, { },)
    },
    getAppEnvInfo: <R>(envId: string) => {
        return Request.get<any, R>(API.envGet + envId, { },)
    },
    getGameList: <R>(params: any) => {
        return Request.post<any, R>(API.gameList, params)
    },
    getResourceList: <R>(params: any) => {
        return Request.post<any, R>(API.resourceList, params)
    },
    deleteGameItem: (id: string) => {
        console.log('id: ', API.deleteGame + id);
        return Request.delete<any, any>(API.deleteGame + id, {})
    },
    getNotice: <R>(params: any) => {
        return Request.post<any, R>(API.notice, params)
    },
    getEnvIdList: () => {
        return Request.post<any, any>(API.envIdList, {})
    }
}