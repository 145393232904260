import { defineStore } from "pinia";
import API from '@/common/api/console/v2/home'

export const useHomeStore = defineStore('HomeStore', {
    state: (): any => ({
        showBubblesPopup: false,
        guideInfo: {
            bubblesPopup: 0,
            guidePopup: 0,
            tutorialGuideStyle: 0,
        },
        stepInfo: {

        }
    }),
    //  1 展示， 0 关闭
    actions: {
        async showTutorialGuide(status = 1) {
            try {
                await API.setTutorialGuide(status)
                this.guideInfo.tutorialGuideStyle = status
            } catch (e) {
            }
        },
        async hideTutorialGuide(status = 1) {
            try {
                await API.setTutorialGuide(status)
                this.guideInfo.tutorialGuideStyle = status
            } catch (e) {
            }
        },
        async hideBubblesPopup(status = 0) {
            try {
                await API.setBubblesPopupStatus(status)
                this.guideInfo.bubblesPopup = status
            } catch (e) {
            }
        },
        async hideGuidePopup(status = 0) {
            await API.setGuidePopup(status)
            this.guideInfo.guidePopup = status
            this.showBubblesPopup = true
        },
        async getGuidePopup() {
            try {
                const res: any = await API.getGuidePopup()
                // API.resetGuider()
                this.guideInfo = res
                if (!res.guidePopup && res.bubblesPopup) {
                    this.showBubblesPopup = true
                }
            }catch (e) {

            }
        },
        async loadGuideStep() {
            try {
                const res: any = await API.getMainStep()
                this.stepInfo = { step: 1, ...res }
            }catch (e) {

            }
        }
    }
})