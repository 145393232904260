import { i18n } from '@/i18n'
export const menuList = () => [
    { label: i18n.global.t('v2.console.sidebar.homepage'),name:'home', path: 'home', key: '1', icon: 'menu-home', hidden: false  },
    { label: i18n.global.t('v2.console.sidebar.management'),name:'', path: '', key: '2', icon: '', hidden: false, type:'group',
        children: [
            {label: i18n.global.t('v2.console.sidebar.project'),name:'projectList', path: 'projectList', key: '2-1', icon: 'menu-project', hidden: false},
            {label: i18n.global.t('v2.console.sidebar.game'),name:'gameList', path: 'gameList', key: '2-2', icon: 'menu-game', hidden: false  },
            {label: i18n.global.t('v2.console.sidebar.resource'),name:'projectResourceList', path: 'projectResourceList', key: '2-3', icon: 'menu-resource', hidden: false  }
        ]
    },

    { label: i18n.global.t('v2.console.sidebar.information'),name:'', path: '', key: '3', icon: '', hidden: false, type:'group',
        children:[
            {label: i18n.global.t('v2.console.sidebar.compatibility'),name:'projectAdapterList', path: 'projectAdapterList', key: '3-1', icon: 'menu-compatibility', hidden: false}
        ]
    }
]