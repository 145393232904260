import { createApp } from 'vue'
import { router } from '@/routers/index'
import store from '@/store';
import WelinkDesign from '@welink/design'
import WelinkDesignV2 from '@welink/design-v2'

import { i18n } from "./i18n"
import App from './views/App.vue'
import API from '@/common/api';
import { setToken, saveLoginInfo } from '@/utils/sso'
import { getQueryString } from '@/utils/getQueryString'


import '@unocss/reset/tailwind.css'
import 'virtual:uno.css'
import 'virtual:svg-icons-register'
import "./style/style.css"
import '@welink/design/dist/es/style.css'
import '@welink/design-v2/dist/es/style.css'

const start = async () => {

    if (getQueryString('wl_sso_token')) {
        try {
            const res = await API.userCenter.getUserInfoByToken(getQueryString('wl_sso_token') as string)
            saveLoginInfo(res)
            setToken(getQueryString('wl_sso_token') as string)
        } catch (e) {

        }
    }

    const app = createApp(App)
    app.use(WelinkDesign).use(router).use(i18n).use(store).use(WelinkDesignV2).mount('#app')
}

start()
