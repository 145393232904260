import { i18n } from '@/i18n'

export * from './game'
export const adapterFileOptions = [
    { label: i18n.global.t('console.adapter.template.t0'), value: 'M:/GameTemplates/template0' },
    { label: i18n.global.t('console.adapter.template.t1'), value: 'M:/GameTemplates/template1' },
    { label: i18n.global.t('console.adapter.template.t2'), value: 'M:/GameTemplates/template2' },
    { label: i18n.global.t('console.adapter.template.t3'), value: 'M:/GameTemplates/template3' },
    { label: i18n.global.t('console.adapter.template.t4'), value: 'M:/GameTemplates/template4' },
    { label: i18n.global.t('console.adapter.template.t5'), value: 'M:/GameTemplates/template5' },
    { label: i18n.global.t('console.adapter.template.t6'), value: 'M:/GameTemplates/template6' },
]

export const dispatchApapterCommonParams = {
    resolution: "1920x1080",
    bitRate: "8000",
    fps: "60",
    version: "V1.0",
    clientIp: "127.0.1.1",
}

export const dispatchApapterWebParams = { ...dispatchApapterCommonParams, codecType: 18 }
export const dispatchApapterAndriodParams = { ...dispatchApapterCommonParams, codecType: 21 }

export const PROJECT_NAME_LENGTH = 255

export const resourceStatusMap: any = {
    '0': { color: 'blue', text: i18n.global.t('v2.resourceList.status0') },
    '1': { color: 'red', text: i18n.global.t('v2.resourceList.status1') },
    '2': { color: 'blue', text: i18n.global.t('v2.resourceList.status2') },
    '3': { color: 'blue', text: i18n.global.t('v2.resourceList.status3') },
    '4': { color: 'blue', text: i18n.global.t('v2.resourceList.status4') },
    '6': { color: 'red', text: i18n.global.t('v2.resourceList.status6') },
    '12': { color: 'green', text: i18n.global.t('v2.resourceList.status12') },
}