<template lang="pug">
div.flex-1.text-center
    a-button(type="default" size="small" @click="editPasswordBox").flex-1.text-center.mr-5 {{ t("userInfo.Modify") }}
a-modal(v-model:open="dialogFormEditTime",
    :title="t('userInfo.editTime')"
    :ok-Text="t('common.confirm')"
    :cancel-Text="t('common.cancel')"
    width="600px"
    @cancel="cancelBox"
    @ok="onSubmit"
).titleleft
    .tips.mt-2.flex.items-center
        svg-icon.ml-4(name="warn" size="14" class=" !mr-2")
        span.text-xs {{ t('userInfo.sessionText') }}
    w-biz-form.add-form.sessionForm.p-5(
        ref="formRef"
        layout="form"
        :class="{'!p-4':true }"
        :loading="loading"
        :formState="formStatePassWord",
        hideSubmit
        hideReset
        :formLayout="{ layout: 'horizontal', labelCol: { span: isForeign?10: 5 }, gap: 20 }"
    )
        template(#appendField="{ filedName }")
            template(v-if="filedName === 'hour'").flex
                span.ml-2 {{ t("userInfo.hours") }}
</template>
<script lang="ts" setup>
const props = withDefaults(defineProps<{
    tokenSurvival?: any,
}>(), {
    tokenSurvival: ""
})
const emits = defineEmits(["getUserDetail"])
import { useI18n } from 'vue-i18n'
import { validate } from "vee-validate";
import API from "@/common/api"
import cloneDeep from 'lodash-es/cloneDeep';
import { message } from '@welink/design-v2';
import { isForeign } from '@/common/constants/env';

const { t } = useI18n()

const formRef = ref()
const loading = ref(false)
console.log(props.tokenSurvival)
const formStatePassWord = ref<any>(cloneDeep({
    hour: { label: t("userInfo.LoginSessionDuration"), value: cloneDeep(props.tokenSurvival),precision:0,type: 'number', max: 24, min: 1, rules: [{ required: true, message: t("userInfo.LoginSessionDuration") + t("common.not_empty") },], allowClear: true, },
}))
const dialogFormEditTime = ref(false)
const editPasswordBox = () => {
    dialogFormEditTime.value = true
}
const cancelBox = () => {
    formRef.value.formRef.resetFields()
    dialogFormEditTime.value = false
}
const onSubmit = () => {
    loading.value = true
    console.log(formRef.value.formRef.validate)
    formRef.value.formRef.validate().then(async (data: any) => {
        console.log(validate)
        const res = await API.userCenter.tokenSurvival({ ...data })
        console.log(res)
        if (res.data.code === 0) {
            dialogFormEditTime.value = false
            message.success(t("common.operateSuccess"))
            emits("getUserDetail")
        } else {
            message.error(res.data.msg)
        }
        loading.value = false
    })
}
</script>
<style lang="sass">
.sessionForm
    .wl-form-number
        width: 200px
    .ant-form-item-control-input-content
        display: flex
        align-items: center
.titleleft
    .ant-modal-title
        text-align: left !important
        // padding-top: 24px
        padding-bottom: 0px !important
        margin-bottom: 0 !important
        margin-top: 0px !important
    // .wl-modal_header
    // height: 64px
</style>
<style lang="sass" scoped>
    
    :deep .btns
        display: none !important
    .tips
        width: 552px
        height: 40px
        // background: #F9FAFB
        border-radius: 4px
        // border: 1px solid #E5E7EB
        color:#6B7280
</style>