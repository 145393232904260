import Request from '@/common/request'
const API = {
    create: '/application-console/customer/game-package/save' ,
    uploadFileList: '/application-console/customer/package-upload-file/page' ,
    getGameList: '/application-console/customer/package-upload-file/list' ,
    deleteFile: '/application-console/customer/package-upload-file/remove/',
    deployPackage: '/application-console/customer/package-deployment/deploy',
    deployPackageList: '/application-console/customer/package-deployment/list',
    deployResourceList: '/application-console/customer/resource-server/list'
}

export default {
    createPackage: (params: any) => Request.post(API.create, params),   // 创建
    getUploadFileList: (params: any) => Request.post(API.uploadFileList, params),  // 管理查询列表
    getPackageList: (params: any) => Request.post(API.getGameList, params),  // 管理查询列表
    deleteFile: (id: string) => Request.delete(API.deleteFile + id, {}),  // 删除数据
    deployPackageList: (environmentId: string, applicationId: string) => Request.post(API.deployPackageList, { environmentId, applicationId  }),  // 管理查询列表
    deployPackage: (params: any) => Request.post(API.deployPackage, params),  // 管理查询列表
    deployResourceList: (showStatusList: number[], applicationId: string) => Request.post(API.deployResourceList, { showStatusList, applicationId  }),  // 更换部署资源
}