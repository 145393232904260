import Request from "@/common/request"
// import { useRequest } from "@/hooks/useRequest"
const common = {
    /** 获取商品 */
    topList: '/user/product/saleType/topList',
    listByType: '/user/product/spu/listByType',
    buyPageInfo: '/user/product/spu/buyPageInfo',

}

export default {
    topList: (params: any) => Request.post(common.topList, { ...params }),
    listByType: (params: any) => Request.post(common.listByType, { ...params }),
    buyPageInfo: (params: any) => Request.post(common.buyPageInfo, { ...params }),
}